













































import { Vue, Component, Prop } from 'vue-property-decorator'
import { FontAwesomeIcon }      from '@fortawesome/vue-fontawesome'
import { mapGetters, mapState }             from 'vuex'
import { getTypeRegle, TypeRegle } from '@/types/Planification'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    computed: {
        ...mapState('session', ['sessionSelect']),
        ...mapState('planification', ['regles_session', 'error_regles']),
        ...mapGetters('planification', ['get_regles_sessions', 'get_checkbox_params'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})
export default class ParametresMinimum extends Vue
{
    @Prop() openModal?: boolean

    getTypeRegle = getTypeRegle
    TypeRegle = TypeRegle
    lignes: any = []
    header: any = {}
    recherche: any = ''
    ecarts_regles = []


    //  [15min; 30 min; 1h; 1h30; 2h; 2h30; 3h; 3h30; 4h; 4h30; 5h; 6h; 8h; 10h; 12h; 18h; 24h]
    optionsSelect = [
        { affichage: '15min', value: 15 },
        { affichage: '30min', value: 30 },
        { affichage: '1h',    value: 60 },
        { affichage: '1h30',  value: 90 },
        { affichage: '2h',    value: 120 },
        { affichage: '2h30',  value: 150 },
        { affichage: '3h',    value: 180 },
        { affichage: '3h30',  value: 210 },
        { affichage: '4h',    value: 240 },
        { affichage: '4h30',  value: 270 },
        { affichage: '5h',    value: 300 },
        { affichage: '6h',    value: 360 },
        { affichage: '8h',    value: 480 },
        { affichage: '10h',   value: 600 },
        { affichage: '12h',   value: 720 },
        { affichage: '18h',   value: 1080 },
        { affichage: '24h',   value: 1440 }
    ]


    /**
     * Met à jour la valeur envoyée par la sélection
     */
    majCollectionRegles () {
        this.$store.getters['planification/prepare_regles_minimum'](this.lignes)
    }

    /**
     * Fermeture du popup de paramétrage
     */
    closeModal() {
        this.$emit('close', false)
    }

    /**
     * Créer le tableau de règles
     */
    generateTableauReglesSessions() {
        this.header = this.$store.getters['planification/get_header_params']
        this.lignes = this.$store.getters['planification/get_lignes_params']
    }


    mounted() {
        if (this.$store.getters['planification/get_regles_sessions']) {
            this.generateTableauReglesSessions()
        }
        if (this.$store.getters['planification/get_ecart_regles']) {
            this.ecarts_regles = this.$store.getters['planification/get_ecart_regles'](false)
        }
    }
}
