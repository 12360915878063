












































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { formatNumber, formatDate, dateDuJourFoDocument, getFileNameFromHeader, getLibellePoste } from '@/utils/helpers'
import { TypeStatut } from '@/types/CandidatEpreuve'
import { getClasseCPGE } from '@/types/Candidat'
import _ from 'lodash'

@Component({
    computed: {
        ...mapGetters('auth', ['user_session_id']),
        ...mapState('auth', ['user_session_id']),
        ...mapGetters('candidatEpreuve', ['candidatEpreuves', 'loading', 'totalRows', 'lastPage', 'total']),
        ...mapGetters('epreuveCorrectionResultat', ['epreuveCorrectionResultatSelect'])
    },
    components: {
        ExaGenericTable,
        PopupEditCandidat,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class ListeNotes extends Vue {
    @Prop() epreuvecorrectionId: any

    Ability                   = Ability
    formatDate                = formatDate
    tableLoading              = false
    params: any               = null
    showModalEditionCandidat  = false
    selected_tab              = 'resultats'
    genericfields: Array<any> = []
    filter                    = ''
    filtres:    any           = []
    dataForTab: Array<any>    = []
    exportingIsWorking        = false

    /**
     * @description Actualisation de l'interface
     * @returns {Promise<void>}
     */
    @Watch('user_session_id')
    async refreshInterface(): Promise<void> {
        await this.load()
    }

    /**
     * @description Chargement des concours
     * @returns {Promise<void>}
     */
    loadConcours(): Promise<void> {
        return new Promise((resolve) => {
            this.$store.dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] })
                .then(async () => {
                    // Check si le chargement est sur la session active
                    let session_active_load = false
                    if (this.$store.state.session.sessionSelect && this.$store.getters['concour/banques'].length !== 0 &&
                        this.$store.state.session.sessionSelect.id === this.$store.getters['concour/banques'][0].session_id) {
                        session_active_load = true
                    }

                    if (this.$store.getters['concour/banques'].length === 0 || !session_active_load) {
                        await this.$store.dispatch('concour/getConcoursActifs')
                    }
                    resolve()
                })
        })
    }

    /**
     * @description Export des ajustements
     * @returns {void}
     */
    exportAjustements(): void {
        if (this.exportingIsWorking) {
            return
        }
        this.exportingIsWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        this.$store.dispatch('epreuveCorrectionResultat/exportAjustements', { epreuvecorrection_id:  this.epreuvecorrectionId })
            .then((response) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute(
                    'Download',
                    getFileNameFromHeader(response.headers) || `export_ajustements_${this.epreuvecorrectionId}_${dateDuJourFoDocument()}.xlsx`
                )
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Export des candidats
     * @returns {void}
     */
    exportCandidatEpreuves(): void {
        if (this.exportingIsWorking) {
            return
        }
        this.exportingIsWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        this.$store.dispatch('candidatEpreuve/exportCandidatEpreuves', { epreuvecorrection_id:  this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.epreuve_correction_id })
            .then((response) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute(
                    'Download',
                    getFileNameFromHeader(response.headers) || `export_liste_note_${this.epreuvecorrectionId}_${dateDuJourFoDocument()}.xlsx`
                )
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Edition d'un candidat
     * @param {any} item - Candidat à éditer
     * @returns {Promise<void>}
     */
    async editCandidat(item: any): Promise<void> {
        if (item && item.id !== undefined) {
            this.$store.commit('candidat/RESET_EDITED_CANDIDAT')
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            await this.$store.dispatch('candidat/getCandidat', item)
            this.$store.commit('candidat/SET_CANDIDAT_FILIERE', {
                candidatId: item.id,
                nomFiliere: item.filiere
            })
            this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
            this.showModalEditionCandidat = true
        }
    }

    /**
     * @description Fermeture de la modale d'édition d'un candidat
     * @returns {void}
     */
    reinitShowModalCandidat(): void {
        this.showModalEditionCandidat = false
    }

    /**
     * @description Création des colonnes du tableau (selon contexte)
     * @returns {void}
     */
    setFields(): void {
        this.genericfields = []
        this.genericfields.push({ key: 'candidat.code', label: 'Code', sortable: true, class: '', type: 'text' })
        this.genericfields.push({ key: 'candidat.name', label: 'Identité', sortable: true, class: '', type: 'text' })
        this.genericfields.push({ key: 'candidat.num_anonymat', label: 'Anonymat', sortable: true, class: '', type: 'text' })

        const customColumnsParam = this.$store.getters['auth/findParameter']('listeEpreuveCorrectionResultatsListeNotesColumns')?.value
        if (customColumnsParam) {
            for (const column of customColumnsParam) {
                this.genericfields.push(column)
            }
        }

        this.genericfields.push({ key: 'correcteurs', label: getLibellePoste(this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect), sortable: true, class: '', type: 'text' }) // batchCorrection.correctorGroup.correctors
        this.genericfields.push({ key: 'note_brute', label: 'Note Brute /20', sortable: true, class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'appreciation', label: 'Appreciation générale', sortable: false, class: '', type: 'text' })
        this.genericfields.push({ key: 'voir_correction', label: 'Voir la notation', sortable: true, class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'note_finale', label: 'Note Finale /20', sortable: true, class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'note_reclamation', label: 'Note Réclamation /20', sortable: true, class: 'text-center', type: 'text' })
    }

    // Chargement des données dans le tableau
    /**
     * @description Chargement des données dans le tableau
     * @param {any} poData - Données à charger
     * @param {boolean} isLoadMore - Chargement de plus de données
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            const customColumnsParam = this.$store.getters['auth/findParameter']('listeEpreuveCorrectionResultatsListeNotesColumns')?.value
            for (const result of poData) {
                const code = result.candidat && result.candidat.code ? result.candidat.code : '-'
                const identite = result.candidat ? (result.candidat.name ? result.candidat.name : '-') + ' ' + (result.candidat.first_name ? result.candidat.first_name : '-') : '-'
                const anonymat = result.candidat ? (result.candidat.num_anonymat ?  result.candidat.num_anonymat : '-') : '-'

                let correctors = '-'
                if (result.batch_correction && result.batch_correction.corrector_group && result.batch_correction.corrector_group.correctors) {
                    correctors = _.orderBy(
                        result.batch_correction.corrector_group.correctors.map((corrector: any) => {
                            return `${corrector.name} ${corrector.first_name}`
                        }),
                        [],
                        ['asc']
                    ).join(', ')
                }

                const note_brute = result.note_brute !== null && result.statut === TypeStatut.STATUT_PRESENT ? formatNumber(result.note_brute) : result.statut === TypeStatut.STATUT_ABSENT ? 'Abs.' : '-'
                const label_note = result.note_brute !== null && result.statut === TypeStatut.STATUT_PRESENT ? formatNumber(result.note_brute) : result.statut === TypeStatut.STATUT_ABSENT ? 'Absent' : '-'
                const appreciation = result.appreciation ? result.appreciation : '-' // overflow ? --> text-overflow: ellipsis; + word-break: break-word;
                const note_finale = result.note_finale !== null ? formatNumber(result.note_finale) : result.statut ===  TypeStatut.STATUT_ABSENT ? 'Abs.' : '-'
                const note_reclamation = result.notes ? (result.notes.note_reclamation ? formatNumber(result.notes.note_reclamation) : '-') : '-'

                const line: any = [
                    { label: 'Cliquez pour consulter la fiche du candidat', text: code,     item: result.candidat, type: 'actionText', typeAction: 'openModaleCandidat', class: 'text-info item_action' },
                    { label: 'Cliquez pour consulter la fiche du candidat', text: identite, item: result.candidat, type: 'actionText', typeAction: 'openModaleCandidat', class: 'text-info item_action' },
                    { label: '', item: anonymat, type: 'text', typeAction: null, class: '' }
                ]

                if (customColumnsParam) {
                    customColumnsParam.forEach((column: any) => {
                        let item = result;
                        column.key.split('.').forEach((segment: any) => {
                            if (item !== undefined) {
                                item = item[segment]
                            } else {
                                item = undefined
                            }
                        })

                        line.push({ label: '', item: item, type: column.type, typeAction: null, class: column.class })
                    })
                }

                line.push({ label: '', item: correctors, type: 'text', typeAction: null, class: '' })
                line.push({ label: label_note, item: note_brute, type: 'text', typeAction: null, class: 'text-center' })
                line.push({ label: '', item: appreciation, type: 'text', typeAction: null, class: '' })
                line.push({ label: '', text: 'APERÇU >', type: 'actionText', item: result, typeAction: 'openApercu', disabled: !result.cle_epreuve_externe, class: 'text-center ' + (result.cle_epreuve_externe ?  'text-primary' : 'text-tertiary cursor-default') })
                line.push({ label: '', item: note_finale, type: 'text', typeAction: null, class: 'text-center background_td text_bold' })
                line.push({ label: '', item: note_reclamation, type: 'text', typeAction: null, class: 'text-center' })

                this.dataForTab.push(line)
            }
        }
    }

    /**
     * @description Initisalisation des filtres
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        this.filtres = [
            { libelle: 'Code', defautOptionlibelle: 'Rechercher un', model: 'candidat.code', value: '', index: 'candidat.code', datas: null, loading: false, options: { type: 'form', fieldsKey: 'candidat.code', strict: true } },
            { libelle: 'Identite', defautOptionlibelle: 'Rechercher une', model: 'candidat.name', value: '', index: 'candidat.name', datas: null, loading: false, options: { type: 'form', fieldsKey: 'candidat.name' } },
            { libelle: 'Numéro d\'anonymat', defautOptionlibelle: 'Rechercher un', model: 'candidat.num_anonymat', value: '', index: 'candidat.num_anonymat', datas: null, loading: false, options: { type: 'form', fieldsKey: 'candidat.num_anonymat' } },
            { libelle: 'Correcteur', defautOptionlibelle: 'Rechercher un', model: 'correcteurs', value: '', index: 'correcteurs', datas: null, loading: false, options: { type: 'form', fieldsKey: 'correcteurs' } } // ,
        ]
    }

    // Events du tableau
    /**
     * @description Gestion des événements du tableau
     * @param {any} paParams - Paramètres de l'événement
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'sortHandler':
                case 'filterHandler':
                    await this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    await this.loadHandler(paParams[1])
                    break

                case 'openModaleCandidat':
                    await this.editCandidat(paParams[1])
                    break

                case 'openApercu':
                    if (paParams[1]?.cle_epreuve_externe) {
                        window.open(this.$store.getters['candidat/getCorrectionOnViatique'](paParams[1].cle_epreuve_externe), '_blank')
                    }
                    break
            }
        }
    }

    /**
     * @description Chargement des candidats
     * @returns {Promise<void>}
     */
    async loadCandidatEpreuves(): Promise<void> {
        this.params = {
            page: 1,
            sort: 'candidat.name',
            direction: 'asc',
            epreuveCorrectionId: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.epreuve_correction_id
        }

        await this.$store.dispatch('candidatEpreuve/getCandidatEpreuves', this.params)
        this.tableLoading = false
        this.initDatas()
    }

    /**
     * @description Initialisation des données
     * @returns {void}
     */
    initDatas(): void {
        this.setDataForGenericTab(this.$store.getters['candidatEpreuve/candidatEpreuves'])
        this.setFiltersForGenericTab()
    }

    /**
     * @description Événement de filtre et de tri
     * @param {any} params - Paramètres de filtre et de tri
     * @returns {Promise<void>}
     */
    async filtreSortHandler(params: any): Promise<void> {
        params.epreuveCorrectionId = this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.epreuve_correction_id
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.$store.state.candidatEpreuve.candidatEpreuves = []
            this.setDataForGenericTab(this.$store.state.candidatEpreuve.candidatEpreuves)
            this.params = params
            this.tableLoading = true
            await this.$store.dispatch('candidatEpreuve/getCandidatEpreuves', this.params)
            this.setDataForGenericTab(this.$store.state.candidatEpreuve.candidatEpreuves)
            this.tableLoading = false
        }
    }

    /**
     * @description Gestion du chargement lors du défilement
     * @param {any} params - Paramètres de chargement
     * @returns {Promise<void>}
     */
    async loadHandler(params: any): Promise<void> {
        params.epreuveCorrectionId = this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.epreuve_correction_id
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.tableLoading = true
            await this.$store.dispatch('candidatEpreuve/getMoreCandidatEpreuves', this.params)
            this.setDataForGenericTab(this.$store.state.candidatEpreuve.candidatEpreuves)
            this.tableLoading = false
        }
    }

    /**
     * @description Chargement des données
     * @returns {Promise<void>}
     */
    async load(): Promise<void> {
        this.tableLoading = true
        this.filtres = []
        this.setFields()
        await this.loadCandidatEpreuves()
        await this.loadConcours()
    }

    /**
     * @description Montage du composant
     * @returns {Promise<void>}
     */
    async mounted(): Promise<void> {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            await this.load()
        }
    }
}
