






















































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import PopupEditCommentCandidat from '@/components/Candidat/PopupEditCommentCandidat.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { checkIcone, format_phone_number, getFileNameFromHeader } from '@/utils/helpers'
import { CandidatInterface } from '@/types/Candidat'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('candidat', [
            'candidats',
            'loading',
            'meta',
            'error',
            'selectedCandidat',
            'totalRows',
            'lastPage',
            'totalPage'
        ])
    },
    components: {
        ExaGenericTable,
        PopupEditCandidat,
        PopupEditCommentCandidat,
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})
export default class Candidats extends Vue {
    Ability = Ability
    firstLoading = true
    dataForTab: Array<any> = []
    filtres: Array<any> = []
    params: any = null
    tableLoading = false
    enreigstrementCours = false
    tabSelected = ''
    blockSelectedCandidat = false
    showModalEditionCandidat = false
    showModalEditionCommentCandidat = false
    showModalEditionBlocageCandidat = false
    genericfields = [
        { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'code', label: 'Code', sortable: true, class: '', type: 'action' },
        { key: 'name', label: 'Identité', sortable: true, class: '', type: 'action' },
        { key: 'concours', label: 'Concours', sortable: true, class: '', type: 'text' },
        { key: 'filiere', label: 'Filière', sortable: true, class: '', type: 'text' },
        { key: 'email', label: 'Adresse mail', sortable: true, class: '', type: 'text' },
        { key: 'telephone', label: 'Tel. portable', sortable: true, class: '', type: 'text' },
        { key: 'portailCandidatBlocked', label: 'Blocage', sortable: true, class: 'text-center', type: 'text' },
        { key: 'cle_epreuve_externe', label: '', sortable: true, class: '', type: 'text' }
    ]

    @Watch('user_session_id')
    async refreshInterface() {
        await this.load()
    }

    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            const can = this.$store.getters['auth/can'](Ability.DLB_PUBNOT_MANAGE)
            const icone = checkIcone(Ability.DLB_PUBNOT_MANAGE, can)

            for (const result of poData) {
                this.updateDatasFiliere(result)

                let styleButtonComment = 'text-tertiary commons_comment_button text-center'
                let nameIcon = 'comment-alt'
                if (result.hasComments === true) {
                    styleButtonComment = 'commons_comment_button text-center'

                    if (result.hasImportantComments === false) {
                        nameIcon = 'comment-alt'
                    } else {
                        nameIcon = 'comment-alt-exclamation'
                    }
                }
                let tooltip_comments = ''

                for (const c in result.comments) {
                    tooltip_comments += result.comments[c].important
                        ? '---------------TACHE A FAIRE---------------\n'
                        : '---------------COMMENTAIRE---------------\n'
                    tooltip_comments += result.comments[c].body
                    tooltip_comments += '\n'
                }

                const identite = result
                    ? (result.name ? result.name : '-') + ' ' + (result.first_name ? result.first_name : '-')
                    : '-'
                const email = result ? (result.email ? result.email : '-') : '-'
                const telephone = result ? (result.telephone ? format_phone_number(result.telephone) : '-') : '-'

                const concours_result = result?.concours
                    ?.map((concour: any) => concour.name)
                    ?.join(', ') || ''

                const puce_blocage = []
                let class_blocage = 'text-danger'
                if (result.portail_candidat_blocked === '0' || result.portail_candidat_blocked === 0) {
                    class_blocage = 'text-success'
                }
                puce_blocage.push({ name: 'circle', class: class_blocage })

                const class_btn_apercu = 'text-info fw-bold text-uppercase'

                const line = [
                    {
                        label: icone.label,
                        item: result,
                        type: 'action',
                        typeAction: 'edit_blocage',
                        class: 'commons_first_action_button btn_action_ligne col-w-etat',
                        icon: icone.icon,
                        disabled: false
                    },
                    {
                        label: 'Cliquez pour consulter la fiche du candidat',
                        text: result.code,
                        item: result.id,
                        type: 'actionText',
                        typeAction: 'edit',
                        class: 'text-info item_action btn_action_ligne_link'
                    },
                    {
                        label: 'Cliquez pour consulter la fiche du candidat',
                        text: identite,
                        item: result.id,
                        type: 'actionText',
                        typeAction: 'edit',
                        class: 'text-info item_action btn_action_ligne_link'
                    },
                    { label: '', item: concours_result, type: 'text', typeAction: null, class: 'text-left' },
                    { label: '', item: result.filiere, type: 'text', typeAction: null, class: 'text-left' },
                    { label: '', item: email, type: 'text', typeAction: null, class: 'text-left' },
                    { label: '', item: telephone, type: 'text', typeAction: null, class: 'text-left' },
                    { label: '', item: puce_blocage, type: 'icons', typeAction: null, class: 'text-center' },
                    {
                        label: '',
                        text: 'APERÇU >',
                        type: 'actionText',
                        item: result,
                        typeAction: 'openApercu',
                        class: 'reclamation_link ' + class_btn_apercu
                    }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        const etat_comments: any = []
        etat_comments.push({ index: 0, name: 'Commentaires' })
        etat_comments.push({ index: 1, name: 'Tâches à faire' })
        etat_comments.push({ index: 2, name: 'Alerte' })

        this.filtres = [
            {
                libelle: 'Type de commentaire',
                defautOptionlibelle: 'Rechercher par',
                model: 'commentaires',
                value: '',
                index: 'commentaires',
                datas: etat_comments,
                loading: this.$store.state.candidat.loading,
                options: { type: 'deroulant', fieldsKey: 'commentaires' }
            },
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'code',
                value: '',
                index: 'code',
                datas: this.$store.state.candidat.code,
                loading: this.$store.state.candidat.loading,
                options: { type: 'form', fieldsKey: 'code', strict: true }
            },
            {
                libelle: 'Nom',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: this.$store.state.candidat.name,
                loading: this.$store.state.candidat.loading,
                options: { type: 'form', fieldsKey: 'name' }
            },
            {
                libelle: 'Prénom',
                defautOptionlibelle: 'Rechercher une',
                model: 'first_name',
                value: '',
                index: 'first_name',
                datas: this.$store.state.candidat.first_name,
                loading: this.$store.state.candidat.loading,
                options: { type: 'form', fieldsKey: 'first_name' }
            },
            {
                libelle: 'Concours',
                defautOptionlibelle: 'Rechercher un',
                model: 'concours.code',
                value: '',
                index: 'concours.code',
                datas: this.$store.state.candidat.concour_name,
                loading: this.$store.state.candidat.loading,
                options: { type: 'form', fieldsKey: 'concours.code' }
            },
            {
                libelle: 'Filière',
                defautOptionlibelle: 'Rechercher une',
                model: 'concour_id',
                value: '',
                index: 'filiere',
                datas: options_filieres,
                loading: this.$store.state.candidat.loading,
                options: { type: 'deroulant', fieldsKey: 'filiere' }
            },
            {
                libelle: 'E-mail',
                defautOptionlibelle: 'Rechercher un',
                model: 'email',
                value: '',
                index: 'email',
                datas: this.$store.state.candidat.email,
                loading: this.$store.state.candidat.loading,
                options: { type: 'form', fieldsKey: 'email' }
            }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent(paParams: any): void {
        this.$store.state.candidat.error = null
        if (paParams && paParams[0] && paParams[1]) {
            const candidats = this.$store.state.candidat.candidats
            let selectedCandidat = null
            switch (paParams[0]) {
                case 'edit':
                    // Récupération de l'étab by ID
                    selectedCandidat = candidats.filter((candidat: any) => candidat.id === paParams[1])[0]

                    if (selectedCandidat) {
                        this.editCandidat(selectedCandidat)
                    }
                    break

                case 'edit_blocage':
                    if (paParams[1]) {
                        this.editBlocageHandler(paParams[1])
                    }
                    break

                case 'openComment':
                    // Récupération de l'étab by ID
                    this.tabSelected = 'commentaires'
                    selectedCandidat = candidats.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (selectedCandidat) {
                        this.editCandidat(selectedCandidat)
                    }
                    break

                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'detailAjustement':
                    this.$router.push('/liste_epreuve_correction_resultats/' + paParams[1])
                    break
                case 'openApercu':
                    // eslint-disable-next-line no-case-declarations
                    const getPortail = this.$store.getters['candidat/getPortail'](paParams[1].compteCandidat.code)
                    window.open(getPortail, '_blank')
                    // console.log(paParams[1].cle_epreuve_externe)// oidc/auth/candidats/{code}
                    break

                default:
                    break
            }
        }
    }

    /**
     *
     * permet de modifier l'etat "blocage" du candidat
     */
    editBlocageHandler(item: any) {
        if (item && item.id !== undefined) {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.dispatch('candidat/getCandidat', item).then(() => {
                const params = {
                    candidatId: item.id,
                    nomFiliere: item.filiere
                }
                this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
                this.blockSelectedCandidat =
                    this.$store.getters['candidat/selectedCandidat'].portail_candidat_blocked !== 0
                this.showModalEditionBlocageCandidat = true
            })
        }
    }

    cancelEditBlocage() {
        this.showModalEditionBlocageCandidat = false
    }

    saveBlockSelectedCandidat() {
        const payload = {
            candidat_id: this.$store.getters['candidat/selectedCandidat'].id,
            portail_candidat_blocked: this.blockSelectedCandidat ? '1' : '0'
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('candidat/updatePortail', payload)
            .then((result) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$store.getters['candidat/selectedCandidat'].portail_candidat_blocked =
                    result.data.data.portail_candidat_blocked
                this.setDataForGenericTab(this.$store.state.candidat.candidats)
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.showModalEditionBlocageCandidat = false
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.enreigstrementCours = false
            })
    }

    /**
     * Edition d'un candidat: enregistrement du candidat en tant que candidat sélectionnée
     * et affichage de la modale
     */
    editCandidat(item: any) {
        if (item && item.id !== undefined) {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.dispatch('candidat/getCandidat', item).then(() => {
                const params = {
                    candidatId: item.id,
                    nomFiliere: item.filiere
                }
                this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
                this.showModalEditionCandidat = true
            })
        }
    }

    /**
     * Edition des commentaires associés à un candidat
     */
    openCommentaireCandidat(item: any) {
        if (item && item.id !== undefined) {
            this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
            this.showModalEditionCommentCandidat = true
        }
    }

    /**
     * Fermeture de la modale d'édition candidat
     */
    reinitShowModalCandidat() {
        this.showModalEditionCandidat = false
    }

    filtreSortHandler(params: any) {
        if (this.firstLoading) {
            this.firstLoading = false
            return
        }
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.setDataForGenericTab(this.$store.state.candidat.candidats)
            this.params = params
            this.tableLoading = true
            this.$store
                .dispatch('candidat/getCandidats', this.params)
                .then(() => {
                    this.setDataForGenericTab(this.$store.state.candidat.candidats)
                    this.tableLoading = false
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        }
    }

    loadHandler(params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.tableLoading = true
            this.$store
                .dispatch('candidat/getMoreCandidats', this.params)
                .then(() => {
                    this.setDataForGenericTab(this.$store.state.candidat.candidats)
                    this.tableLoading = false
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        }
    }

    updateDatasFiliere(candidat: CandidatInterface) {
        if (candidat && candidat.concour_id && !candidat.filiere) {
            const concour_found = this.$store.state.concour.concours.find((c: any) => c.id === candidat.concour_id)
            if (concour_found) {
                const params = {
                    candidatId: candidat.id,
                    nomFiliere: concour_found.name
                }
                this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
            }
        }
    }

    async load() {
        this.tableLoading = true
        await this.$store.dispatch('concour/getConcoursActifs')
        this.$store.dispatch('candidat/getCandidats', { sort: 'name', direction: 'asc' })
            .then(() => {
                this.setDataForGenericTab(this.$store.state.candidat.candidats)
                this.setFiltersForGenericTab()
                this.tableLoading = false
            })
    }

    async mounted() {
        if (this.$store.getters['auth/user_session_id']) {
            await this.load()
        }
    }
}
