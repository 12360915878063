export interface ConcourPhaseInterface {
    name: string,
    code: string,
    id: number | 0
}

export interface StateConcourPhases {
    concourPhases: Array<ConcourPhaseInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    concourPhaseSelect: ConcourPhaseInterface | {},
    phaseAjustementCompteurs: Array<string> | null,
    phaseCompteurs: Array<any> | null
}

export enum EtatRemonteNote {
    ETAT_NON_LANCE = 0,
    ETAT_EN_COURS = 1,
    ETAT_TRAITE = 2,
    ETAT_ERREUR = 3,
}

export function getEtatRemonteNoteLibelle(etatRemonteNote: EtatRemonteNote): string {
    switch (etatRemonteNote) {
        case EtatRemonteNote.ETAT_NON_LANCE:
            return 'Non lancé'
        case EtatRemonteNote.ETAT_EN_COURS:
            return 'En cours'
        case EtatRemonteNote.ETAT_TRAITE:
            return 'Traité'
        case EtatRemonteNote.ETAT_ERREUR:
            return 'Erreur lors de la remontée'
        default:
            return ''
    }
}
