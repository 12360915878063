








































import { Vue, Component, Watch }    from 'vue-property-decorator'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { formatDate } from '@/utils/helpers'

@Component({
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class ComposantExempleWithoutNav extends Vue
{
    showModalEditionCandidat?:          boolean = false
    showModalEditionCommentCandidat?:   boolean = false
    formatDate = formatDate
    sessionActive: any = null
    tableLoading = false

    Ability = Ability

    // Ensemble des colonnes du tableau de epreuveCorrectionResultats

    genericfields = [
        { key: '', label: 'Filière', sortable: true, class: '', type: 'text' },
        { key: 'epreuve_correction.name', label: 'Epreuve de correction', sortable: true, class: '', type: 'text' },
        { key: 'correction_finished_at', label: 'Correction terminée', sortable: true, class: '', type: 'text' },
        { key: 'moyenne_notes_brutes', label: 'Moyenne notes brutes', sortable: true, class: '', type: 'text' },
        { key: 'ecart_type_notes_brutes', label: 'Écart type notes brutes', sortable: true, class: '', type: 'text' },
        { key: '', label: 'Étape d\'ajustement', sortable: true, class: '', type: 'text' },
        { key: 'moyenne_notes_finales', label: 'Moyenne notes finales', sortable: true, class: '', type: 'text' },
        { key: 'ecart_type_notes_finales', label: 'Écart type notes finales', sortable: true, class: '', type: 'text' },
        { key: 'ajustements_params.arrondi', label: 'Arrondi', sortable: true, class: '', type: 'text' },
        { key: '', label: 'Notes finalisées', sortable: true, class: '', type: 'text' }
    ]

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;

    filtres:    any         = []
    dataForTab: Array<any>  = [
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'LVE - Anglais',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 82,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'LVE - Allemand',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 83,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'LVE - Espagnol',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 84,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'LVE - Arabe',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 85,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'LVE - Italien',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 86,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'LVE - Russe',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 87,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'Informatique',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 88,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'Français',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 89,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'Sciences industrielles',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 90,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'Chimie',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 91,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'Physique 1',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 92,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'Physique 2',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 93,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'Mathématiques 1',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 94,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'Mathématiques 2',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 95,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'LVE - Anglais',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 96,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'LVE - Allemand',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 97,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'LVE - Espagnol',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 98,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'LVE - Arabe',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 99,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'LVE - Italien',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 100,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ],
        [
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: 'Epreuve de correction',
                item: 'LVE - Russe',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: "Pas d'ajustement",
                type: 'text',
                typeAction: null,
                class: 'text-tertiary'
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: '-',
                type: 'text',
                typeAction: null,
                class: ''
            },
            {
                label: '',
                item: [
                    {
                        name: 'circle',
                        class: 'text-tertiary'
                    }
                ],
                type: 'icons',
                typeAction: null,
                class: 'text-center',
                title: ''
            },
            {
                label: '',
                item: 101,
                icon: 'arrow-circle-right',
                type: 'action',
                typeAction: 'detailAjustement',
                class: 'fa-2x text-center text-tertiary',
                disabled: true
            }
        ]
    ]

    footers: Array<any>  = [
        { key: '0', label: 'Libelle 1', colspan: 1, class: 'text-start' },
        { key: '1', label: 'Libelle 2', colspan: 1, class: 'text-start' },
        { key: '2', label: 'Libelle 3', colspan: 1, class: 'text-start' },
        { key: '3', label: 'Libelle 4', colspan: 1, class: 'text-center' },
        { key: '4', label: 'Libelle 5', colspan: 1, class: 'text-start' },
        { key: '5', label: 'Libelle 6', colspan: 1, class: 'text-start' },
        { key: '6', label: 'Libelle 7', colspan: 1, class: 'text-center' },
        { key: '7', label: 'Libelle 8', colspan: 1, class: 'text-start' },
        { key: '8', label: 'Libelle 9', colspan: 1, class: 'text-start'  },
        { key: '9', label: 'Libelle 10', colspan: 2, class: 'text-center bg-primary text-warning' }
    ]

    /**
     * Au montage du composant, on charge les EpreuveCorrectionResultat si cela est nécéssaire
     */
    constructor ()
    {
        super()
        // Chargement des epreuveCorrectionResultats
        this.loadEpreuveCorrectionResultatIfNotExists().then((result) =>
        {
            this.initDatas(result)
        })
        this.filtres = []
    }

    @Watch('loading')
    loadingChangeHandler () {
        this.tableLoading = this.$store.state.candidat.loading
    }

    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const concourTemp = this.$store.getters['concour/concourById'](result.epreuve_correction.epreuves[0].conccour_id)
                const filiere = concourTemp ? concourTemp.name : '-'


                const line = [
                    { label: '', item: filiere, type: 'text', typeAction: null, class: '' },
                    { label: 'Epreuve de correction', item: result.epreuve_correction.name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.correction_finished_at, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.moyenne_notes_brutes, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.ecart_type_notes_brutes, type: 'text', typeAction: null, class: '' },
                    { label: '', item: '', type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.moyenne_notes_finales, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.ecart_type_notes_finales, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.ajustements_params.arrondi, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.notes_finalisees_validated_at, type: 'text', typeAction: null, class: '' }
                ]
                this.dataForTab.push(line)
            }
        }
    }


    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab()
    {
        this.filtres =
        [
            { libelle: 'Epreuve de correction', defautOptionlibelle: 'Rechercher une', model: 'name', value: '', index: 'name', datas: null, loading: false, options: { type: 'form', fieldsKey: 'epreuve_correction.name' } }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            const epreuveCorrectionResultats         = this.$store.state.candidat.epreuveCorrectionResultats
            let selectedCandidat    = null

            switch (paParams[0])
            {
                case 'edit':
                    // Récupération du candidat by ID
                    selectedCandidat = epreuveCorrectionResultats.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (selectedCandidat)
                    {
                        this.editCandidat(selectedCandidat)
                    }
                    break

                case 'openComment':
                    // Récupération du candidat by ID
                    selectedCandidat = epreuveCorrectionResultats.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (selectedCandidat)
                    {
                        this.openCommentaireCandidat(selectedCandidat)
                    }
                    break

                case 'sortHandler':
                    this.loadHandler(paParams[1])
                    break

                case 'filterHandler':
                    this.loadHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break

                default:
                    break
            }
        }
    }


    /**
     * Récupération sur le serveur des 100 premiers epreuveCorrectionResultats s'ils ne sont pas déja
     * présents dans le state
     */
    loadEpreuveCorrectionResultatIfNotExists ()
    {
        return new Promise(() =>
        {
            /* if (this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultats'].length === 0)
            {
                    this.$store.dispatch('concour/getConcours', { session_id: this.$store.getters['auth/user_session_id'], params: null }).then(() => {
                        this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultats', { sort: 'name', direction: 'asc' }).then((result) => {
                            resolve(result)
                        })
                    })
            }
            else
            {
                resolve(true)
            } */
        })
    }


    /**
     * Initialisation des données epreuveCorrectionResultats affichées dans le tableau ainsi que les compteurs
     */
    initDatas (poCandidats: any)
    {
        if (poCandidats && poCandidats.data && poCandidats.data.data)
        {
            this.setDataForGenericTab(poCandidats.data.data)
        }
    }


    /**
     * Appel d'une nouvelle page de epreuveCorrectionResultats lors du scroll
     */
    loadHandler (params: any)
    {
        this.$store.dispatch('epreuveCorrectionResultat/getMoreepreuveCorrectionResultats', params)
    }


    /**
     * Edition d'un candidat: enregistrement du candidat en tant que candidat sélectionné
     * et affichage de la modale
     */
    editCandidat (item: any)
    {
        if (item && item.id !== undefined)
        {
            this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
            this.showModalEditionCandidat = true
        }
    }

    /**
     * Edition des commentaires associés à un candidat
     */
    openCommentaireCandidat (item: any)
    {
        if (item && item.id !== undefined)
        {
            this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
            this.showModalEditionCommentCandidat = true
        }
    }

    /**
     * Fermeture de la modale d'édition candidat
     */
    reinitShowModalCandidat ()
    {
        this.showModalEditionCandidat = false
    }

    /**
     * Fermeture de la modale d'édition des commentaires epreuveCorrectionResultats
     */
    reinitShowModalCommentCandidat ()
    {
        this.showModalEditionCommentCandidat = false
    }
}
