














































import { Vue, Component } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import DashboardOrauxPreparation from '@/components/DashboardOraux/DashboardOrauxPreparation.vue'
import DashboardOrauxPlanification from '@/components/DashboardOraux/DashboardOrauxPlanification.vue'
import AffichageCommentCandidat from '@/components/DashboardOraux/AffichageCommentCandidat.vue'
import { mapState } from 'vuex'

@Component({
    computed: {
        ...mapState('dashboardOraux', ['dashboard_oraux_informations'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        DashboardOrauxPreparation,
        DashboardOrauxPlanification,
        AffichageCommentCandidat
    }
})

export default class DashboardOraux extends Vue {
    tabSelected = 'preparation'
}

