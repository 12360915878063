

















































































































































































































/* Les données affichées dans la page proviennent du clic de la fleche dans DefinitionDesBarres.vue, ce clic charge definitionDesBarres/selectedConcour */
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ListeDesNotes from '@/views/DefinitionDesBarres/ListeDesNotes.vue'
import StatistiquesEpreuves from '@/views/DefinitionDesBarres/StatistiquesEpreuves.vue'
import Back from '@/components/Tools/Back.vue'
import PopupDefinirLesBarres from '@/components/DefinitionDesBarres/PopupDefinirLesBarres.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import StatistiquesConcours from '@/views/DefinitionDesBarres/StatistiquesConcours.vue'
import AnalyseHypothese from '@/views/DefinitionDesBarres/AnalysesHypotheses.vue'
import PopupExportationListeDesNotes from '@/components/DefinitionDesBarres/PopupExportationListeDesNotes.vue'
import { vowelOrNot, getFileNameFromHeader, base64ToArrayBuffer, formatDateVariante, formatDate } from '@/utils/helpers'
import _ from 'lodash'
import { BarTypePass } from '@/types/Barre'

@Component({
    methods: { formatDate, formatDateVariante, vowelOrNot },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('definitionDesBarres', ['tableauDesConcours', 'meta', 'links', 'loading', 'error', 'currentPage', 'lastPage', 'totalPage', 'totalRows', 'selectedConcour']),
        ...mapGetters('definitionBarresListeNotesAdmission', ['historiqueConcourPhase']),
        ...mapGetters('rankingGroup', ['getRankinGroupNameById']),
        showAnalysesHypotheses() {
            return this.$data.concourPhase?.deliberation?.barres?.find((barre: any) => barre.type === 'pass')?.thresholds?.length >= 2
        }
    },
    components: {
        'liste-des-notes': ListeDesNotes,
        'font-awesome-icon': FontAwesomeIcon,
        'statistiques-epreuves': StatistiquesEpreuves,
        'statistiques-concours': StatistiquesConcours,
        'analyse-hypothese': AnalyseHypothese,
        Back,
        PopupDefinirLesBarres,
        ErrorDisplay,
        'popup-exportation-liste-des-notes': PopupExportationListeDesNotes
    }
})

export default class DefinitionDesBarresOnglets extends Vue {
    // ************* DATAS
    concourPhase: any = []

    /* Recup de l'id concour afin de le faire passer dans les onglets */
    concourID                         = this.$route.params.id
    /* PopupDefinir les barres */
    showPopupDefinirBarres            = false
    selectedTab                       = 'listeDesNotes'
    togglePopupValidationBarres       = false
    togglePopupInvalidationBarres     = false
    togglePopupHistoriqueConcourPhase = false
    errorKeeper                       = null
    Ability                           = Ability
    showModalExportation              = false
    session                           = this.$store.getters['session/sessionSelect']
    isBarreAdmissionValidated         = false
    bar_rules: any                    = []
    ranking_groups:Array<any> = []
    codeClient = 'CCMP'
    hiddenBarres = [
        BarTypePass.BAR_TYPE_ANONYMOUS
    ]

    // ************* LISTENERS
    /**
     * Recharge les données si le concours sélectionné change
     * @returns {Promise<void>}
     */
    @Watch('selectedConcour')
    async watchSelecConcour(): Promise<void> {
        this.concourPhase = this.$store.getters['definitionDesBarres/selectedConcour']
        await this.$store.dispatch('definitionBarresListeNotesAdmission/getHistoriqueConcourPhase', { concourphase_id: this.$store.getters['definitionDesBarres/selectedConcour'].id })
    }

    /**
     * @description Recharge l'interface si l'id de la session change
     * @returns {void}
     */
    @Watch('user_session_id')
    refreshInterface(): void {
        this.$router.push({ path: '/definition_des_barres' })
    }

    getBarreRuleByIdOfConcour(id: any, bar_rules: any) {
        for (const bar_rule of bar_rules) {
            if (bar_rule.id === id) {
                return bar_rule
            }
        }
        return null
    }

    /**
     * @description Charge les barres
     * @returns {void}
     */
    @Watch('concourPhase', { deep: true })
    loadBarres() {
        this.bar_rules = []
        this.ranking_groups = []

        if(this.concourPhase.deliberation && this.concourPhase.deliberation.barres){
            for(const barre of this.concourPhase.deliberation.barres) {
                if(!this.hiddenBarres.includes(barre.type)) {
                    for(const threshold of barre.thresholds) {
                        if (!this.ranking_groups.find((b: any) => String(b.ranking_group_id) === String(barre.ranking_group_id))){
                            this.ranking_groups.push({
                                ranking_group_id: barre.ranking_group_id,
                                name: barre.thresholds.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, this.concourPhase.deliberation.bar_rules)?.name : barre.name
                            })
                        }

                        const check_barre = this.bar_rules.find((b: any) => String(b.id) === String(barre.id) && String(b.bar_rule_id) === String(threshold.bar_rule_id))
                        if (!check_barre) {
                            this.bar_rules.push({
                                id: barre.id,
                                value: threshold.value,
                                bar_rule_id: threshold.bar_rule_id,
                                name: barre.thresholds.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, this.concourPhase.deliberation.bar_rules)?.name: barre.name
                            })
                        }
                    }
                }
            }
        }
        if(this.concourPhase.candidatsByRankingGroup) {
            for (let i = 0; i < this.concourPhase.candidatsByRankingGroup.length; i++) {
                for(let j = 0; j < this.ranking_groups.length; j++) {
                    if(String(this.concourPhase.candidatsByRankingGroup[i].ranking_group_id) === String(this.ranking_groups[j].ranking_group_id)) {
                        this.ranking_groups[j].ranking_group_id_count = this.concourPhase.candidatsByRankingGroup[i].ranking_group_id_count
                    }
                }
            }
        }
    }

    // ************* METHODS
    /**
     * @description Permet de savoir si l'utilisateur n'a que les droits externes
     * @returns {boolean}
     */
    onlyExt(): boolean {
        // Sert à afficher correctement le dashboard quand un utilisateur n'a seulement que les droits externes
        const user = this.$store.getters['auth/authUser']
        if (user) {
            const abilities = [ ...new Set(user?.abilities.map((ability: any) => ability.name)) ]
            if (abilities.length > 3) {
                return false
            } else if (this.$store.getters['auth/can'](Ability.EXT_RESULTS_VIEW)) {
                return true
            }
        }
        return false
    }

    /**
     * @description Ouvre le popup de définition des barres
     * @returns {void}
     */
    popupDefinitionDesBarres(): void {
        this.showPopupDefinirBarres = true
    }

    /**
     * @description Ferme le popup de définition des barres
     * @returns {void}
     */
    closePopupDefinirLesBarres(): void {
        this.showPopupDefinirBarres = false
    }

    /**
     * @description Retourne la liste passée en paramètre ordonnée par created_at descendant
     * @param {any} liste - Liste à ordonner
     * @returns {any}
     */
    orderedListeByCreatedDateDesc(liste: any): any {
        return _.orderBy(liste, 'created_at', 'desc')
    }

    /**
     * @description Définit l'onglet sélectionné
     * @param {string} string - Nom de l'onglet
     * @returns {void}
     */
    setSelectedTab(string: string): void {
        this.$store.commit('definitionDesBarres/SET_SELECTED_TAB', string)
    }

    /**
     * @description Ouvre ou ferme le popup de validation des barres
     * @returns {void}
     */
    togglePopupBarreValidation(): void {
        this.errorKeeper = null
        this.$store.commit('definitionDesBarres/SET_ERROR', null)
        this.togglePopupValidationBarres = !this.togglePopupValidationBarres
    }

    /**
     * @description Ouvre ou ferme le popup d'invalidation des barres
     * @returns {void}
     */
    togglePopupBarreInvalidation(): void {
        this.errorKeeper = null
        this.$store.commit('definitionDesBarres/SET_ERROR', null)

        if (!this.togglePopupInvalidationBarres) {
            this.isBarreAdmissionValidated = this.checkIsBarreAdmissionValidated()
        }

        this.togglePopupInvalidationBarres = !this.togglePopupInvalidationBarres
    }

    /**
     * @description Vérifie si les barres sont définies
     * @returns {object | boolean}
     */
    checkBarresDefinition(): object | boolean {
        let barres: any = []
        if (this.concourPhase?.deliberation?.barres && this.concourPhase?.deliberation?.bar_rules) {
            barres = this.concourPhase.deliberation.barres
                .filter((barre: any) => ['pass', 'wildcard'].includes(barre.type) && barre.thresholds.length > 0)
                .map((barre: any) => {
                    return barre.thresholds
                        .map((threshold: any) => {
                            const bar_rule = this.concourPhase.deliberation.bar_rules.find((bar_rule: any) => bar_rule.id === threshold.bar_rule_id)
                            if (bar_rule) {
                                return {
                                    name: bar_rule.name.charAt(0).toUpperCase() + bar_rule.name.slice(1),
                                    value: threshold.value
                                }
                            }
                        })
                        .filter(Boolean);
                })
                .flat(1)

            if (barres.every((b: any) => b.value !== null)) {
                return barres
            }
        }
        return false
    }

    /**
     * @description Vérifie si la barre d'admission a été validée
     * @returns {boolean}
     */
    checkIsBarreAdmissionValidated(): boolean {
        // récupération du concours_phase équivalent pour la phase d'admission
        const concourMatch = this.$store.state.definitionDesBarres.tableauDesConcours
            .filter((c: any) => c.concour_id === this.$store.getters['definitionDesBarres/selectedConcour'].concour_id && c.phase_id === 2)
        if (concourMatch.length > 0) {
            return concourMatch[0].deliberation.barreValide === 1
        }
        return false
    }

    /**
     * @description Valide ou invalide les barres
     * @param {number} state - Etat de validation
     * @returns {void}
     */
    validateInvalidateBarre(state: number): void {
        /* TOAST enregistrement en cours */
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        /* fin toast */

        const params = {
            concourphase: this.concourID,
            _method: 'PUT',
            validate: state,
            data: {
                bar_rules: this.concourPhase.deliberation.bar_rules,
                barres: JSON.parse(JSON.stringify(this.concourPhase.deliberation.barres))
            }
        }
        this.$store.dispatch('definitionDesBarres/putDefinitionDesBarres', params)
            .then(() => {
                const id = parseInt(this.$route.params.id)
                this.$store.dispatch('concourPhase/getConcourPhase',{concourPhase_id: id}).then((resp: any) => {
                    this.concourPhase = resp.data.data
                    this.$store.commit('definitionDesBarres/SET_SELECTED_CONCOUR', this.concourPhase)
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }

                    const message = `${state === 1 ? 'Validation' : 'Invalidation'} des barres terminée.`
                    this.$bvToast.toast(message, succesToaster)
                    if (state === 1) {
                        this.togglePopupBarreValidation()
                    } else {
                        this.togglePopupBarreInvalidation()
                    }
                })
                    .catch(() => {
                        this.errorKeeper = this.$store.getters['definitionDesBarres/error']
                    })
            })
            .catch(() => {
                this.errorKeeper = this.$store.getters['definitionDesBarres/error']
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Exporte la liste des candidats admissibles
     * @param {string} format - Format de l'export
     * @returns {void}
     */
    exportListeCandidatAdmissiblesCng (format = ''): void {
        this.$store.commit('definitionBarresListeNotes/SET_ERROR_EXPORT', null)
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        let payload :any = {
            concour_id : this.concourPhase.concour_id,
            phase_id: this.concourPhase.phase_id
        }
        if (format) {
            payload = {
                ...payload,
                format: format
            }
        } else {
            payload = {
                ...payload,
                headers: { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
                responseType: 'arraybuffer'
            }
        }

        this.$store.dispatch('definitionBarresListeNotes/exportListeCandidatAdmissiblesCng', payload)
            .then((response) => {
                // Logique de téléchargement
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([format ? base64ToArrayBuffer(response.data) : response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || 'exportAdmissibilite.pdf')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Ouvre le popup d'exportation
     * @returns {void}
     */
    exporterListeDesNotes(): void {
        if (this.onlyExt()) {
            const component: any = this.$refs['export_liste_notes']
            component?.exporterListeNotes()
        } else {
            this.showModalExportation = true
        }
    }

    /**
     * @description Ferme le popup d'exportation
     * @returns {void}
     */
    reinitShowModalExportation() {
        this.showModalExportation = false
    }

    /**
     * @description Toggle le popup d'historique des validations / invalidations des barres
     * @returns {void}
     */
    setTogglePopupHistoriqueConcourPhase(): void {
        this.errorKeeper = null
        this.$store.commit('definitionDesBarres/SET_ERROR', null)
        this.togglePopupHistoriqueConcourPhase = !this.togglePopupHistoriqueConcourPhase
    }

    /**
     * @description Retourne les barres de l'historique
     * @param {any} data - Données de l'historique
     * @returns {any}
     */
    getStringBarresHistory(data: any): any {
        const retour = []
        for (const barre of data.barres) {
            if (!this.hiddenBarres.includes(barre.type)) {
                if (barre.thresholds?.length > 0) {
                    for (const threshold of barre.thresholds) {
                        retour.push((barre.thresholds.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, data.bar_rules).name : barre.name) + ' : <b>' + (threshold.value ? threshold.value : '-') + '</b>')
                    }
                } else {
                    retour.push((data.bar_rules?.length > 0 ? this.getBarreRuleByIdOfConcour(barre.concour_id, data.bar_rules).name : barre.nom) + ' : <b>' + (barre.valBarre ? barre.valBarre : '-') + '</b>')
                }
            }
        }
        return retour
    }

    /**
     * @description Charge les données
     * @returns {void}
     */
    load(): void {
        /* toute la logique dessous sert uniquement en cas de refresh de la page */
        const id = parseInt(this.$route.params.id)
        this.concourPhase = this.$store.getters['definitionDesBarres/selectedConcour']
        if (!(this.concourPhase.id && this.concourPhase.id !== id)) {
            this.$store.dispatch('concourPhase/getConcourPhase',{concourPhase_id: id}).then((resp: any) => {
                this.concourPhase = resp.data.data
                this.$store.commit('definitionDesBarres/SET_SELECTED_CONCOUR', this.concourPhase)
            })
        }
    }

    /**
     * @description Montage du composant
     * @returns {void}
     */
    mounted(): void {
        const parameter = this.$store.getters['auth/findParameter']('codeClient')?.value
        this.codeClient = parameter || 'CCMP'
        this.load()
    }
}

