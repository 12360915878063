export interface RankingGroup {
    id: number,
    session_id: number,
    code: Code,
    name: string,
    created_at: string,
    updated_at: string
}

export enum Code {
    CODE_ABSENCE = 'absence',
    CODE_NOTE = 'note',
    CODE_NON_ADMISSIBLE = 'non-admissible',
    CODE_NON_ADMIS = 'non-admis',
    CODE_ADMISSIBLE = 'admissible',
    CODE_ADMIS = 'admis'
}

export function getCode(code: Code): { index: string; labelle: string } {
    switch (code) {
        case Code.CODE_ABSENCE:
            return { index: Code.CODE_ABSENCE, labelle: 'Absent' };
        case Code.CODE_NOTE:
            return { index: Code.CODE_NOTE, labelle: 'Eliminé' };
        case Code.CODE_NON_ADMISSIBLE:
            return { index: Code.CODE_NON_ADMISSIBLE, labelle: 'Non admissible' };
        case Code.CODE_NON_ADMIS:
            return { index: Code.CODE_NON_ADMIS, labelle: 'Non admis(e)' };
        case Code.CODE_ADMISSIBLE:
            return { index: Code.CODE_ADMISSIBLE, labelle: 'Admissible' };
        case Code.CODE_ADMIS:
            return { index: Code.CODE_ADMIS, labelle: 'Admis(e)' };
    }
}
