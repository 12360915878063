





























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import { formatDate, formatNumber, exportDivElementAsImage, getLibellePoste } from '@/utils/helpers'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, BoxplotChart, ScatterChart } from 'echarts/charts'
import {
    DatasetComponent,
    GridComponent,
    MarkLineComponent,
    TitleComponent,
    TooltipComponent,
    TransformComponent
} from 'echarts/components'
import { incrementArrayChartOutliersDetail, getCurrentNbOfCurrentOutliers } from '@/store/modules/EchartsToolsManage'
import { TypePassation } from '@/types/Epreuve'
import _ from 'lodash'

use([
    CanvasRenderer,
    BarChart,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    TransformComponent,
    BoxplotChart,
    ScatterChart,
    CanvasRenderer, MarkLineComponent
])

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('epreuveCorrectionResultat', ['epreuveCorrectionResultatSelect', 'epreuveCorrectionCorrecteurs', 'dicoNotesByUserId', 'notes', 'loading', 'totalRows', 'lastPage', 'totalPage'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ECharts
    }
})

export default class Statistiques extends Vue {
    @Prop() epreuvecorrectionId: any
    @Prop() visible?: boolean

    TypePassation = TypePassation
    getLibellePoste = getLibellePoste
    formatDate = formatDate
    formatNumber = formatNumber
    loadingData = false
    option: any = null
    option2: any = null
    selected_corrector_group_id = 0
    selected_filiere_id = 0
    totalZero = 0
    totalVingt = 0
    epreuve_id = 0
    ecart_type_notes_brutes = '0'
    ecart_type_notes_finales = '0'
    mediane_notes_brutes = 0
    mediane_notes_finales = 0
    moyenne_notes_brutes = '0'
    moyenne_notes_finales = '0'
    premier_quartile_notes_finales = 0
    premier_quartile_notes_brutes = 0
    troisieme_quartile_notes_finales = 0
    troisieme_quartile_notes_brutes = 0
    nb_note_max_brutes = 0
    nb_note_max_finales = 0
    nb_note_min_brutes = 0
    nb_note_min_finales = 0
    nb_class = 20
    note_max = 20
    arrayChartOutliersTotal: any = []
    Ability = Ability

    /**
     * Concaténation des noms des correcteurs
     * @param {any} ecc - Epreuve Correction Correcteurs
     * @returns {string} Noms des correcteurs concaténés
     */
    concatCorrectorsNames(ecc: any): string {
        if (ecc && ecc.scope && ecc.scope.correctors) {
            const correctors = ecc.scope.correctors
            if (correctors && typeof correctors === 'object' && correctors.length > 0) {
                const names_correctors =  correctors.map((corrector: any) => {
                    return `${corrector.name} ${corrector.first_name}`
                })
                return _.orderBy(names_correctors, [], ['asc']).join(', ')
            }
        }
        return ''
    }

    /**
     * Mise à jour du nombre d'outliers
     * @returns {void}
     */
    maj_nb_outliers(): void {
        this.arrayChartOutliersTotal = this.$store.state.echartsToolsManage.arrayChartOutliersTotal
    }

    /**
     * Création des options pour les graphiques
     * @returns {Promise<void>}
     */
    async build_chart_options(): Promise<void> {
        if (this.selected_corrector_group_id !== 0) {
            this.selected_filiere_id = 0
        }
        const data = this.$store.getters['epreuveCorrectionResultat/dicoNotesByUserId'](this.selected_corrector_group_id, this.nb_class, this.note_max, this.epreuve_id)
        // Réinit forcé des tableaux des nbr d'outliers
        this.$store.state.echartsToolsManage.arrayChartOutliersTotal = []
        this.$store.state.echartsToolsManage.arrayChartOutliersDetail = []

        this.totalZero =  data?.finale?.dico[0]?.total || '0'
        this.totalVingt =  data?.finale?.dico[20]?.total || '0'

        this.ecart_type_notes_brutes = formatNumber(data?.ecart_type_notes_brutes) || '0'
        this.ecart_type_notes_finales = formatNumber(data?.ecart_type_notes_finales) || '0'

        this.mediane_notes_brutes = data?.mediane_notes_brutes || '0'
        this.mediane_notes_finales = data?.mediane_notes_finales || '0'

        this.moyenne_notes_brutes = formatNumber(data?.moyenne_notes_brutes) || '0'
        this.moyenne_notes_finales = formatNumber(data?.moyenne_notes_finales) || '0'

        this.premier_quartile_notes_brutes = data?.premier_quartile_notes_brutes || '0'
        this.premier_quartile_notes_finales = data?.premier_quartile_notes_finales || '0'

        this.troisieme_quartile_notes_brutes = data?.troisieme_quartile_notes_brutes || '0'
        this.troisieme_quartile_notes_finales = data?.troisieme_quartile_notes_finales || '0'

        this.nb_note_max_brutes = data?.nb_note_max_brutes || '0'
        this.nb_note_max_finales = data?.nb_note_max_finales || '0'

        this.nb_note_min_brutes = data?.nb_note_min_brutes || '0'
        this.nb_note_min_finales = data?.nb_note_min_finales || '0'

        const xaxisData = []
        const rapnb_saallaNoteMax =  this.note_max / this.nb_class

        const moyForChart = parseFloat(this.moyenne_notes_finales)
        const ecartTypNeg =  parseFloat(this.moyenne_notes_finales) - parseFloat(this.ecart_type_notes_finales)
        const ecartTyPos =  parseFloat(this.moyenne_notes_finales) + parseFloat(this.ecart_type_notes_finales)

        for (let i = 0; i < this.note_max; i = i + rapnb_saallaNoteMax) {
            xaxisData.push('[' + i + '-' + (i + rapnb_saallaNoteMax) + ((i + rapnb_saallaNoteMax) === this.note_max ? ']' : '['))
        }

        this.option = {
            autoresize: true,
            cursor: 'default',
            animationDuration: 500,
            grid: {
                left: 40,
                top: 20,
                right: 40,
                bottom: 5
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c}'
            },
            xAxis: [{
                type: 'category',
                data: xaxisData
            },
            {
                type: 'value',
                min: 0,
                max: this.note_max,
                show: false
            }
            ],
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: data.finale.table,
                    type: 'bar',
                    showBackground: false,
                    silent: false,
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    }
                },
                {
                    xAxisIndex: 1,
                    data : [],
                    type: 'bar',
                    markLine : {
                        symbol:['none', 'none'],
                        silent: false, // ignore mouse events
                        data : [
                            // Horizontal Axis (requires valueIndex = 0)
                            {
                                type : 'average',
                                name: 'Moyenne',
                                valueIndex: 0,
                                xAxis: moyForChart,
                                itemStyle:{ normal:{ color:'red' } },

                                label: {
                                    color: 'red',
                                    formatter: () => { return ''  } // 'Moyenne = ' + moyForChart

                                },

                                lineStyle: {
                                    type: 'solid'
                                }
                            },
                            {
                                type : 'average',
                                name: 'Écart type (' + this.ecart_type_notes_finales + ') ',
                                valueIndex: 0,
                                xAxis: ecartTypNeg,
                                itemStyle:{ normal:{ color:'grey' } },

                                label: {
                                    color: 'grey',
                                    formatter: () => { return '' }

                                },

                                lineStyle: {
                                    type: 'dashed'
                                }
                            },
                            {
                                type : 'average',
                                name: 'Écart type (' + this.ecart_type_notes_finales + ') ',
                                valueIndex: 0,
                                xAxis: ecartTyPos,
                                itemStyle:{ normal:{ color:'grey' } },
                                label: {
                                    color: 'grey',
                                    formatter: () => { return '' }

                                },
                                lineStyle: {
                                    type: 'dashed'
                                }
                            }
                        ]
                    }
                }
            ]
        }

        this.option2 = {
            animationDuration: 500,
            autoresize: true,
            cursor: 'default',
            grid: {
                left: 40,
                top: 0,
                right: 40,
                bottom: 25
            },
            dataset: [
                { source: [data.finale.liste] },
                {
                    transform: {
                        type: 'boxplot'
                    }
                },
                { fromDatasetIndex: 1, fromTransformResult: 1 }
            ],
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (params: any) => {
                    if (params.data && params.data.length > 4) {
                        return [
                            'Supérieur: ' + Math.round(params.data[5] * 100) / 100,
                            '3eme Quartile: ' + Math.round(params.data[4] * 100) / 100,
                            'Mediane: ' + Math.round(params.data[3] * 100) / 100,
                            '1er Quartile: ' + Math.round(params.data[2] * 100) / 100,
                            'Inférieur: ' + Math.round(params.data[1] * 100) / 100
                        ].join('<br/>')
                    } else {
                        return 'Outlier : ' + Math.round(params.data[1] * 100) / 100
                    }
                }
            },
            xAxis: {
                type: 'value',
                name: '',
                min: 0,
                max: this.note_max
            },
            yAxis: {
                name: '',
                type: 'category'
            },
            series: [
                {
                    name: ' ',
                    type: 'boxplot',
                    datasetIndex: 1
                },
                {
                    name: 'Outlier',
                    type: 'scatter',
                    datasetIndex: 2,
                    itemStyle: {
                        color: '#5470C6'
                    },
                    label: {
                        show: true,
                        formatter: function(params: any) {
                            // on se sert du formatage d'un label vide pour faire l'incrémentation des entrées d'Outlier.
                            incrementArrayChartOutliersDetail('chart2', params.value)
                            return ''
                        }
                    },
                    tooltip: {
                        trigger: 'item',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: function(params: any) {
                            // reformatage du tooltip pour l'outlier
                            let nbrEntites = 0
                            if (params.value) {
                                nbrEntites = getCurrentNbOfCurrentOutliers('chart2', params.value)
                            }
                            return [
                                'Outlier : ' + params.value[1],
                                'Entités : ' + nbrEntites
                            ].join('<br/>')
                        }
                    }
                }
            ]
        }
    }

    /**
     * Redimentionnement des graphiques selon écran d'affichage
     * @returns {void}
     */
    resize_charts(): void {
        // Réinit forcé du tableau des nbr d'outliers
        this.$store.state.echartsToolsManage.arrayChartOutliersTotal = []
        this.$store.state.echartsToolsManage.arrayChartOutliersDetail = []

        const c2: any = this.$refs.chart2
        const c1: any = this.$refs.chart1
        const chartBoite1 = this.$refs.chartBoite1 as HTMLElement
        const chartBoite2 = this.$refs.chartBoite2 as HTMLElement

        c1?.resize({
            width: chartBoite1.offsetWidth + 'px',
            height: chartBoite1.offsetHeight + 'px'
        })
        c2?.resize({
            width: chartBoite2.offsetWidth + 'px',
            height: chartBoite2.offsetHeight + 'px'
        })
    }

    /**
     * Chargement des notes
     * @returns {void}
     */
    load_notes(): void {
        this.loadingData = true
        if (parseInt((this.selected_filiere_id).toString()) === 0) {
            this.epreuve_id = 0
            this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultatsNotes', { epreuve_correction_id: this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect'].epreuve_correction_id })
                .then(() => {
                    this.build_chart_options()
                    this.resize_charts()
                    window.addEventListener('resize', this.resize_charts)
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.loadingData = false
                })
        } else {
            this.selected_corrector_group_id = 0
            for (let i = 0; i < this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect'].epreuve_correction.epreuves.length; i++) {
                if (this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect'].epreuve_correction.epreuves[i].concour_id === this.selected_filiere_id) {
                    this.epreuve_id = this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect'].epreuve_correction.epreuves[i].id
                    this.$store.dispatch('epreuveCorrectionResultat/getEpreuveNotes', { epreuve_id: this.epreuve_id })
                        .then(() => {
                            this.build_chart_options()
                            this.resize_charts()
                            window.addEventListener('resize', this.resize_charts)
                        })
                        .catch((error) => {
                            console.log('ko:' + error)
                        })
                        .finally(() => {
                            this.loadingData = false
                        })
                }
            }
        }
    }

    /**
     * Exporter les graphiques
     * @param {string} elementDomId - ID de l'élément DOM
     * @param {string} rootFileExportName - Nom du fichier racine à exporter
     * @returns {void}
     */
    exporter_graph_image (elementDomId: string, rootFileExportName: string) {
        exportDivElementAsImage(elementDomId, rootFileExportName)
    }

    /**
     * Chargement des données
     * @returns {void}
     */
    load_datas(): void {
        this.$store.state.epreuveCorrectionResultat.notes = null
        this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultats', {
            'filter-epreuve_correction_id': this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect'].epreuve_correction_id,
            scoped: 1,
            scope_type: 'corrector-group'
        })
            .then((response) => {
                this.$store.commit('epreuveCorrectionResultat/SET_EPREUVE_CORRECTION_CORRECTEURS', response.data.data)
                this.load_notes()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    /**
     * Montage du composant
     * @returns {void}
     */
    mounted(): void {
        this.loadingData = true
        if (JSON.stringify(this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect) === '{}') {
            this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultat', {
                epreuveCorrectionResultat_id: this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect'].epreuve_correction_id,
                scoped: 1,
                scope_type: 'corrector-group'
            })
                .then(() => {
                    this.load_datas()
                })
        } else {
            this.load_datas()
        }
    }

    /**
     * Avant destruction du composant
     * @returns {void}
     */
    beforeDestroy(): void {
        window.removeEventListener('resize', this.resize_charts)

        if (parseInt((this.selected_filiere_id).toString()) !== 0) {
            this.selected_filiere_id = 0
            this.load_notes()
        }
    }
}
