




























































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { Ability } from '@/types/Ability'
import EditorTinyMCE from '@/components/Tools/EditorTinyMCE.vue'
import { EntityType, getEntityTypeSpec } from '@/types/NotificationTemplate'
import { Decision, DecisionIntervenant, getDecisionSpec } from '@/types/DossierAcademique'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer, checkIcone } from '@/utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        ExaGenericTable,
        EditorTinyMCE,
        VuePdfApp,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('notificationTemplatePortail', ['loading', 'error', 'notificationTemplates', 'meta', 'links', 'totalRows', 'source_pdf']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})

export default class NotificationPortail extends Vue
{
    Ability = Ability

    // Ensemble des colonnes du tableau

    genericfields = [
        { key: 'etatEdit',      label: '', sortable: false, class: '', type: 'action' },
        { key: 'name', label: 'Libellé', sortable: false, class: '', type: 'text' },
        { key: 'champ_descriptif', label: 'Descriptif', sortable: false, class: '', type: 'text' }
    ]

    notificationTemplate: any = null
    notificationTemplateTemp: any = null

    showModalEditionNotificationTemplate = false
    showModalMessageDelete = false
    modeRedaction = true
    modeConsultation = false

    entityType = EntityType
    getterEntityTypeSpec = getEntityTypeSpec

    decision = Decision
    decisionIntervenant = DecisionIntervenant
    getterDecisionSpec = getDecisionSpec

    customToolbar = [[{ header: [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ align: [] }]]
    cursor_in_editor = false
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''
    dataForTab: Array<any> = []

    // Remplissage du tableau
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            const can = this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE)
            const icone = checkIcone(Ability.ADM_PAR_MANAGE, can)

            for (const result of poData)
            {
                const line = [
                    { label: icone.label, item: result, type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.champ_descriptif, type: 'text', typeAction: null, class: '' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    // Ouvre l'aperçu d'un pv
    apercuPdf () {
        const payload = {
            entity_id: this.notificationTemplateTemp.entity_id,
            entity_property: this.notificationTemplateTemp.entity_property,
            entity_type: this.notificationTemplateTemp.entity_type,
            id: this.notificationTemplateTemp.id,
            session_id: this.notificationTemplateTemp.session_id,
            tpl_email_message: this.notificationTemplateTemp.tpl_email_message,
            tpl_email_subject: this.notificationTemplateTemp.tpl_email_subject,
            tpl_pdf_content: this.notificationTemplateTemp.tpl_pdf_content,
            enabled: this.notificationTemplateTemp.enabled
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('notificationTemplatePortail/updateNotificationTemplate', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Modèle enregistré avec succès !', succesToaster)
                this.apercuPdfSuite()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Mode consultation du pdf
    apercuPdfSuite () {
        this.$store.dispatch('notificationTemplatePortail/getPDF', this.notificationTemplateTemp.id).then(() => {
            this.source_doc = base64ToArrayBuffer(this.$store.state.notificationtemplateportail.source_pdf)
            this.modeRedaction = false
            this.modeConsultation = true
        })
    }

    // Fermeture du mode consultation
    back () {
        this.source_doc = ''
        this.modeRedaction = true
        this.modeConsultation = false
    }

    // Récupération des valeurs depuis l'editor
    saveDatasEmailSubject (value: string) {
        this.notificationTemplateTemp.tpl_email_subject = value
    }

    saveDatasEmail (value: string) {
        this.notificationTemplateTemp.tpl_email_message = value
    }

    saveDatasPdf (value: string) {
        this.notificationTemplateTemp.tpl_pdf_content = value
    }

    // Ouvre l'édition d'une notification template
    editNotificationTemplate (row: any) {
        this.notificationTemplate = row
        this.notificationTemplateTemp = {
            champ_descriptif: this.notificationTemplate.champ_descriptif,
            entity_id: this.notificationTemplate.entity_id,
            entity_property: this.notificationTemplate.entity_property,
            entity_property_value: this.notificationTemplate.entity_property_value,
            entity_type: this.notificationTemplate.entity_type,
            id: this.notificationTemplate.id,
            name: this.notificationTemplate.name,
            session_id: this.notificationTemplate.session_id,
            tpl_email_message: this.notificationTemplate.tpl_email_message,
            tpl_email_subject: this.notificationTemplate.tpl_email_subject,
            tpl_pdf_content: this.notificationTemplate.tpl_pdf_content,
            enabled:  this.notificationTemplate.enabled
        }
        if (!this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE)) {
            this.apercuPdfSuite()
        }
        this.showModalEditionNotificationTemplate = true
    }

    // Annule l'ouverture d'une édition
    cancelEdit () {
        this.notificationTemplate = null
        this.notificationTemplateTemp = null
        this.showModalEditionNotificationTemplate = false
    }

    // Enregistre l'édition
    editSuite () {
        const payload = {
            entity_id: this.notificationTemplateTemp.entity_id,
            entity_property: this.notificationTemplateTemp.entity_property,
            entity_type: this.notificationTemplateTemp.entity_type,
            id: this.notificationTemplateTemp.id,
            session_id: this.notificationTemplateTemp.session_id,
            tpl_email_message: this.notificationTemplateTemp.tpl_email_message,
            tpl_email_subject: this.notificationTemplateTemp.tpl_email_subject,
            tpl_pdf_content: this.notificationTemplateTemp.tpl_pdf_content,
            enabled:  this.notificationTemplateTemp.enabled ? 1 : 0
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.notificationTemplateTemp.id === 0) {
            this.$store.dispatch('notificationTemplatePortail/addNotificationTemplate', payload)
                .then(async () => {
                    this.notificationTemplate = null
                    this.notificationTemplateTemp = null
                    this.showModalEditionNotificationTemplate = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Modèle ajouté avec succès !', succesToaster)
                    await this.$store.dispatch('notificationTemplatePortail/getNotificationTemplates')
                    this.setDataForGenericTab(this.$store.state.notificationTemplatePortail.notificationTemplates)
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('notificationTemplatePortail/updateNotificationTemplate', payload)
                .then(async () => {
                    this.notificationTemplate = null
                    this.notificationTemplateTemp = null
                    this.showModalEditionNotificationTemplate = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Modèle enregistré avec succès !', succesToaster)
                    await this.$store.dispatch('notificationTemplatePortail/getNotificationTemplates')
                    this.setDataForGenericTab(this.$store.state.notificationTemplatePortail.notificationTemplates)
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    // Events
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    this.editNotificationTemplate(paParams[1])
                    break
            }
        }
    }

    mounted () {
        this.$store.dispatch('notificationTemplatePortail/getNotificationTemplates').then(() => {
            this.setDataForGenericTab(this.$store.state.notificationTemplatePortail.notificationTemplates)
        })
    }
}
