import { render, staticRenderFns } from "./ParametresMinimum.vue?vue&type=template&id=20b94e53&"
import script from "./ParametresMinimum.vue?vue&type=script&lang=ts&"
export * from "./ParametresMinimum.vue?vue&type=script&lang=ts&"
import style0 from "./ParametresMinimum.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports