

























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { formatNumber, formatDate } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import AjustementNotesSeuils from '@/components/Deliberation/AjustementNotesSeuils.vue'
import { ScopeAjustement } from '@/types/EpreuveCorrectionResultat'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('epreuveCorrectionResultat', ['epreuveCorrectionResultatSelect', 'ajustementIsValidable', 'tableAjustementParams', 'epreuveCorrectionCorrecteurs', 'dicoNotesByUserId', 'notes', 'error', 'loading', 'getAjustement'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay,
        AjustementNotesSeuils
    }
})

export default class GradeThresholdCorrecteurLot extends Vue {
    @Prop() epreuvecorrectionId: any
    @Prop() visible?: boolean
    @Prop() indexAjustement?: number

    formatNumber = formatNumber
    formatDate = formatDate
    ScopeAjustement = ScopeAjustement

    tableLoading = false
    params: any = null
    Ability = Ability
    footers: Array<any>  = []
    footers_supp: Array<any>  = []
    repereEcart = 0.5
    showAjustements = false
    ajustementValue = 0
    genericfields: any = []
    filtres:    any         = []
    dataForTab: Array<any>  = []
    lib_epreuve = ''
    epreuveCorrectionCorrecteurTemp: any = null
    showModalMessageValidation = false
    ecart_moyenne_correcteurInfos: any = null
    validationEnCours = false
    vueSelected = ScopeAjustement.CORRECTOR_GROUP
    libelle_ajustement = ''
    index_actual_ajustement: any = null
    index_prec_ajustement: any = null

    /**
     * @description Affiche le message de confirmation de validation
     * @returns {void}
     */
    validerAjustement(): void {
        this.$store.state.epreuveCorrectionResultat.error = null
        this.showModalMessageValidation = true
    }

    /**
     * @description Ferme le message de confirmation de validation
     * @returns {void}
     */
    cancelValidate(): void {
        this.showModalMessageValidation = false
    }

    /**
     * @description Valide les ajustements
     * @returns {void}
     */
    validateSuite(): void {
        this.validationEnCours = true
        const route_validate = this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect']?.ajustements_params[this.$props.indexAjustement]?.validated_at ? 'invalidateAdjustement': 'validateAdjustement'

        const payload = {
            ajustement_name: this.$props.indexAjustement,
            epreuvecorrectionresultat_id: this.epreuvecorrectionId,
            params: [],
            validate: this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect']?.ajustements_params[this.$props.indexAjustement]?.validated_at ? 0 : 1
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('epreuveCorrectionResultat/'+ route_validate, {
            epreuve_correction_resultat_id: this.epreuvecorrectionId,
            adjustement_id: this.$props.indexAjustement,
            payload: payload
        })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.showModalMessageValidation = false
                this.setDataForGenericTab()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.validationEnCours = false
            })
    }

    /**
     * @description Ferme la popup d'ajustement des notes
     * @returns {void}
     */
    closeAjustements(): void {
        this.showAjustements = false
        this.epreuveCorrectionCorrecteurTemp = null
        this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultat', {
            epreuveCorrectionResultat_id: this.epreuvecorrectionId,
            scoped: 1
        })
            .then(this.initComposant)
    }

    /**
     * @description Remplissage des lignes du tableau
     * @returns {void}
     */
    setDataForGenericTab(): void {
        const dataBrut = {
            mediane: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.mediane_notes_brutes,
            moyenne: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.moyenne_notes_brutes,
            nb_note_max: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.nb_note_max_brutes,
            nb_note_min: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.nb_note_min_brutes,
            premier_quartile: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.premier_quartile_notes_brutes,
            troisieme_quartile: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.troisieme_quartile_notes_brutes,
            ecart_type_notes_brutes: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.ecart_type_notes_brutes
        }

        const beforeInfos = this.indexAjustement && this.indexAjustement > 0 ?  this.$store.getters['epreuveCorrectionResultat/tableAjustementParams'](this.indexAjustement).data : dataBrut
        let libelleAjustementBefore = ''
        if (this.indexAjustement && this.indexAjustement > 0) {
            libelleAjustementBefore = this.$store.getters['epreuveCorrectionResultat/tableAjustementParams'](this.indexAjustement - 1).ajustement
        }

        let count = 0
        let total_moyenne_notes_brutes = 0
        let total_ecartb = 0
        let total_ecart_type_notes_brutes = 0
        let total_nb_note_min_brutes = 0
        let total_nb_note_max_brutes = 0
        let total_moyenne_notes_finales = 0
        let total_ecartf = 0
        let total_nb_note_min_finales = 0
        let total_nb_note_max_finales = 0

        // TODO : Attention normalisation possible sans seuil ?
        this.ecart_moyenne_correcteurInfos = this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect']?.ajustements_params['grade-threshold-adjuster'] && this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect']?.ajustements_params?.normalisation_seuil?.infos ? this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect']?.ajustements_params[this.$props.indexAjustement]?.infos : null

        const poData = this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultats']
            .filter((c: any) => c.scope_type === this.vueSelected)
        this.createFields()
        this.dataForTab = []

        if (poData) {
            for (const result of poData) {
                count++
                const ajustement_correcteur = result.ajustements_params
                let ajust_before_correcteur = null
                if (this.indexAjustement && this.indexAjustement > 0) {
                    ajust_before_correcteur = result.ajustements_params[libelleAjustementBefore].data
                } else {
                    ajust_before_correcteur = {
                        ecart_type: result.ecart_type_notes_brutes,
                        mediane: result.mediane_notes_brutes,
                        moyenne: result.moyenne_notes_brutes,
                        nb_note_max: result.nb_note_max_brutes,
                        nb_note_min: result.nb_note_min_brutes,
                        premier_quartile: result.premier_quartile_notes_brutes,
                        troisieme_quartile: result.troisieme_quartile_notes_brutes
                    }
                }

                let identite = ''
                let lot = ''
                if (this.vueSelected === ScopeAjustement.CORRECTOR_GROUP) {
                    identite = result.scope.correctors
                        .map((corrector: any) => `${corrector.name} ${corrector.first_name}`)
                        .join(', ')
                } else if (this.vueSelected === ScopeAjustement.BATCH_CORRECTION) {
                    lot = 'LOT ' + result.scope.external_num
                    identite = result.scope.corrector_group.correctors
                        .map((corrector: any) => `${corrector.name} ${corrector.first_name}`)
                        .join(', ')
                }

                const moyenne_notes_brutes = formatNumber(ajust_before_correcteur?.moyenne) || '0'
                total_moyenne_notes_brutes += ajust_before_correcteur?.moyenne || 0

                const ecartb = ajust_before_correcteur.moyenne - beforeInfos.moyenne
                total_ecartb += ajust_before_correcteur.moyenne - beforeInfos.moyenne
                const ecartBrute = formatNumber(ecartb)

                const classEcartBrute = 'text-center' + (ecartb > 0 && ecartb > this.repereEcart ? ' text_gras text-success' : (ecartb < 0 && ecartb < (this.repereEcart * -1) ? ' text_gras text-danger' : ''))
                const ecart_type_notes_brutes = formatNumber(ajust_before_correcteur?.ecart_type) || '0'
                total_ecart_type_notes_brutes += ajust_before_correcteur?.ecart_type || 0

                const nb_note_min_brutes = ajust_before_correcteur?.nb_note_min || '0'
                total_nb_note_min_brutes += ajust_before_correcteur?.nb_note_min || 0

                const nb_note_max_brutes = ajust_before_correcteur?.nb_note_max || '0'
                total_nb_note_max_brutes += ajust_before_correcteur?.nb_note_max || 0

                const puce_ajustement = []
                const checkAjustement = () => {
                    if (this.$props.indexAjustement && result.ajustements_params[this.$props.indexAjustement]?.params?.thresholds?.length) {
                        puce_ajustement.push({ name:'check', class: 'text-success', title: 'Ajustements appliqués' })
                    } else {
                        puce_ajustement.push({ name:'minus', class: 'text-secondary', title: 'Aucun ajustement' })
                    }
                }

                if (this.vueSelected === ScopeAjustement.CORRECTOR_GROUP) {
                    const findAjustementLot = this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultats']
                        .filter((ec: any) => ec.scope_type === ScopeAjustement.BATCH_CORRECTION)
                        .filter((ec: any) => ec.scope.corrector_group.correctors.some((corrector: any) => corrector.id === result.scope.correctors[0].id))
                        .filter((ec: any) => ec.ajustements_params[this.$props.indexAjustement]?.params?.thresholds?.length)
                        .length

                    if (findAjustementLot) {
                        puce_ajustement.push({ name:'exclamation-triangle', class: 'text-warning', title: 'Un ajustement sur un des lots de ce correcteur empêche un nouvel ajustement sur l\'ensemble de ses corrections.' })
                    } else {
                        checkAjustement()
                    }
                } else if (this.vueSelected === ScopeAjustement.BATCH_CORRECTION) {
                    const findAjustementCorrecteur = this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultats']
                        .filter((ec: any) => ec.scope_type === ScopeAjustement.CORRECTOR_GROUP)
                        .find((ec: any) => ec.scope.correctors.some((corrector: any) => corrector.id === result.scope.corrector_group.correctors[0].id))
                        .ajustements_params[this.$props.indexAjustement]?.params?.thresholds?.length

                    if (findAjustementCorrecteur) {
                        puce_ajustement.push({ name:'exclamation-triangle', class: 'text-warning', title: 'Un ajustement sur l\'ensemble des notes de ce correcteur empêche un nouvel ajustement sur un des lots de ce correcteur.' })
                    } else {
                        checkAjustement()
                    }
                } else {
                    checkAjustement()
                }

                const moyenne_notes_finales = ajustement_correcteur[this.$props.indexAjustement] ? (formatNumber(ajustement_correcteur[this.$props.indexAjustement]?.infos?.moyenne) || '0') : formatNumber(result.moyenne_notes_finales) // ---
                total_moyenne_notes_finales += ajustement_correcteur[this.$props.indexAjustement] ? (ajustement_correcteur[this.$props.indexAjustement]?.infos?.moyenne || 0) : result.moyenne_notes_finales

                const ecartf  = this.ecart_moyenne_correcteurInfos && ajustement_correcteur[this.$props.indexAjustement]?.infos?.moyenne ? ajustement_correcteur[this.$props.indexAjustement].infos.moyenne - this.ecart_moyenne_correcteurInfos.moyenne : 0
                const ecartFinale = formatNumber(ecartf)
                total_ecartf += ecartf

                const classEcartFinale = (ecartf > 0 && ecartf > this.repereEcart ? ' text_gras text-success' : (ecartf < 0 && ecartf < (this.repereEcart * -1) ? ' text_gras text-danger' : ''))

                const nb_note_min_finales = ajustement_correcteur[this.$props.indexAjustement] ? (ajustement_correcteur[this.$props.indexAjustement]?.infos?.nb_note_min || '0') : result.nb_note_min_finales // ---
                total_nb_note_min_finales += ajustement_correcteur[this.$props.indexAjustement] ? (ajustement_correcteur[this.$props.indexAjustement]?.infos?.nb_note_min || 0) : result.nb_note_min_finales

                const nb_note_max_finales = ajustement_correcteur[this.$props.indexAjustement] ? (ajustement_correcteur[this.$props.indexAjustement]?.infos?.nb_note_max || '0') : result.nb_note_max_finales // ----
                total_nb_note_max_finales += ajustement_correcteur[this.$props.indexAjustement] ? (ajustement_correcteur[this.$props.indexAjustement]?.infos?.nb_note_max || 0) : result.nb_note_max_finales

                const ajustementIsValidate = !!this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect']?.ajustements_params[this.$props.indexAjustement]?.validated_at

                const line = []

                line.push({ label: 'Editer', item: result, type: 'action', typeAction: 'edit', class: 'commons_first_action_button', icon:'pen', disabled: ajustementIsValidate || !!puce_ajustement.find((puce: any) => puce.name === 'exclamation-triangle') })
                line.push({ label: '', item: identite, type: 'text', typeAction: null, class: 'text-start' })

                if (this.vueSelected === ScopeAjustement.BATCH_CORRECTION) {
                    line.push({ label: '', item: lot, type: 'text', typeAction: null, class: '' })
                }

                line.push({ label: '', item: moyenne_notes_brutes, type: 'text', typeAction: null, class: 'text-center' })
                line.push({ label: '', item: ecartBrute, type: 'text', typeAction: null, class: classEcartBrute })
                line.push({ label: '', item: ecart_type_notes_brutes, type: 'text', typeAction: null, class: 'text-center' })
                line.push({ label: '', item: nb_note_min_brutes, type: 'text', typeAction: null, class: 'text-center' })
                line.push({ label: '', item: nb_note_max_brutes, type: 'text', typeAction: null, class: 'text-center' })

                line.push({ label: '', item: puce_ajustement,  type: 'icons', typeAction: null, class: 'text-center background_td' })

                line.push({ label: '', item: moyenne_notes_finales, type: 'text', typeAction: null, class: 'text-center' })
                line.push({ label: '', item: ecartFinale, type: 'text', typeAction: null, class: classEcartFinale })
                line.push({ label: '', item: nb_note_min_finales, type: 'text', typeAction: null, class: 'text-center' })
                line.push({ label: '', item: nb_note_max_finales, type: 'text', typeAction: null, class: 'text-center' })

                this.dataForTab.push(line)
            }
        }

        this.footers = []
        this.footers.push({key: '0', label: '', colspan: 1, class: 'text-start'})
        this.footers.push({key: '1', label: 'Global sur l\'épreuve', colspan: this.vueSelected === ScopeAjustement.BATCH_CORRECTION ? 2 : 1, class: 'text-start'})
        this.footers.push({key: '2', label: formatNumber(beforeInfos?.moyenne) || '-', colspan: 1, class: 'text-center'})
        this.footers.push({key: '3', label: '', colspan: 1, class: 'text-center'})
        this.footers.push({key: '4', label: formatNumber(dataBrut?.ecart_type_notes_brutes) || '99', colspan: 1, class: 'text-center'})
        this.footers.push({key: '5', label: beforeInfos?.nb_note_min || '-', colspan: 1, class: 'text-center'})
        this.footers.push({key: '6', label: beforeInfos?.nb_note_max || '-', colspan: 1, class: 'text-center'})
        this.footers.push({key: '7', label: '', colspan: 1, class: 'text-center'})
        this.footers.push({key: '8', label: formatNumber(this.ecart_moyenne_correcteurInfos?.moyenne) || '-', colspan: 1, class: 'text-center'})
        this.footers.push({key: '9', label: '', colspan: 1, class: 'text-center'})
        this.footers.push({key: '10', label: this.ecart_moyenne_correcteurInfos?.nb_note_min || '-', colspan: 1, class: 'text-center'})
        this.footers.push({key: '11', label: this.ecart_moyenne_correcteurInfos?.nb_note_max || '-', colspan: 1, class: 'text-center'})

        this.footers_supp = []
        this.footers_supp.push({key: '0', label: '', colspan: 1, class: 'text-center'})
        this.footers_supp.push({key: '1', label: 'Moyenne des informations', colspan: this.vueSelected === ScopeAjustement.BATCH_CORRECTION ? 2 : 1, class: 'text-start'})
        this.footers_supp.push({key: '2', label: formatNumber(total_moyenne_notes_brutes / count), colspan: 1, class: 'text-center'})
        this.footers_supp.push({key: '3', label: formatNumber(total_ecartb / count), colspan: 1, class: 'text-center'})
        this.footers_supp.push({key: '4', label: formatNumber(total_ecart_type_notes_brutes / count), colspan: 1, class: 'text-center'})
        this.footers_supp.push({key: '5', label: formatNumber(total_nb_note_min_brutes / count), colspan: 1, class: 'text-center'})
        this.footers_supp.push({key: '6', label: formatNumber(total_nb_note_max_brutes / count), colspan: 1, class: 'text-center'})
        this.footers_supp.push({key: '7', label: '', colspan: 1, class: 'text-center'})
        this.footers_supp.push({key: '8', label: formatNumber(total_moyenne_notes_finales / count), colspan: 1, class: 'text-center'})
        this.footers_supp.push({key: '9', label: formatNumber(total_ecartf / count), colspan: 1, class: 'text-center'})
        this.footers_supp.push({key: '10', label: formatNumber(total_nb_note_min_finales / count), colspan: 1, class: 'text-center'})
        this.footers_supp.push({key: '11', label: formatNumber(total_nb_note_max_finales / count), colspan: 1, class: 'text-center'})
    }

    /**
     * @description Initialisation des filtres
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        this.filtres = []
    }

    /**
     * @description Gestion des événements du tableau
     * @param {any} paParams - Paramètres
     * @returns {void}
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    this.$store.state.epreuveCorrectionResultat.error = null
                    this.epreuveCorrectionCorrecteurTemp = this.$store.getters['epreuveCorrectionResultat/getepreuveCorrectionCorrecteurById'](paParams[1].id)
                    this.ajustementValue = this.epreuveCorrectionCorrecteurTemp?.ajustements_params[this.$props.indexAjustement]?.params?.ecart_moyenne || 0
                    if (this.$props.mode_ajustement !== ScopeAjustement.EPREUVE) {
                        this.load_notes(paParams[1].scope_id)
                    } else {
                        this.showAjustements = true
                    }
                    break
                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
            }
        }
    }

    /**
     * @description Gestion des filtres
     * @param {any} params - Paramètres
     * @returns {void}
     */
    filtreSortHandler(params: any): void {
        params['filter-epreuve_correction_id'] = this.epreuvecorrectionId
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.params.scoped = 1
            this.params['filter-epreuve_correction_id'] = this.epreuvecorrectionId
            this.tableLoading = true
            this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultats', this.params)
                .then((response) => {
                    this.$store.commit('epreuveCorrectionResultat/SET_EPREUVE_CORRECTION_CORRECTEURS', response.data.data)
                    this.setDataForGenericTab()
                })
                .finally(() => this.tableLoading = false)
        }
    }

    /**
     * @description Création des colonnes du tableau
     * @returns {void}
     */
    createFields(): void {
        this.genericfields = []
        this.genericfields.push({ key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' })
        this.genericfields.push({ key: 'user.name', label: 'Correcteur', sortable: true, class: 'text-start', type: 'text' })

        if (this.vueSelected === ScopeAjustement.BATCH_CORRECTION) {
            this.genericfields.push({ key: 'lot', label: 'Lot', sortable: true, class: '', type: 'text' })
        }

        this.genericfields.push({ key: 'moyenne_notes_brutes', label: 'Moyenne notes brutes', sortable: true, class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'ecart', label: 'Écart', sortable: false, class: '', type: 'text' })
        this.genericfields.push({ key: 'ecart_type_notes_brutes', label: 'Écart type note brutes', sortable: true, class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'nb_note_min_brutes', label: 'Nombre de 0 notes brutes', sortable: true, class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'nb_note_max_brutes', label: 'Nombre de 20 notes brutes', sortable: true, class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'ajustement', label: 'Ajustement correcteur', sortable: false, class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'moyenne_notes_finales', label: 'Moyenne notes ajustées', sortable: true, class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'ecartb', label: 'Écart', sortable: false, class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'nb_note_min_finales', label: 'Nombre de 0 notes ajustées', sortable: true, class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'nb_note_max_finales', label: 'Nombre de 20 notes ajustées', sortable: true, class: 'text-center', type: 'text' })
    }

    /**
     * @description Charge les notes
     * @param {number} batch_correction_id - ID du lot
     * @returns {void}
     */
    load_notes(batch_correction_id: number): void {
        this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultatsNotes', {
            epreuve_correction_id: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.epreuve_correction_id
        })
            .then((response) => {
                const notes_sin_lot = response.data.data.filter((n: any) => n.batch_correction_id !== batch_correction_id)
                this.$store.commit('epreuveCorrectionResultat/SET_EPREUVE_CORRECTION_RESULTATS_NOTES_GLOBALES', notes_sin_lot)
                const notes_lot = this.$store.state.epreuveCorrectionResultat.notes.filter((n: any) => n.batch_correction_id === batch_correction_id)
                this.$store.commit('epreuveCorrectionResultat/SET_EPREUVE_CORRECTION_RESULTATS_NOTES', notes_lot)
                this.showAjustements = true
            })
    }

    /**
     * @description Initialisation des composants
     * @returns {void}
     */
    initComposant(): void {
        this.tableLoading = true
        this.filtres = []
        const epcr = this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect']
        const phaseLibelle = epcr?.epreuve_correction?.phase?.name || '-'
        const filiere = epcr?.epreuve_correction?.concours?.map((concour: any) => concour.name).join(', ') || ''
        this.lib_epreuve = `${phaseLibelle} - ${filiere} - ${epcr?.epreuve_correction?.name || ''}`
    }

    /**
     * @description Montage du composant
     * @returns {void}
     */
    mounted(): void {
        this.libelle_ajustement = this.$store.getters['epreuveCorrectionResultat/getAjustement'](this.$props.indexAjustement).name
        this.index_actual_ajustement = this.$store.getters['epreuveCorrectionResultat/tableAjustementParams']
            .findIndex((a: any) => a.ajustement === this.$props.indexAjustement)
        this.index_prec_ajustement = this.$store.getters['epreuveCorrectionResultat/getIndexAjustementPrec'](this.$props.indexAjustement)

        this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultat', {
            epreuveCorrectionResultat_id: this.epreuvecorrectionId,
            scoped: 1
        })
            .then(this.initComposant)
    }
}
