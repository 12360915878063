



















































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { exportDivElementAsImage } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, BoxplotChart, LineChart, ScatterChart } from 'echarts/charts'
import {
    DatasetComponent,
    DataZoomComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    TransformComponent
} from 'echarts/components'
import { BarTypePass } from '@/types/Barre'
import { CandidatConcourPhase } from '@/types/Candidat'
import _ from 'lodash'

use([
    CanvasRenderer,
    BarChart,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    TransformComponent,
    BoxplotChart,
    ScatterChart,
    CanvasRenderer,
    ToolboxComponent,
    DataZoomComponent,
    LineChart,
    LegendComponent,
    MarkLineComponent
])

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('auth', ['user', 'authUser']),
        ...mapGetters('definitionDesBarres', ['selectedTab']),
        ...mapGetters('definitionBarresStatistiquesConcours', ['stateInfosStatistiquesConcours', 'graphIsLoading'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ECharts
    }
})

export default class StatistiquesConcours extends Vue {
    @Prop() concour: any

    Ability = Ability
    barres_total = 0
    thresholds: any = []
    nb_classe = 8
    infosStatConcours: any = []
    option: any  = null
    statistiques: any = []
    classe_values: any = []

    /**
     * @description Charge les données si l'onglet sélectionné est celui des statistiques de concours
     * @returns {void}
     */
    @Watch('selectedTab', { immediate : true })
    watchSelectedTab(): void {
        if (this.$store.getters['definitionDesBarres/selectedTab'] === 'statistiquesConcours') {
            this.initDatas()
        }
    }

    /**
     * @description Initialisation des données
     * @returns {Promise<void>}
     */
    @Watch("$store.state.definitionDesBarres.selectedConcour", { deep : true })
    async initDatas(): Promise<void> {
        // Notes des candidats
        await this.$store.dispatch('definitionBarresStatistiquesConcours/getInfosStatistiquesConcours', {
            concour_id: this.$props.concour.concour_id,
            phase_id: this.$props.concour.phase_id
        })

        // Variables
        const notes = this.$store.getters['definitionBarresStatistiquesConcours/stateInfosStatistiquesConcours']
        const phase = this.$store.getters['concour/concourTemp']?.phases
            .find((phase: any) => phase.phase_id === this.$props.concour.phase_id)
        const barres = phase?.barres
        const barRules = phase?.bar_rules

        // Rassemblement des notes par type de décision et ranking group
        let sortedNotes: any = []
        notes.forEach((note: any) => {
            const arrayFound = sortedNotes
                .find((element: any) => element.decision === note.recu)

            if (arrayFound) {
                arrayFound.count++
                const rankingGroupFound = arrayFound.rankingGroups
                    .find((rankingGroup: any) => rankingGroup.id.toString() === note.rankingGroup?.id.toString())

                if (rankingGroupFound) {
                    rankingGroupFound.count++
                } else {
                    arrayFound.rankingGroups.push({
                        ...note.rankingGroup,
                        count: 1
                    })
                }
            } else {
                let name = ''
                switch (note.recu) {
                    case CandidatConcourPhase.DECISION_AUCUNE:
                        name = 'Sans décision'
                        break;
                    case CandidatConcourPhase.DECISION_RECU:
                        name = 'Non éliminés'
                        break;
                    case CandidatConcourPhase.DECISION_NON_RECU:
                        name = 'Éliminés'
                        break;
                }

                // Évite un affichage vide si pas de barre définie
                if (note.rankingGroup) {
                    sortedNotes.push({
                        decision: note.recu,
                        name: name,
                        count: 1,
                        rankingGroups: [{ ...note.rankingGroup, count: 1 }]
                    })
                }
            }
        })

        // Détail rejet
        const passBarre = barres
            .find((barre: any) => barre.type === BarTypePass.BAR_TYPE_PASS)
        const barRulesPass = passBarre?.thresholds?.map((threshold: any) => threshold.bar_rule_id)
        if (passBarre?.thresholds?.length > 1) {
            const detailsRejet = [
                {
                    name: 'Rejet une barre',
                    count: notes
                        .reduce((total: number, note: any) => {
                            const reasons = note?.infos?.rejectionReasons
                            if (reasons?.length === 1) {
                                const included = barRulesPass.includes(reasons[0])
                                return included ? total + 1 : total
                            }
                            return total
                        }, 0)
                },
                {
                    name: 'Rejet deux barres',
                    count: notes
                        .reduce((total: number, note: any) => {
                            const reasons = note?.infos?.rejectionReasons
                            if (reasons?.length === 2) {
                                const included = barRulesPass.includes(reasons[0]) && barRulesPass.includes(reasons[1])
                                return included ? total + 1 : total
                            }
                            return total
                        }, 0)
                }
            ]

            const arrayFound = sortedNotes
                .find((element: any) => element.decision === CandidatConcourPhase.DECISION_NON_RECU)
            if (arrayFound) {
                arrayFound.detailsRejet = detailsRejet
            }
        }

        // Trie pour affichage
        sortedNotes = _.orderBy(sortedNotes, 'decision', 'asc')
        this.statistiques = sortedNotes

        // Seuils pour la construction des graphiques
        this.thresholds = passBarre?.thresholds
            ?.map((threshold: any) => {
                const barRule = barRules.find((bar_rule: any) => bar_rule.id === threshold.bar_rule_id)
                return {
                    name: barRule.name,
                    id: threshold.bar_rule_id,
                    type: barRule.type,
                    value: threshold.value
                }
            }) || []

        this.classeValuesCreator()
    }

    /**
     * @description Récupère les valeurs de X
     * @param {number} note_max - Note maximale
     * @param {number} classe - Nombre de classes
     * @returns {Array<number>}
     */
    getX(note_max: number, classe: number) {
        const getX: any[] = []
        const nbX: number = Math.round(note_max / classe)

        let numberX = 0
        for (let index = 0; index < nbX; index++) {
            getX.push(numberX)
            /* Valeur d affichage de X */
            numberX += classe
        }
        return getX
    }

    /**
     * @description Récupère les informations statistiques du concours
     * @returns {void}
     */
    infosStatistiquesConcours(): void {
        const infos = this.$store.getters['definitionBarresStatistiquesConcours/stateInfosStatistiquesConcours']
        const nb_candidats = infos.length
        const nb_candidats_recu = infos.reduce((total: number, candidat: any) => candidat.recu === 1 ? total + 1 : total, 0)
        const meilleure_note = [0, 0]

        // Barre principale
        const barre_selected = this.barres_total
        const barre_secondaire = barre_selected === 0 ? 1 : 0

        const barre1: any = []
        // barre2, candadidats ayant validé la 2 eme barres
        const barre2: any = []
        // barre2, candadidats n'ayant pas validé la 2 eme barres
        const barre3: any = []

        infos.forEach((candidat: any): void => {
            if (candidat?.bar_rule_results) {
                // recup des notes du candidats
                const notesCandidat: Array<number> = Object.entries(candidat.bar_rule_results)
                    .filter(([id]: any) => this.thresholds.map((barRule: any) => barRule.id.toString()).includes(id))
                    .map(([, value]: any) => value)
                // calcul premiere barre
                if (notesCandidat[barre_selected] !== null) {
                    // recuperation de la note max
                    if (meilleure_note[barre_selected] < notesCandidat[barre_selected]) {
                        meilleure_note[barre_selected] = notesCandidat[barre_selected]
                    }
                }
            }
        })

        infos.forEach((candidat: any): void => {
            if (candidat?.bar_rule_results) {
                // recup des notes du candidats
                const notesCandidat: Array<number> = Object.entries(candidat.bar_rule_results)
                    .filter(([id]: any) => this.thresholds.map((barRule: any) => barRule.id.toString()).includes(id))
                    .map(([, value]: any) => value);
                const r = Math.floor(notesCandidat[barre_selected] / this.nb_classe)

                // calcul premiere barre
                if (notesCandidat[barre_selected] !== null) {
                    if (!barre1[r]) {
                        barre1[r] = 0
                    }
                    // creation d'un tableau de chiffre barre1[r] correcpond a la valeur X du tableau, on incremente +1 pour ajouter un candidat
                    if (notesCandidat[barre_selected] >= meilleure_note[barre_selected]) {
                        barre1[r - 1] += 1
                    } else if (notesCandidat[barre_selected] < meilleure_note[barre_selected]) {
                        barre1[r] += 1
                    }
                }
                // calcul deuxieme barre, on divise en deux, si >= a la barre de validation, alors on ajoute un candidat dans la barre de validation sinon, barre invalidation
                if (notesCandidat[barre_secondaire] !== null) {
                    if (meilleure_note[barre_secondaire] < notesCandidat[barre_secondaire]) {
                        meilleure_note[barre_secondaire] = notesCandidat[barre_secondaire]
                    }
                    if (!barre2[r]) {
                        barre2[r] = 0
                    }
                    if (
                        this.thresholds[barre_secondaire] &&
                        notesCandidat[barre_secondaire] >=
                        this.thresholds[barre_secondaire].value
                    ) {
                        if (!barre2[r]) {
                            barre2[r] = 0
                        }
                        if (notesCandidat[barre_selected] >= meilleure_note[barre_selected]) {
                            barre2[r - 1] += 1
                        } else if (notesCandidat[barre_selected] < meilleure_note[barre_selected]) {
                            barre2[r] += 1
                        }
                    } else if (
                        this.thresholds[barre_secondaire] &&
                        notesCandidat[barre_secondaire] <
                        this.thresholds[barre_secondaire].value
                    ) {
                        if (!barre3[r]) {
                            barre3[r] = 0
                        }
                        if (notesCandidat[barre_selected] >= meilleure_note[barre_selected]) {
                            barre3[r - 1] += 1
                        } else if (notesCandidat[barre_selected] < meilleure_note[barre_selected]) {
                            barre3[r] += 1
                        }
                    }
                }
            }
        })

        // valeur x = note maximale de la barre
        const axeX = this.getX(meilleure_note[barre_selected], this.nb_classe)
        const barre_name1 = 'Tous les candidats'
        const barre_name2 = this.thresholds[barre_secondaire]
            ? 'Candidats au dessus de la barre ' + this.thresholds[barre_secondaire].name
            : null
        const barre_name3 = this.thresholds[barre_secondaire]
            ? 'Candidats en dessous de la barre ' + this.thresholds[barre_secondaire].name
            : null

        this.infosStatConcours = {
            nb_candidats: nb_candidats,
            nb_candidats_recu: nb_candidats_recu,
            axeX: axeX.map((x: any) => Math.ceil(x)),
            barre1: barre1,
            barre2: barre2,
            barre3: barre3,
            barre_name1: barre_name1,
            barre_name2: barre_name2,
            barre_name3: barre_name3,
            meilleure_note: meilleure_note
        }
    }

    /**
     * @description Création du menu déroulant de nombre de classes en fonction de la barre sélectionnée par l'utilisateur
     * @returns {void}
     */
    @Watch('barres_total')
    classeValuesCreator(): void {
        this.infosStatistiquesConcours()

        // temp est utilisé pour se souvenir du dernier choix de l'utilisateur et configurer nb_classe en fonction.
        const temp = this.classe_values ? this.classe_values.findIndex((classe: { value: number }) => classe.value === this.nb_classe) : null
        if (this.infosStatConcours) {
            this.classe_values = [
                { value: 1, affichage: this.infosStatConcours.meilleure_note[this.barres_total] + ' (' + (this.infosStatConcours.meilleure_note[this.barres_total] / this.infosStatConcours.meilleure_note[this.barres_total]) + ' pts)' },
                { value: this.infosStatConcours.meilleure_note[this.barres_total] / 128, affichage: 128 + ' (' + (Math.round(this.infosStatConcours.meilleure_note[this.barres_total] / 128)) + ' pts)' },
                { value: this.infosStatConcours.meilleure_note[this.barres_total] / 32, affichage: 32 + ' (' + (Math.round(this.infosStatConcours.meilleure_note[this.barres_total] / 32)) + ' pts)' },
                { value: this.infosStatConcours.meilleure_note[this.barres_total] / 20, affichage: 20 + ' (' + (Math.round(this.infosStatConcours.meilleure_note[this.barres_total] / 20)) + ' pts)' },
                { value: this.infosStatConcours.meilleure_note[this.barres_total] / 8, affichage: 8 + ' (' + (Math.round(this.infosStatConcours.meilleure_note[this.barres_total] / 8)) + ' pts)' },
                { value: this.infosStatConcours.meilleure_note[this.barres_total] / 4, affichage: 4 + ' (' + (Math.round(this.infosStatConcours.meilleure_note[this.barres_total] / 4)) + ' pts)' },
                { value: this.infosStatConcours.meilleure_note[this.barres_total] / 2, affichage: 2 + ' (' + (Math.round(this.infosStatConcours.meilleure_note[this.barres_total] / 2)) + ' pts)' },
                { value: this.infosStatConcours.meilleure_note[this.barres_total], affichage: 1 + ' (' + this.infosStatConcours.meilleure_note[this.barres_total] + ' pts)' }
            ]

            this.nb_classe = temp !== -1 && this.classe_values ? this.classe_values[temp].value : this.infosStatConcours.meilleure_note[this.barres_total] / 32
        }
    }

    /**
     * @description Construction et affichage du graphique
     * @returns {void}
     */
    @Watch('nb_classe')
    @Watch('barres_total')
    buildChartOptions(): void {
        this.infosStatistiquesConcours()

        // option du graphique
        const option: any = {
            autoresize: true,
            cursor: 'default',
            calculable: true,
            // legend, dans selected, si pas de barres, on désactive les affichages
            legend: {
                data: [this.infosStatConcours.barre_name1, this.infosStatConcours.barre_name2, this.infosStatConcours.barre_name3],
                selected: { [this.infosStatConcours.barre_name1]: true, [this.infosStatConcours.barre_name2]: !!this.thresholds[0].value, [this.infosStatConcours.barre_name3]: !!this.thresholds[1]?.value }
            },
            dataZoom: [
                {
                    show: true,
                    start: 0,
                    end: this.infosStatConcours.note_max
                }
            ],
            tooltip: {
                trigger: 'item',
                formatter: (seriesIndex: any) => {
                    // si pas d'index superieur, on garde l'affichage de la meilleure note
                    const pointsSuperieur = this.infosStatConcours.axeX[seriesIndex.dataIndex + 1] ? this.infosStatConcours.axeX[seriesIndex.dataIndex + 1] : this.infosStatConcours.meilleure_note[this.barres_total]

                    let affichage = ''
                    let barre_name_screen = ''
                    let totalName = ''

                    // Prefixe pour le premier affichage, le seul non formatté
                    const prefixe_barre_name_screen0 = ' candidats avec '
                    // recuperation des noms des barres.
                    switch (seriesIndex.componentIndex) {
                        case 0:
                            barre_name_screen = this.thresholds[this.barres_total]?.name ? prefixe_barre_name_screen0 + this.thresholds[this.barres_total].name : ''
                            affichage = seriesIndex.data + ' ' + barre_name_screen + ' <br>entre  [' + this.infosStatConcours.axeX[seriesIndex.dataIndex] + ' et ' +  pointsSuperieur  + (this.infosStatConcours.axeX[seriesIndex.dataIndex + 1] ? '[' : ']')
                            break
                        case 1:
                            barre_name_screen = this.infosStatConcours.barre_name2
                            totalName = this.thresholds[this.barres_total]?.name || ''
                            affichage = seriesIndex.data + ' ' + barre_name_screen + '.<br>' + totalName + ' entre  [' + this.infosStatConcours.axeX[seriesIndex.dataIndex] + ' et ' +  pointsSuperieur  + (this.infosStatConcours.axeX[seriesIndex.dataIndex + 1] ? '[' : ']')
                            break
                        case 2:
                            barre_name_screen = this.infosStatConcours.barre_name3
                            totalName = this.thresholds[this.barres_total]?.name || ''
                            affichage = seriesIndex.data + ' ' + barre_name_screen + '.<br>' + totalName + ' entre  [' + this.infosStatConcours.axeX[seriesIndex.dataIndex] + ' et ' +  pointsSuperieur  + (this.infosStatConcours.axeX[seriesIndex.dataIndex + 1] ? '[' : ']')
                            break
                        default:
                            break
                    }

                    return affichage
                }
            },
            xAxis: [{
                type: 'category',
                data: this.infosStatConcours.axeX
            },
            {
                type: 'value',
                min: 0,
                max: this.infosStatConcours.meilleure_note[this.barres_total],
                show: false
            }
            ],
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: this.infosStatConcours.barre_name1,
                    data: this.infosStatConcours.barre1,
                    type: 'bar',
                    showBackground: false,
                    silent: false,
                    color: 'gray'
                }
            ]
        }

        if (this.infosStatConcours.barre_name2 && this.infosStatConcours.barre2) {
            option.series.push({
                name: this.infosStatConcours.barre_name2,
                data: this.infosStatConcours.barre2,
                type: 'bar',
                showBackground: false,
                silent: false,
                color: '#5b9bd5',
                backgroundStyle: {
                    color: 'rgba(115,165,165,1)'
                }
            })
        }

        if (this.infosStatConcours.barre_name3 && this.infosStatConcours.barre3) {
            option.series.push({
                name: this.infosStatConcours.barre_name3,
                data: this.infosStatConcours.barre3,
                type: 'bar',
                showBackground: false,
                silent: false,
                color: '#cfe7dc',
                backgroundStyle: {
                    color: 'rgba(145,145,145,1)'
                }
            })
        }

        option.series.push({
            xAxisIndex: 1,
            data : [],
            type: 'bar',
            silent:true,
            markLine : {
                symbol:['none', 'none'],
                silent: false, // ignore mouse events
                data : [
                    // Horizontal Axis (requires valueIndex = 0)
                    {
                        type : 'average',
                        name: 'Moyenne',
                        valueIndex: 0,
                        xAxis: this.thresholds[this.barres_total].value,
                        itemStyle:{ normal:{ color:'red' } },
                        label: {
                            color: 'red',
                            formatter: () =>
                            {
                                return this.thresholds[this.barres_total].value
                            } // 'Moyenne = ' + moyForChart
                        },

                        lineStyle: {
                            type: 'solid'
                        }
                    }
                ]
            }
        })

        this.option = option
    }

    // Exporte le graph
    /**
     * @description Exporte le graphique en image
     * @param {string} elementDomId - Id de l'élément à exporter
     * @param {string} rootFileExportName - Nom du fichier à exporter
     * @returns {void}
     */
    exportAsImage(elementDomId: string, rootFileExportName: string): void {
        exportDivElementAsImage(elementDomId, rootFileExportName)
    }
}
