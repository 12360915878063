





































import { Vue, Component, Prop } from 'vue-property-decorator'
import { FontAwesomeIcon }      from '@fortawesome/vue-fontawesome'
import { mapState }             from 'vuex'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import ParametresMinimum from '@/views/Planification/ParametresMinimum.vue'
import ParametresOptimum from '@/views/Planification/ParametresOptimum.vue'

@Component({
    computed: {
        ...mapState('session', ['sessionSelect']),
        ...mapState('planification', ['regles_session', 'error_regles'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay,
        ParametresMinimum,
        ParametresOptimum
    }
})
export default class Parametres extends Vue
{
    @Prop() openModal?: boolean
    ongletSelect = 'onglet_minimum'

    /**
     * Fermeture du popup de paramétrage
     */
    closeModal() {
        this.$emit('close', false)
    }

    /**
     * Enregistrement des règles de session
     */
    saveRegles () {
        this.$store.commit('planification/SET_ERROR_REGLES', null)
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('planification/saveReglesSession', {
            session_id: this.$store.state.session.sessionSelect.id,
            payload: { regles : this.$store.state.planification.regles_session }
        })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)

                this.$emit('close', true)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }
}
