




































import { Vue, Component, Watch }  from 'vue-property-decorator'
import { mapGetters, mapState }   from 'vuex'
import { Ability }                from '@/types/Ability'
import  PopupEditCandidat         from '@/components/Candidat/PopupEditCandidat.vue'
import { FontAwesomeIcon }        from '@fortawesome/vue-fontawesome'
import { formatDateDocument }             from '@/utils/helpers'



@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('candidat', ['candidats', 'authUser']),
        ...mapGetters('candidat', ['loading_dashboard_comment', 'candidats_with_important_comments']),
        ...mapGetters('dashboardOraux', ['dashboard_oraux_informations'])
    },
    components: {
        PopupEditCandidat,
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class DashboardOraux extends Vue {
    @Watch('candidats_with_important_comments')
    watch_candidats_with_important_comments() {
        this.candidatsWithComments = this.$store.getters['candidat/candidats_with_important_comments']
    }

    ability                   = Ability
    dashboard_informations    = []
    candidatsWithComments     = []
    // boolean affichage des commentaires candidats
    showModalEditionCandidat  = false

    Ability = Ability

    mounted () {
        // recuperation des candidats puis recup des candidats avec commentaires "A faire"
        this.$store.dispatch('candidat/getCandidatsWithImportantComments')
            .then(() => {
                if (this.$store.getters['candidat/candidats_with_important_comments']) {
                    this.candidatsWithComments = this.$store.getters['candidat/candidats_with_important_comments']
                }
            })
    }

    /*
  *
  *   Gestion de la todo des candidats
  *
  */


    /**
     * Edition d'un candidat: enregistrement du candidat en tant que candidat sélectionné
     * et affichage de la modale
     */
    editCandidat (item: any)
    {
        if (item && item.id !== undefined)
        {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.dispatch('candidat/getCandidat', item).then((resp) => {
                const params =
                {
                    candidatId: item.id,
                    nomFiliere: resp.data.data.filiere
                }
                this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT_FROM_DASHBOARD', resp.data.data)
                this.showModalEditionCandidat = true
            })
        }
    }


    /** gestion de l affichage de la popup edition commentaires d'un candidat  */
    openEditCandidat(selectedCandidat: any) {
        if (selectedCandidat)
        {
            this.editCandidat(selectedCandidat)
        }
    }

    /**
     * Fermeture de la modale d'édition candidat
     */
    reinitShowModalCandidat ()
    {
        this.showModalEditionCandidat = false
    }

    /** formatte la date */
    formatteDate (date: Date) {
        return formatDateDocument(date)
    }
}
