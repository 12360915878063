/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Ability } from '@/types/Ability'
import store from '@/store/index'

// Affichage quand la personne a terminé les taches
interface DashboardDoneInformations {
    type: string,
    doneText: string,
    doneBottomText?: string // utilisable si SmallCardRegularInterface
}

// Interface des cartes
interface SmallCardRegularInterface {
    cardType: string, // regular
    auth: string[],
    key: string,
    title: string,
    numberShow: number,
    textShowSingular: string,
    textShowPlural: string,
    link: string,
    bottomText?: string | undefined,
    done: DashboardDoneInformations
}

interface SmallCardThreeInformationsInterface {
    cardType: string, // threeInformations
    auth: string[],
    title: string,
    link: string,
    key: string,
    information1Number: number,
    information1TextSingular: string,
    information1TextPlural: string,
    information2Number: number,
    information2TextSingular: string,
    information2TextPlural: string,
    information3Number?: number,
    information3TextSingular?: string,
    information3TextPlural?: string,
    done: DashboardDoneInformations
}

interface SmallCardProgressBarInterface {
    cardType: string, // progressBar
    auth: string[],
    title: string,
    key: string,
    fractionNumber1: number,
    fractionNumber2: number,
    textShowSingular: string,
    textShowPlural: string,
    link: string,
    done: DashboardDoneInformations
}

interface SmallCardDuoCardInterface{
    cardType: string, // DUOCARD
    auth: string[],
    key: string,
    titleFirstCard: string,
    numberShowFirstCard: number,
    numberShow2FirstCard?: number,
    textShowSingularFirstCard: string,
    textShowPluralFirstCard: string,
    linkFirstCard: string,
    doneFirstCard: DashboardDoneInformations,
    titleSecondCard: string,
    numberShowSecondCard: number,
    numberShow2SecondCard?: number,
    textShowSingularSecondCard: string,
    textShowPluralSecondCard: string,
    linkSecondCard: string,
    doneSecondCard: DashboardDoneInformations
}

// interface spécifique a signature des documents. Affiche un chiffre + texte en mode normal puis un graphique en mode Done
interface SmallCardSignatureInterface{
    cardType: string, // SIGNATURECARD
    auth: string[],
    title: string,
    numberShow: number,
    textShowSingular: string,
    textShowPlural: string,
    link: string,
    bottomText?: string | undefined,
    done: {
        doneText: string,
        graphNumber1: number,
        graphNumber2: number,
        graphText1: string,
        graphText2: string
    }
}

enum enumReclamations {
    reclamations_ecrit = 1,
    reclamations_oral = 2,
    reclamations_post_concours = 3,
}

// Interface de chaque ligne de cartes
interface BigCardType {
    cardIcon: string,
    auth: string[],
    allCards: (SmallCardRegularInterface | SmallCardThreeInformationsInterface | SmallCardProgressBarInterface | SmallCardDuoCardInterface | SmallCardSignatureInterface)[]
}

const candidats: BigCardType = {
    cardIcon: 'user-friends',
    auth: [Ability.RH_SPS_VIEW],
    allCards: [
        {
            key: 'NbCandidats',
            cardType: 'threeinformations',
            title: 'Gestion des candidats',
            auth: [Ability.RH_SPS_VIEW],
            information1Number: 99,
            information1TextSingular: 'Dossier Candidat',
            information1TextPlural: 'Dossiers Candidats',
            information2Number: 88,
            information2TextSingular: 'Dossier Handicap',
            information2TextPlural: 'Dossiers Handicaps',

            link: '/candidats_concours',
            done: {
                type: 'string',
                doneText: 'doneSingular'
            }
        }
    ]
}

const deliberations: any = {
    cardIcon: 'calculator-alt',
    auth: [Ability.DLB_PREPJURY_VIEW, Ability.DLB_PUBNOT_VIEW],
    allCards: [
        {
            key: 'deliberation_ajustements',
            cardType: 'SMALLCARDTWOFRACTIONS',
            title: 'Ajustement des notes',
            auth: [Ability.DLB_PREPJURY_VIEW],
            fraction1Number1: 0,
            fraction1Number2: 0,
            textShow1Singular:'Épreuve avec ajustements terminés',
            textShow1Plural:'Épreuves avec ajustements terminés',

            fraction2Number1: 0,
            fraction2Number2: 0,
            textShow2Singular:'Filière validée',
            textShow2Plural:'Filières validées',
            link: '/liste_epreuve_correction_resultats',
            done: {
                type: 'string',
                doneText: 'doneSingular'
            }
        },
        {
            key: 'barre_adm',
            cardType: 'duocard',
            auth: [Ability.DLB_PUBNOT_VIEW],

            titleFirstCard: 'Barre d\'admissibilité',
            numberShowFirstCard: 10,
            numberShow2FirstCard: 10,
            textShowSingularFirstCard: 'Concours validé',
            textShowPluralFirstCard: 'Concours validés',
            linkFirstCard: '/definition_des_barres',
            doneFirstCard: {
                type: 'doneRegular',
                doneText: 'Tous les concours sont validés'
            },

            titleSecondCard: 'barre d\'admission',
            numberShowSecondCard: 10,
            numberShow2SecondCard: 10,
            textShowSingularSecondCard: 'Concours validé',
            textShowPluralSecondCard: 'Concours validés',
            linkSecondCard: '/definition_des_barres',
            doneSecondCard: {
                type: 'doneRegular',
                doneText: 'Tous les concours sont validés'
            }
        }
    ]
}


const portailCandidats: any = {
    cardIcon: 'chalkboard-teacher',
    auth: [Ability.DLB_PUBNOT_VIEW],
    allCards: [
        {
            key: 'resultats_portail',
            cardType: 'threeinformations',
            title: 'Portail Cand. - Résultats',
            auth: [Ability.DLB_PUBNOT_VIEW],
            information1Number: 99,
            information1TextSingular: 'Concours d\'admissibilité publié',
            information1TextPlural: 'Concours d\'admissibilité publiés',
            information2Number: 88,
            information2TextSingular: 'Concours d\'admission publié',
            information2TextPlural: 'Concours d\'admission publiés',

            link: '/gestion_portail_candidats',
            done: {
                type: 'string',
                doneText: 'doneSingular'
            }
        },
        {
            key: 'reclamations_portail',
            cardType: 'threeinformations',
            title: 'Portail Cand. - Réclamations',
            auth: [Ability.DLB_PUBNOT_VIEW],
            information1Number: 99,
            information1TextSingular: 'Concours - ECRIT publié',
            information1TextPlural: 'Concours - ECRIT publiés',
            information2Number: 88,
            information2TextSingular: 'Concours - ORAL publié',
            information2TextPlural: 'Concours - ORAL publiés',
            information3Number: 88,
            information3TextSingular: 'Concours - POST CONCOURS publié',
            information3TextPlural: 'Concours - POST CONCOURS publiés',
            link: '/gestion_portail_candidats',
            done: {
                type: 'string',
                doneText: 'doneSingular'
            }
        },
        {
            key: 'demissions_portail',
            cardType: 'threeinformations',
            title: 'Portail Cand. - Démissions',
            auth: [Ability.DLB_PUBNOT_VIEW],
            information1Number: 99,
            information1TextSingular: 'Concours publié',
            information1TextPlural: 'Concours publiés',

            link: '/gestion_portail_candidats',
            done: {
                type: 'string',
                doneText: 'doneSingular'
            }
        },
        {
            key: 'organisation_portail',
            cardType: 'threeinformations',
            title: 'Portail Cand. - Organisation',
            auth: [Ability.DLB_PUBNOT_VIEW],
            information1Number: 99,
            information1TextSingular: 'Série (INFORMATIONS) publiée',
            information1TextPlural: 'Séries (INFORMATIONS) publiées',
            information2Number: 99,
            information2TextSingular: 'Série (PLANNING) publiée',
            information2TextPlural: 'Séries (PLANNING) publiées',
            information3Number: 99,
            information3TextSingular: 'Série (PLANNING COMPLET) publiée',
            information3TextPlural: 'Séries (PLANNING COMPLET) publiées',

            link: '/gestion_portail_candidats',
            done: {
                type: 'string',
                doneText: 'doneSingular'
            }
        },
        {
            key: 'candidats_bloques',
            cardType: 'REGULAR',
            title: 'Portail Cand. - Blocage',
            auth: [Ability.RH_SPS_VIEW],
            numberShow: 1,
            textShowSingular: 'Candidat bloqué',
            textShowPlural: 'Candidats bloqués',

            link: '/gestion_portail_candidats',
            done: {
                type: 'string',
                doneText: 'Aucun candidat bloqué'
            }
        }
    ]
}

const reclamations = {
    cardIcon: 'hand-paper',
    auth: [Ability.ECR_RECLAM_VIEW, Ability.ORAL_RECLAM_VIEW, Ability.ORAL_SEANCE_RECLAM_VIEW],
    allCards: [
        {
            key: 'reclamations_ecrit',
            cardType: 'threeinformations',
            title: 'Réclamations Écrit',
            auth: [Ability.ECR_RECLAM_VIEW],
            information1Number: 0,
            information1TextSingular: 'Déclarée',
            information1TextPlural: 'Déclarées',
            information2Number: 0,
            information2TextSingular: 'Traitée',
            information2TextPlural: 'Traitées',
            information3Number: 0,
            information3TextSingular: 'Signée',
            information3TextPlural: 'Signées',
            link: '/reclamations_ecrit',
            done: {
                type: 'string',
                doneText: 'doneSingular'
            }
        },
        {
            key: 'reclamations_oral',
            cardType: 'threeinformations',
            title: 'Réclamations Oral',
            auth: [Ability.ORAL_SEANCE_RECLAM_VIEW],
            information1Number: 0,
            information1TextSingular: 'Déclarée',
            information1TextPlural: 'Déclarées',
            information2Number: 0,
            information2TextSingular: 'Traitée',
            information2TextPlural: 'Traitées',
            information3Number: 0,
            information3TextSingular: 'Signée',
            information3TextPlural: 'Signées',
            link: '/reclamations_oral',
            done: {
                type: 'string',
                doneText: 'doneSingular'
            }
        },
        {
            key: 'reclamations_post_concours',
            cardType: 'threeinformations',
            title: 'Réclamations Post-Concours',
            auth: [Ability.ORAL_RECLAM_VIEW],
            information1Number: 0,
            information1TextSingular: 'Déclarée',
            information1TextPlural: 'Déclarées',
            information2Number: 0,
            information2TextSingular: 'Traitée',
            information2TextPlural: 'Traitées',
            information3Number: 0,
            information3TextSingular: 'Signée',
            information3TextPlural: 'Signées',
            link: '/reclamations_post_concours',
            done: {
                type: 'string',
                doneText: 'doneSingular'
            }
        }
    ]
}


const functionsForDashboard = {
    dashboardElements: [
        candidats,
        portailCandidats,
        deliberations,
        reclamations
    ],

    // eslint-disable-next-line
  /** Fonction qui filtre les entrées du menu en fonction des droits de l'user */
    dashboardFilterFunction (dashboardElements: BigCardType[] | SmallCardThreeInformationsInterface[] | SmallCardRegularInterface[] | SmallCardRegularInterface[]): unknown {
        const dashboardElementsFilter: Set<any> = new Set()
        for (const element of dashboardElements) {
            for (const ua of element.auth) {
                if (store.getters['auth/can'](ua)) {
                    dashboardElementsFilter.add(element)
                }
            }
        }

        let dashboardFilterArray: any[] = []
        dashboardFilterArray = Array.from(dashboardElementsFilter)
        return dashboardFilterArray
    },

    /**
   * Fonction utilisant userAuth et theFilterFunction. Controle le menu et les sous-menu, renvois le tableau final pret pour SideBarLeft. Utilise theFilterFunction
    */
    // eslint-disable-next-line
  dashboardFiltered(dashboardElements: any[]): any {
        const dashboardFiltered = this.dashboardFilterFunction(dashboardElements)
        this.maj_compteurs()

        for (const element of dashboardElements) {
            if (element.allCards) {
                element.allCards = this.dashboardFilterFunction(element.allCards)
            }
        }
        return dashboardFiltered
    },

    /**
   **  Fonction qui met a jour les différents compteurs aprés retour serveur
  */
    maj_compteurs (): any {
        // infos du back
        const dashboardsInformations = store.getters['dashboardConcours/dashboard_concours_informations']

        // carte gestion des candidats
        const compteur_gestion_candidats = store.getters['user/dashboard'][Ability.CAND_VIEW] ? dashboardsInformations['cand-view'] : null


        const candidats_card: any = candidats.allCards.find((c: any) => c.key === 'NbCandidats')
        if (candidats_card) {
            candidats_card.information1Number = compteur_gestion_candidats ? compteur_gestion_candidats.nb_dossiers_candidat_total : 0
            candidats_card.information2Number = compteur_gestion_candidats ? compteur_gestion_candidats.nb_dossiers_candidat_handicap : 0
        }

        /* Ligne deliberation */
        const compteurs_deliberations = store.getters['user/dashboard'][Ability.DLB_PREPJURY_VIEW] ? dashboardsInformations['dlb.prepjury-view'] : null

        /* Ajustements */
        const ajustement = deliberations.allCards.find((c: any) => c.key === 'deliberation_ajustements')
        if (ajustement && compteurs_deliberations) {
            ajustement.fraction1Number1 = compteurs_deliberations && compteurs_deliberations.nb_epreuves_ajustees ? compteurs_deliberations.nb_epreuves_ajustees : 0

            ajustement.fraction1Number2 = compteurs_deliberations && compteurs_deliberations.nb_epreuves_a_ajuster ?  compteurs_deliberations.nb_epreuves_a_ajuster : 0

            ajustement.fraction2Number1 = compteurs_deliberations && compteurs_deliberations.nb_concours_phases_valides ? compteurs_deliberations.nb_concours_phases_valides : 0

            ajustement.fraction2Number2 = compteurs_deliberations && compteurs_deliberations.nb_concours_phases_a_valider ? compteurs_deliberations.nb_concours_phases_a_valider : 0
        }

        const barres_adm = deliberations.allCards.find((c: any) => c.key === 'barre_adm')
        if (barres_adm && compteurs_deliberations) {
            barres_adm.numberShowFirstCard = compteurs_deliberations && compteurs_deliberations.barres['1'] ? compteurs_deliberations.barres['1'].nb_concours_valides : 0
            barres_adm.numberShow2FirstCard = compteurs_deliberations && compteurs_deliberations.barres['1'] ? compteurs_deliberations.barres['1'].nb_concours_total : 0

            barres_adm.numberShowSecondCard = compteurs_deliberations && compteurs_deliberations.barres['2'] ? compteurs_deliberations.barres['2'].nb_concours_valides : 0
            barres_adm.numberShow2SecondCard = compteurs_deliberations && compteurs_deliberations.barres['2'] ? compteurs_deliberations.barres['2'].nb_concours_total : 0
        }

        /* Portail candidats */
        const compteur_portail_candidats = store.getters['user/dashboard'][Ability.DLB_PUBNOT_VIEW] ? dashboardsInformations['dlb.pubnot-view'] : null
        const resultats_portail = portailCandidats.allCards.find((c: any) => c.key === 'resultats_portail')
        if (resultats_portail) {
            resultats_portail.information1Number = compteur_portail_candidats && compteur_portail_candidats.resultats_admissibilite ? compteur_portail_candidats.resultats_admissibilite.publies + '/' + compteur_portail_candidats.resultats_admissibilite.total : 0
            resultats_portail.information2Number = compteur_portail_candidats && compteur_portail_candidats.resultats_admission ? compteur_portail_candidats.resultats_admission.publies + '/' + compteur_portail_candidats.resultats_admission.total : 0
        }

        const reclamations_portail = portailCandidats.allCards.find((c: any) => c.key === 'reclamations_portail')
        if (reclamations_portail) {
            reclamations_portail.information1Number = compteur_portail_candidats && compteur_portail_candidats.reclamations_ecrits ? compteur_portail_candidats.reclamations_ecrits.publies + '/' + compteur_portail_candidats.reclamations_ecrits.total : 0
            reclamations_portail.information2Number = compteur_portail_candidats && compteur_portail_candidats.reclamations_oraux ? compteur_portail_candidats.reclamations_oraux.publies + '/' + compteur_portail_candidats.reclamations_oraux.total : 0
            reclamations_portail.information3Number = compteur_portail_candidats && compteur_portail_candidats.reclamations_postconcours ? compteur_portail_candidats.reclamations_postconcours.publies + '/' + compteur_portail_candidats.reclamations_postconcours.total : 0
        }

        const demissions_portail = portailCandidats.allCards.find((c: any) => c.key === 'demissions_portail')
        if (demissions_portail) {
            demissions_portail.information1Number = compteur_portail_candidats && compteur_portail_candidats.demissions_admission ? compteur_portail_candidats.demissions_admission.publies + '/' + compteur_portail_candidats.demissions_admission.total : 0
        }

        const organisation_portail = portailCandidats.allCards.find((c: any) => c.key === 'organisation_portail')
        if (organisation_portail) {
            organisation_portail.information1Number = compteur_portail_candidats && compteur_portail_candidats.informations_admission ? compteur_portail_candidats.informations_admission.publies + '/' + compteur_portail_candidats.informations_admission.total : 0
            organisation_portail.information2Number = compteur_portail_candidats && compteur_portail_candidats.planning_admission ? compteur_portail_candidats.planning_admission.publies + '/' + compteur_portail_candidats.planning_admission.total : 0
            organisation_portail.information3Number = compteur_portail_candidats && compteur_portail_candidats.planning_admission_sans_tp ? compteur_portail_candidats.planning_admission_sans_tp.publies + '/' + compteur_portail_candidats.planning_admission_sans_tp.total : 0
        }

        const candidats_bloques = portailCandidats.allCards.find((c: any) => c.key === 'candidats_bloques')
        if (candidats_bloques) {
            candidats_bloques.numberShow = compteur_portail_candidats ? compteur_portail_candidats.nb_candidats_bloques : 0
        }

        /** RECLAMATIONS */
        const compteur_reclamations_ecrit = store.getters['user/dashboard'][Ability.ORAL_RECLAM_VIEW] ? dashboardsInformations['ecr.reclam-view'] : null
        const reclamations_ecrit = reclamations.allCards.find((c: any) => c.key === 'reclamations_ecrit')
        if (reclamations_ecrit) {
            reclamations_ecrit.information1Number = compteur_reclamations_ecrit &&  compteur_reclamations_ecrit.nb_reclamations_declarees ? compteur_reclamations_ecrit.nb_reclamations_declarees : 0

            reclamations_ecrit.information2Number = compteur_reclamations_ecrit &&  compteur_reclamations_ecrit.nb_reclamations_traitees ? compteur_reclamations_ecrit.nb_reclamations_traitees : 0

            reclamations_ecrit.information3Number = compteur_reclamations_ecrit &&  compteur_reclamations_ecrit.nb_reclamations_validees ? compteur_reclamations_ecrit.nb_reclamations_validees : 0
        }

        const compteur_reclamations_seance = store.getters['user/dashboard'][Ability.ORAL_RECLAM_VIEW] ? dashboardsInformations['oral.seance-reclam-view'] : null
        const reclamations_oral = reclamations.allCards.find((c: any) => c.key === 'reclamations_oral')
        if (reclamations_oral) {
            reclamations_oral.information1Number = compteur_reclamations_seance && compteur_reclamations_seance.nb_reclamations_declarees ? compteur_reclamations_seance.nb_reclamations_declarees : 0

            reclamations_oral.information2Number = compteur_reclamations_seance && compteur_reclamations_seance.nb_reclamations_traitees ? compteur_reclamations_seance.nb_reclamations_traitees : 0

            reclamations_oral.information3Number = compteur_reclamations_seance && compteur_reclamations_seance.nb_reclamations_validees ? compteur_reclamations_seance.nb_reclamations_validees : 0
        }

        const compteur_reclamations_postconcours = store.getters['user/dashboard'][Ability.ORAL_RECLAM_VIEW] ? dashboardsInformations['oral.reclam-view'] : null
        const reclamations_post_concours = reclamations.allCards.find((c: any) => c.key === 'reclamations_post_concours')
        if (reclamations_post_concours) {
            reclamations_post_concours.information1Number = compteur_reclamations_postconcours &&  compteur_reclamations_postconcours.nb_reclamations_declarees ? compteur_reclamations_postconcours.nb_reclamations_declarees : 0

            reclamations_post_concours.information2Number = compteur_reclamations_postconcours &&  compteur_reclamations_postconcours.nb_reclamations_traitees ? compteur_reclamations_postconcours.nb_reclamations_traitees : 0

            reclamations_post_concours.information3Number = compteur_reclamations_postconcours &&  compteur_reclamations_postconcours.nb_reclamations_validees ? compteur_reclamations_postconcours.nb_reclamations_validees : 0
        }
    }
}

export { functionsForDashboard, SmallCardRegularInterface, SmallCardProgressBarInterface, SmallCardThreeInformationsInterface, SmallCardDuoCardInterface }
