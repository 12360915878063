










































































































































































































































































































































































































































import DashboardOrauxPlanification from '@/components/DashboardOraux/DashboardOrauxPlanification.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { Ability } from '@/types/Ability'
import { getTypeEnsembleOrder, getTypeEnsembleSpec } from '@/types/Ensemble'
import { EtatHistoPlanification, EtatPlanificationAuto, EtatSuppressionPlanification, TypePlanification } from '@/types/Planification'
import { formatDateSinTime } from '@/utils/helpers'
import Parametres from '@/views/Planification/Parametres.vue'
import Statistiques from '@/views/Planification/Statistiques.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'

@Component({
    computed: {
        ...mapState('session', ['sessionSelect']),
        ...mapState('sessionuser', ['statsValidation']),
        ...mapState('serie', ['series']),
        ...mapState('banque', ['banques']),
        ...mapState('ensemble', ['ensembles']),
        ...mapGetters('auth', ['can']),
        ...mapState('planification', [
            'compteurs_globaux',
            'compteurs_after_planif',
            'avancement',
            'stats_ensembles',
            'planification_en_cours',
            'serie_selected',
            'loading_planification',
            'historique_en_cours',
            'error_planification',
            'info_avancement',
            'historiques'
        ])
    },
    components: {
        ErrorDisplay,
        Parametres,
        Statistiques,
        DashboardOrauxPlanification
    }
})

export default class PlanificationAutomatique extends Vue {
    @Prop() reload_serie?: boolean
    @Prop() histo_en_cours?: boolean
    @Prop() open?: boolean

    Ability                    = Ability
    getTypeEnsembleOrder       = getTypeEnsembleOrder
    serie_select_id: any       = null
    chaine_concours            = ''
    showModalDelete            = false
    delete_manuel              = false
    timeoutid                  = 0
    ensembles_trier: any       = {}
    ensembles_by_concours: any = {}
    params: any                = {}
    EtatPlanificationAuto      = EtatPlanificationAuto
    TypePlanification          = TypePlanification
    formatDateSinTime          = formatDateSinTime
    getTypeEnsembleSpec        = getTypeEnsembleSpec
    showParametres             = false
    showStatistiques           = false
    check_dispo_examinateur    = false
    select_filtres: any        = {
        key: 'full',
        value: null
    }

    /**
     * @description Vérifie si les disponibilités des examinateurs sont validées
     * @returns {void}
     */
    @Watch('statsValidation')
    freshControl(): void {
        if (this.$store.state.sessionuser.statsValidation) {
            this.check_dispo_examinateur =
                this.$store.state.sessionuser.statsValidation.disponibilites_nb_valides ===
                this.$store.state.sessionuser.statsValidation.ensembles_affectations_affectes
        }
    }

    /**
     * @description Charge la série sélectionnée
     * @returns {void}
     */
    @Watch('series')
    loadSerieSelected(): void {
        if (this.$store.state.serie.series.length !== 0 && this.serie_select_id === null) {
            this.serie_select_id = this.$store.state.planification.historique_en_cours?.serie_id || this.$store.state.serie.series[0].id
        }
    }

    /**
     * @description Charge les ensembles triés par type
     * @returns {void}
     */
    @Watch('ensembles')
    loadStats(): void {
        this.ensembles_trier = this.$store.getters['ensemble/ensemblesByType']
        for (const e in this.ensembles_trier) {
            this.ensembles_trier[e] = this.ensembles_trier[e]
                .sort((a: any, b: any) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0)
        }
        this.ensembles_by_concours = this.$store.getters['ensemble/ensembleByBanques']
        this.getStatsPlanification()
    }

    /**
     * @description Charge la chaine de concours pour les équipes transverses
     * @returns {void}
     */
    @Watch('banques')
    loadChaineConcours(): void {
        this.chaine_concours = this.$store.getters['banque/banques']
            ?.map((banque: any) => banque.code)
            ?.join(', ') || ''
    }

    /**
     * @description Récupère la liste des concours pour un ensemble
     * @param {any} ensemble - Ensemble pour lequel on veut récupérer les concours
     * @param {boolean} fromSelect - Si la récupération se fait depuis un select
     * @returns {string}
     */
    getEnsembleChaineConcours(ensemble: any, fromSelect = false): string {
        if (fromSelect && ensemble?.concour?.name) {
            return ensemble.concour.name
        } else if (ensemble?.ensemble_concours) {
            return ensemble.ensemble_concours
        } else {
            if (ensemble.groupeEpreuve === undefined) {
                const ensembleTemp = this.$store.state.ensemble.ensembles
                    .find((e: any) => e.id === parseInt(ensemble.id))

                if (ensembleTemp) {
                    ensemble = ensembleTemp
                }
            }

            if (ensemble?.groupeEpreuve?.epreuves?.length > 0) {
                const retour: string[] = []
                for (let i = 0; i < ensemble.groupeEpreuve.epreuves.length; i++) {
                    if (ensemble?.groupeEpreuve?.epreuves[i]?.epreuves?.length > 0) {
                        for (let j = 0; j < ensemble.groupeEpreuve.epreuves[i].epreuves.length; j++) {
                            retour.push(ensemble.groupeEpreuve.epreuves[i].epreuves[j].concour.code)
                        }
                    } else {
                        retour.push(ensemble.groupeEpreuve.epreuves[i].concour.code)
                    }
                }
                return retour.join(', ') || ''
            }

            return this.chaine_concours
        }
    }

    /**
     * @description Recharge les données de la série sélectionnée
     * @returns {void}
     */
    @Watch('serie_select_id')
    refreshStats(): void {
        this.select_filtres = {
            key: 'full',
            value: null
        }
        this.$store.commit('planification/MAJ_STATE_PLANIFICATION', EtatPlanificationAuto.NON_LANCEE)
        if ((this.$props.params_route && this.$props.params_route.serie_id === null) || !this.$props.params_route) {
            this.loadInfoSerie()
        } else {
            if (this.$store.state.serie.series.length !== 0) {
                const serie_temp = this.$store.state.serie.series
                    .find((s: any) => s.id === Number(this.$props.params_route.serie_id))
                this.$store.commit('planification/SET_SERIE_SELECTED', serie_temp)
                this.$emit('serie_id', Number(this.$props.params_route.serie_id))
            }
        }
    }

    /**
     * @description Recharge les données de la série
     * @returns {void}
     */
    @Watch('reload_serie')
    reloadSerie(): void {
        if (this.$props.reload_serie) {
            this.loadInfoSerie()
        }
    }

    /**
     * @description Récupère les filtres
     * @returns {void}
     */
    @Watch('select_filtres')
    getFiltres(): void {
        this.params = {}
        this.$store.commit('planification/MAJ_STATE_PLANIFICATION', EtatPlanificationAuto.NON_LANCEE)

        switch (this.select_filtres.key) {
            case 'handi':
                Vue.set(this.params, 'handi', 1)
                break

            case 'ensemble_id':
                Vue.set(this.params, 'ensemble_id', Number(this.select_filtres.value))
                Vue.set(this.params, 'type_ensemble', Number(this.select_filtres.type))
                break

            case 'type_ensemble':
                Vue.set(this.params, 'type_ensemble', Number(this.select_filtres.value))
                break

            case 'type_ensemble_concour':
                Vue.set(this.params, 'type_ensemble', Number(this.select_filtres.type))
                Vue.set(this.params, 'banque_id', Number(this.select_filtres.concour))
                break

            case 'full':
                this.params = {}
                break
        }

        if (this.serie_select_id) {
            this.getStatsSerie()
        }
    }

    /**
     * @description Charge les informations de l'historique sélectionné
     * @returns {void}
     */
    @Watch('histo_en_cours')
    watchHistoriqueEnCours(): void {
        const has_histo_en_cours = this.$store.state.planification.historiques
            .find((h: any) => h.etat === EtatHistoPlanification.ETAT_EN_COURS && h.user_id === this.$store.state.auth.user.id)
        if (has_histo_en_cours) {
            this.serie_select_id = has_histo_en_cours.serie_id
            this.loadInfoSerie()

            if (has_histo_en_cours.type === TypePlanification.TYPE_PLANIF_AUTO) {
                this.$store.commit('planification/MAJ_ARRET', false)
                this.getAvancement()
                this.$store.commit('planification/MAJ_STATE_PLANIFICATION', EtatPlanificationAuto.EN_COURS)
            }
        }
    }

    /**
     * @description Retourne le libellé formatté des paramètres sélectionné
     * @returns {string}
     */
    getLibelleParams(): string {
        return this.$store.getters['planification/getLibelleParams'](this.params)
    }

    /**
     * @description Charge les infos de la série sélectionnée
     * @returns {void}
     */
    loadInfoSerie(): void {
        this.$store.commit('planification/SET_ERROR', null)
        const serie_temp = this.$store.state.serie.series.find((s: any) => s.id === this.serie_select_id)
        this.$store.commit('planification/SET_SERIE_SELECTED', serie_temp)
        this.getStatsSerie()
        if (this.$store.state.ensemble.ensembles.length !== 0) {
            this.$store.commit('planification/MAJ_STATE_PLANIFICATION', EtatPlanificationAuto.NON_LANCEE)
        }
        this.$emit('serie_id', Number(this.serie_select_id))
    }

    /**
     * @description Charge les stats de la planification sur une planif faite / non faite / en cours
     * @returns {void}
     */
    getStatsPlanification(): void {
        if (this.$props.open) {
            Vue.set(this.params, 'serie_id', this.serie_select_id)
            this.$store
                .dispatch('planification/getStatsPlanif', {
                    session_id: this.$store.state.session.sessionSelect.id,
                    payload: this.params
                })
                .then(() => {
                    this.$store.commit('planification/SET_LOADING', false)
                })
        }
    }

    /**
     * @description Charge les stats sur la série uniquement
     * @returns {void}
     */
    getStatsSerie(): void {
        if (this.$props.open) {
            Vue.set(this.params, 'serie_id', this.serie_select_id)
            this.$store.dispatch('planification/getStatsSerie', {
                session_id: this.$store.state.session.sessionSelect.id,
                payload: this.params
            })
        }
    }

    /**
     * @description Appel l'avancement de la planification de façon récursive
     * @returns {void}
     */
    getAvancement(): void {
        Vue.set(this.params, 'serie_id', this.serie_select_id)
        this.$store
            .dispatch('planification/getAvancement', {
                histo_planif_id: this.$store.state.planification.historique_en_cours.id,
                payload: this.params
            })
            .then(() => {
                if (this.$store.state.planification.avancement.etat === EtatHistoPlanification.ETAT_EN_COURS) {
                    // Arret demandé en cours
                    if (this.$store.state.planification.avancement.arret === 1) {
                        this.$store.commit(
                            'planification/MAJ_STATE_PLANIFICATION',
                            EtatPlanificationAuto.ARRET_EN_COURS
                        )
                    }

                    // Rappel de l'avancement pour l'actualisé (appel toutes les secondes)
                    clearTimeout(this.timeoutid)
                    this.timeoutid = setTimeout(this.getAvancement, 2000)
                } else {
                    if (!this.$store.state.planification.arret_demander) {
                        // Planification terminée
                        this.$store.commit(
                            'planification/MAJ_STATE_PLANIFICATION',
                            EtatPlanificationAuto.TERMINEE_STATS
                        )
                    } else {
                        // Planification interrompue
                        this.$store.commit(
                            'planification/MAJ_STATE_PLANIFICATION',
                            EtatPlanificationAuto.TERMINEE_ARRETER
                        )
                        this.$store.state.planification.historique_en_cours.etat =
                            this.$store.state.planification.avancement.etat
                    }

                    // MAJ des stats
                    this.getStatsPlanification()
                    // MAJ des stats de la série
                    this.getStatsSerie()
                    // Refresh état des series
                    this.$store.dispatch('serie/getSeries').then(() => {
                        // MAJ historiques
                        this.$store.dispatch('planification/getHistoPlanif', {
                            serie_id: this.$store.state.planification.serie_selected.id,
                            type_planif: TypePlanification.AUCUN
                        })
                        // Clean les historiques
                        this.cleanHistoriques()
                    })
                }
            })
    }

    /**
     * @description Lancement de la planification
     * @returns {void}
     */
    planifier(): void {
        this.$store.commit('planification/MAJ_STATE_PLANIFICATION', EtatPlanificationAuto.NON_LANCEE)
        this.$store.commit('planification/SET_ERROR', null)
        this.$store.commit('planification/MAJ_ARRET', false)
        this.$store.commit('planification/RESET_STATS', false)

        Vue.set(this.params, 'serie_id', this.serie_select_id)

        this.$store
            .dispatch('planification/planifier', {
                session_id: this.$store.state.session.sessionSelect.id,
                payload: this.params
            })
            .then(() => {
                this.$emit('active_edition', true)
                // Premier appel de stats
                this.getAvancement()
            })
    }

    /**
     * @description Arrêter la planification en cours
     * @returns {void}
     */
    stop(): void {
        this.$store.commit('planification/SET_ERROR', null)
        this.$store.dispatch('planification/arreter')
    }

    /**
     * @description Ouvre le popup de choix / confirmation de suppression
     * @returns {void}
     */
    openModalSuppression(): void {
        this.$store.commit('planification/SET_ERROR', null)
        this.showModalDelete = true
    }

    /**
     * @description Annule la suppression de la planification
     * @returns {void}
     */
    cancelDelete(): void {
        this.delete_manuel = false
        this.showModalDelete = false
    }

    /**
     * @description Confirme la suppression de la planification
     * @returns {void}
     */
    confirmDelete(): void {
        Vue.set(this.params, '_method', 'DELETE')
        Vue.set(this.params, 'serie_id', this.serie_select_id)
        Vue.set(
            this.params,
            'etat',
            this.delete_manuel ? EtatSuppressionPlanification.ALL_PLANIF : EtatSuppressionPlanification.PLANIF_AUTO
        )

        this.$store
            .dispatch('planification/deletePlanif', {
                session_id: this.$store.state.session.sessionSelect.id,
                payload: this.params
            })
            .then(() => {
                this.delete_manuel = false
                this.showModalDelete = false
                Vue.set(this.params, '_method', 'POST')
                // MAJ des stats
                this.getStatsPlanification()
                // MAJ des stats de la série
                this.getStatsSerie()
                // MAJ historiques
                this.$store.dispatch('planification/getHistoPlanif', {
                    serie_id: this.$store.state.planification.serie_selected.id,
                    type_planif: TypePlanification.AUCUN
                })
                // Refresh état des séries
                this.$store.dispatch('serie/getSeries')
            })
    }

    /**
     * @description Retourne le nombre de non planifié d'une ligne
     * @param {any} ligne - Ligne de stats
     * @returns {number} - Nombre de non planifié
     */
    countNbNonPlanifie(ligne: any): number {
        let nb_non_planifier = 0
        if (ligne.nbAPlanifier !== ligne.nbPlanifie) {
            nb_non_planifier = ligne.nbAPlanifier - ligne.nbPlanifie
        }
        return nb_non_planifier
    }

    /**
     * @description Ouvre le popup de paramétrage de la planification
     * @returns {void}
     */
    openParametres(): void {
        this.$store.commit('planification/SET_ERROR_REGLES', null)
        this.$store.dispatch('planification/getReglesSession').then(() => {
            this.showParametres = true
        })
    }

    /**
     * @description Ouvre le popup d'affichage des statistiques de la planification
     * @returns {void}
     */
    openStatistiques(): void {
        this.showStatistiques = true
    }

    /**
     * @description Fermeture de la popup des paramètres
     * @param {boolean} withSave - Sauvegarde des données
     * @returns {void}
     */
    closeParametres(withSave: boolean): void {
        if (withSave) {
            const idSucces = 't_succes_' + Math.random()
            const succesToaster = {
                id: idSucces,
                toaster: 'b-toaster-top-right',
                variant: 'success',
                noCloseButton: true,
                fade: true,
                autoHideDelay: 5000
            }
            this.$bvToast.toast('Enregistrement terminé.', succesToaster)
        }

        this.showParametres = false
    }

    /**
     * @description Fermeture la popup des statistiques
     * @returns {void}
     */
    closeStatistiques(): void {
        this.showStatistiques = false
    }

    /**
     * @description Nettoyage des historiques
     * @returns {void}
     */
    cleanHistoriques(): void {
        for (const h in this.$store.state.planification.historiques) {
            const histo_select = this.$store.state.planification.historiques[h]

            if (
                histo_select.etat === EtatHistoPlanification.ETAT_EN_COURS &&
                histo_select.etat === TypePlanification.TYPE_PLANIF_AUTO
            ) {
                histo_select.etat = EtatHistoPlanification.ETAT_ANNULE
            }
        }
    }
}
