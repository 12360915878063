



















































































































































































import { Vue, Component, Prop }    from 'vue-property-decorator'
import { mapGetters }               from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { formatNumber, formatDate } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { ScopeAjustement } from '@/types/EpreuveCorrectionResultat'
import _ from 'lodash'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('epreuveCorrectionResultat', ['epreuveCorrectionResultatSelect', 'ajustementIsValidable', 'tableAjustementParams', 'epreuveCorrectionCorrecteurs', 'dicoNotesByUserId', 'notes', 'error', 'loading'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class EcartMoyenneCorrecteur extends Vue
{
    @Prop() epreuvecorrectionId: any
    @Prop() visible?: boolean
    @Prop() indexAjustement?: number

    formatNumber = formatNumber
    formatDate = formatDate

    tableLoading = true
    params: any = null
    Ability = Ability
    footers: Array<any>  = []
    footers_supp: Array<any>  = []
    repereEcart = 0.5
    showEditEcartMoyenneCorrecteur = false
    actionLimited = false
    ajustementValue = 0

    genericfields = [
        { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'corrector_group_correctors', label: 'Correcteur(s)', sortable: true, class: 'text-start', type: 'text' },
        { key: 'moyenne_notes_brutes', label: 'Moyenne notes brutes', sortable: true, class: 'text-center', type: 'text' },
        { key: 'ecart', label: 'Écart', sortable: false, class: '', type: 'text' },
        { key: 'ecart_type_notes_brutes', label: 'Écart type note brutes', sortable: true, class: 'text-center', type: 'text' },
        { key: 'nb_note_min_brutes', label: 'Nombre de 0 notes brutes', sortable: true, class: 'text-center', type: 'text' },
        { key: 'nb_note_max_brutes', label: 'Nombre de 20 notes brutes', sortable: true, class: 'text-center', type: 'text' },
        { key: 'ajustement', label: 'Ajustement correcteur', sortable: false, class: 'text-center', type: 'text' },
        { key: 'moyenne_notes_finales', label: 'Moyenne notes ajustées', sortable: true, class: 'text-center', type: 'text' },
        { key: 'ecartb', label: 'Écart', sortable: false, class: 'text-center', type: 'text' },
        { key: 'nb_note_min_finales', label: 'Nombre de 0 notes ajustées', sortable: true, class: 'text-center', type: 'text' },
        { key: 'nb_note_max_finales', label: 'Nombre de 20 notes ajustées', sortable: true, class: 'text-center', type: 'text' }
    ]

    filtres:    any         = []
    dataForTab: Array<any>  = []
    lib_epreuve = ''
    epreuveCorrectionCorrecteurTemp: any = null
    showModalMessageValidation = false
    ecart_moyenne_correcteurInfos: any = null
    validationEnCours = false
    enregistrementEnCours = false
    libelle_ajustement = ''
    index_actual_ajustement: any = null
    index_prec_ajustement: any = null

    // Check si les ajustements sont validables
    ajustementIsisValidable (indexAjustement: number, tableAjustementParams: Array<any>) {
        if (indexAjustement) {
            if (tableAjustementParams[indexAjustement - 1] &&
                tableAjustementParams[indexAjustement - 1].data.validated_at == null) {
                return false
            } else if (tableAjustementParams[indexAjustement + 1] &&
              tableAjustementParams[indexAjustement + 1].data.validated_at !== null) {
                return false
            }
        }
        return true
    }

    // Modification de l'input d'écart
    ecartChangeHandler () {
        this.setDataForGenericTab(this.$store.state.epreuveCorrectionResultat.epreuveCorrectionCorrecteurs)
    }

    // Affiche le message de confirmation de validation
    open_confirm_validation () {
        this.$store.state.epreuveCorrectionResultat.error = null
        this.showModalMessageValidation = true
    }

    // Ferme le message de confirmation de validation
    cancel_validation () {
        this.showModalMessageValidation = false
    }

    // Valide les ajustements
    confirm_validation () {
        this.validationEnCours = true
        const route_validate = this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.ajustements_params[this.$props.indexAjustement].validated_at ? 'invalidateAdjustement': 'validateAdjustement'
        const payload = {
            params: [],
            validate: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.ajustements_params[this.$props.indexAjustement].validated_at ? 0 : 1

        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('epreuveCorrectionResultat/'+ route_validate, { epreuve_correction_resultat_id: this.epreuvecorrectionId, adjustement_id: this.$props.indexAjustement, payload: payload})
            .then(async () => {
                await this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultat', { epreuveCorrectionResultat_id: this.epreuvecorrectionId })
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.showModalMessageValidation = false
                this.setDataForGenericTab(this.$store.state.epreuveCorrectionResultat.epreuveCorrectionCorrecteurs)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.validationEnCours = false
                this.$bvToast.hide(idInfo)
            })
    }

    // Sauvegarde de l'ajustement des notes
    saveAjustements () {
        this.enregistrementEnCours = true
        const payload = {
            params: {
                ecart_moyenne: this.ajustementValue
            }
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('epreuveCorrectionResultat/saveAdjustement', {
            epreuve_correction_resultat_id: this.epreuveCorrectionCorrecteurTemp.id,
            adjustement_id: this.$props.indexAjustement,
            payload: payload,
            prevent_store: true
        })
            .then(() => {
                this.tableLoading = true
                this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultats', {
                    'filter-epreuve_correction_id': this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect'].epreuve_correction_id,
                    scoped: 1,
                    scope_type: ScopeAjustement.CORRECTOR_GROUP,
                    ...this.params
                })
                    .then(async (response) => {
                        await this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultat', { epreuveCorrectionResultat_id: this.epreuvecorrectionId })
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                        this.showEditEcartMoyenneCorrecteur = false
                        this.epreuveCorrectionCorrecteurTemp = null
                        this.$store.commit('epreuveCorrectionResultat/SET_EPREUVE_CORRECTION_CORRECTEURS', response.data.data)
                        this.setDataForGenericTab(this.$store.state.epreuveCorrectionResultat.epreuveCorrectionCorrecteurs)
                    })
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.enregistrementEnCours = false
                this.tableLoading = false
            })
    }

    // Ferme la popup d'ajustement des notes
    cancelEdit () {
        this.showEditEcartMoyenneCorrecteur = false
        this.epreuveCorrectionCorrecteurTemp = null
    }

    // Remplissage des lignes
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        const dataBrut = {
            mediane: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.mediane_notes_brutes,
            moyenne: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.moyenne_notes_brutes,
            nb_note_max: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.nb_note_max_brutes,
            nb_note_min: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.nb_note_min_brutes,
            premier_quartile: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.premier_quartile_notes_brutes,
            troisieme_quartile: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.troisieme_quartile_notes_brutes,
            ecart_type_notes_brutes: this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.ecart_type_notes_brutes
        }

        const beforeInfos = this.indexAjustement && this.indexAjustement > 0 ?  this.$store.getters['epreuveCorrectionResultat/tableAjustementParams'](this.indexAjustement).data : dataBrut
        let libelleAjustementBefore = ''
        if (this.indexAjustement && this.indexAjustement > 0) {
            libelleAjustementBefore = this.$store.getters['epreuveCorrectionResultat/tableAjustementParams'](this.indexAjustement - 1).ajustement
        }

        let count = 0
        let total_moyenne_notes_brutes = 0
        let total_ecartb = 0
        let total_ecart_type_notes_brutes = 0
        let total_nb_note_min_brutes = 0
        let total_nb_note_max_brutes = 0
        let total_moyenne_notes_finales = 0
        let total_ecartf = 0
        let total_nb_note_min_finales = 0
        let total_nb_note_max_finales = 0

        this.ecart_moyenne_correcteurInfos = this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect?.ajustements_params[this.$props.indexAjustement]?.infos !== undefined ?
            this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.ajustements_params[this.$props.indexAjustement].infos : null
        if (poData)
        {
            for (const result of poData)
            {
                count++
                const ajustement_correcteur = result.ajustements_params
                let ajust_before_correcteur = null
                if (this.indexAjustement && this.indexAjustement > 0) {
                    ajust_before_correcteur = result.ajustements_params[libelleAjustementBefore].data
                } else {
                    ajust_before_correcteur = {
                        ecart_type: result.ecart_type_notes_brutes || 0,
                        mediane: result.mediane_notes_brutes || 0,
                        moyenne: result.moyenne_notes_brutes || 0,
                        nb_note_max: result.nb_note_max_brutes || 0,
                        nb_note_min: result.nb_note_min_brutes || 0,
                        premier_quartile: result.premier_quartile_notes_brutes || 0,
                        troisieme_quartile: result.troisieme_quartile_notes_brutes || 0
                    }
                }

                let identite = '-'
                if (result && result.scope && result.scope.correctors) {
                    identite = _.orderBy(
                        result.scope.correctors.map((corrector: any) => {
                            return `${corrector.name} ${corrector.first_name}`
                        }),
                        [],
                        ['asc']
                    ).join(', ')
                }

                const moyenne_notes_brutes = formatNumber(ajust_before_correcteur.moyenne)
                total_moyenne_notes_brutes += ajust_before_correcteur.moyenne

                const ecartb = ajust_before_correcteur.moyenne - beforeInfos.moyenne
                total_ecartb += ajust_before_correcteur.moyenne - beforeInfos.moyenne
                const ecartBrute = formatNumber(ecartb)

                const classEcartBrute = 'text-center' + (ecartb > 0 && ecartb > this.repereEcart
                    ? ' text_gras text-success' : (ecartb < 0 && ecartb < (this.repereEcart * -1) ? ' text_gras text-danger' : ''))
                const ecart_type_notes_brutes = formatNumber(ajust_before_correcteur.ecart_type)
                total_ecart_type_notes_brutes += ajust_before_correcteur.ecart_type

                const nb_note_min_brutes = ajust_before_correcteur.nb_note_min
                total_nb_note_min_brutes += ajust_before_correcteur.nb_note_min

                const nb_note_max_brutes = ajust_before_correcteur.nb_note_max
                total_nb_note_max_brutes += ajust_before_correcteur.nb_note_max

                const ajustement = ajustement_correcteur[this.$props.indexAjustement]?.params?.ecart_moyenne !== undefined
                    ? ajustement_correcteur[this.$props.indexAjustement].params.ecart_moyenne : '-'
                const classAjustement = ajustement !== '-' ? (ajustement > 0 ? 'text-center text_gras text-success' : 'text-center text_gras text-danger') : ''

                const moyenne_notes_finales = ajustement_correcteur?.[this.$props.indexAjustement]?.infos?.moyenne !== undefined
                    ? formatNumber(ajustement_correcteur[this.$props.indexAjustement].infos.moyenne) : formatNumber(ajust_before_correcteur.moyenne)
                total_moyenne_notes_finales += ajustement_correcteur?.[this.$props.indexAjustement]?.infos?.moyenne !== undefined
                    ? ajustement_correcteur[this.$props.indexAjustement].infos.moyenne : ajust_before_correcteur.moyenne

                const ecartf = this.ecart_moyenne_correcteurInfos && ajustement_correcteur?.[this.$props.indexAjustement]
                    ? ajustement_correcteur[this.$props.indexAjustement].infos.moyenne - this.ecart_moyenne_correcteurInfos.moyenne : ajust_before_correcteur.moyenne - this.ecart_moyenne_correcteurInfos.moyenne
                const ecartFinale = formatNumber(ecartf)
                total_ecartf += ecartf

                const classEcartFinale = (ecartf > 0 && ecartf > this.repereEcart ? ' text_gras text-success' : (ecartf < 0 && ecartf < (this.repereEcart * -1) ? ' text_gras text-danger' : ''))

                const nb_note_min_finales = ajustement_correcteur?.[this.$props.indexAjustement]?.infos?.nb_note_min !== undefined
                    ? ajustement_correcteur[this.$props.indexAjustement].infos.nb_note_min : ajust_before_correcteur.nb_note_min
                total_nb_note_min_finales += ajustement_correcteur?.[this.$props.indexAjustement]?.infos?.nb_note_min !== undefined ?
                    ajustement_correcteur[this.$props.indexAjustement].infos.nb_note_min : ajust_before_correcteur.nb_note_min

                const nb_note_max_finales = ajustement_correcteur?.[this.$props.indexAjustement]?.infos?.nb_note_max !== undefined ?
                    ajustement_correcteur[this.$props.indexAjustement].infos.nb_note_max : ajust_before_correcteur.nb_note_max
                total_nb_note_max_finales += ajustement_correcteur?.[this.$props.indexAjustement]?.infos?.nb_note_max !== undefined
                    ? ajustement_correcteur[this.$props.indexAjustement].infos.nb_note_max : ajust_before_correcteur.nb_note_max

                const ajustementIsValidate = this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.ajustements_params[this.$props.indexAjustement].validated_at !== null

                const line = [
                    { label: 'Editer', item: result, type: 'action', typeAction: 'edit', class: 'commons_first_action_button', icon:'pen', disabled: ajustementIsValidate },
                    { label: '', item: identite, type: 'text', typeAction: null, class: 'text-start' },
                    { label: '', item: moyenne_notes_brutes, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: ecartBrute, type: 'text', typeAction: null, class: classEcartBrute },
                    { label: '', item: ecart_type_notes_brutes, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: nb_note_min_brutes, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: nb_note_max_brutes, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: ajustement, type: 'text', typeAction: null, class: 'text-center background_td ' + classAjustement },
                    { label: '', item: moyenne_notes_finales, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: ecartFinale, type: 'text', typeAction: null, class: classEcartFinale },
                    { label: '', item: nb_note_min_finales, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: nb_note_max_finales, type: 'text', typeAction: null, class: 'text-center' }
                ]
                this.dataForTab.push(line)
            }
        }
        this.footers = [
            {
                key: '0',
                label: '',
                colspan: 1,
                class: 'text-start'
            },
            {
                key: '1',
                label: 'Global sur l\'épreuve',
                colspan: 1,
                class: 'text-start'
            },
            {
                key: '2',
                label: beforeInfos.moyenne ? formatNumber(beforeInfos.moyenne) : '-',
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '3',
                label: '',
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '4',
                label: dataBrut.ecart_type_notes_brutes ? formatNumber(dataBrut.ecart_type_notes_brutes) : '99',
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '5',
                label: beforeInfos.nb_note_min ? beforeInfos.nb_note_min : '-',
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '6',
                label: beforeInfos.nb_note_max ? beforeInfos.nb_note_max : '-',
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '7',
                label: '',
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '8',
                label: this.ecart_moyenne_correcteurInfos?.moyenne !== undefined ? formatNumber(this.ecart_moyenne_correcteurInfos.moyenne) : '-',
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '9',
                label: '',
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '10',
                label: this.ecart_moyenne_correcteurInfos?.nb_note_min !== undefined ? this.ecart_moyenne_correcteurInfos.nb_note_min : '-',
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '11',
                label: this.ecart_moyenne_correcteurInfos?.nb_note_max !== undefined ? this.ecart_moyenne_correcteurInfos.nb_note_max : '-',
                colspan: 1,
                class: 'text-center'
            }
        ]

        this.footers_supp = [
            {
                key: '0',
                label: '',
                colspan: 1,
                class: 'text-start'
            },
            {
                key: '1',
                label: 'Moyenne des informations',
                colspan: 1,
                class: 'text-start'
            },
            {
                key: '2',
                label: formatNumber(total_moyenne_notes_brutes / count),
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '3',
                label: formatNumber(total_ecartb / count),
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '4',
                label:formatNumber(total_ecart_type_notes_brutes / count),
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '5',
                label: formatNumber(total_nb_note_min_brutes / count),
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '6',
                label: formatNumber(total_nb_note_max_brutes / count),
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '7',
                label: '-',
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '8',
                label: formatNumber(total_moyenne_notes_finales / count),
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '9',
                label: formatNumber(total_ecartf / count),
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '10',
                label: formatNumber(total_nb_note_min_finales / count),
                colspan: 1,
                class: 'text-center'
            },
            {
                key: '11',
                label: formatNumber(total_nb_note_max_finales / count),
                colspan: 1,
                class: 'text-center'
            }
        ]
    }

    // Evenements du tableau
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'edit':
                    this.$store.state.epreuveCorrectionResultat.error = null
                    this.epreuveCorrectionCorrecteurTemp = this.$store.getters['epreuveCorrectionResultat/getepreuveCorrectionCorrecteurById'](paParams[1].id)
                    this.ajustementValue = this.epreuveCorrectionCorrecteurTemp?.ajustements_params[this.$props.indexAjustement]?.params?.ecart_moyenne !== undefined
                        ? this.epreuveCorrectionCorrecteurTemp.ajustements_params[this.$props.indexAjustement].params.ecart_moyenne : 0
                    this.showEditEcartMoyenneCorrecteur = true
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
            }
        }
    }

    // Evenement de filtres
    filtreSortHandler (params: any)
    {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.$store.state.epreuveCorrectionResultat.epreuveCorrectionCorrecteurs = []
            this.setDataForGenericTab(this.$store.state.epreuveCorrectionResultat.epreuveCorrectionCorrecteurs)
            this.params = params
            this.tableLoading = true
            this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultats', {
                'filter-epreuve_correction_id': this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect'].epreuve_correction_id,
                scoped: 1,
                scope_type: ScopeAjustement.CORRECTOR_GROUP,
                ...this.params
            })
                .then((response) => {
                    this.$store.commit('epreuveCorrectionResultat/SET_EPREUVE_CORRECTION_CORRECTEURS', response.data.data)
                    this.setDataForGenericTab(this.$store.state.epreuveCorrectionResultat.epreuveCorrectionCorrecteurs)
                    this.tableLoading = false
                })
                /* .catch((error) => {
                    // console.log('ko:' + error)
                }) */
        }
    }

    // Initialisation
    initComposant () {
        this.filtres = []
        const epcr = this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect
        const phaseLibelle = epcr.epreuve_correction && epcr.epreuve_correction.phase && epcr.epreuve_correction.phase.name ? epcr.epreuve_correction.phase.name : '_'
        const filiere = epcr?.epreuve_correction?.concours
            ?.map((concour: any) => concour.name)
            ?.join(', ') || ''
        this.lib_epreuve = phaseLibelle + ' - ' + filiere + ' - ' + (epcr.epreuve_correction && epcr.epreuve_correction.name ? epcr.epreuve_correction.name : '')
    }

    mounted() {
        // Charge l'indexAjustement / libelle
        this.libelle_ajustement = this.$store.getters['epreuveCorrectionResultat/getAjustement'](this.$props.indexAjustement).name
        this.index_actual_ajustement = this.$store.getters['epreuveCorrectionResultat/tableAjustementParams'].findIndex((a: any) => a.ajustement === this.$props.indexAjustement)
        this.index_prec_ajustement = this.$store.getters['epreuveCorrectionResultat/getIndexAjustementPrec'](this.$props.indexAjustement)

        if (JSON.stringify(this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect']) === '{}') {
            this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultat', { epreuveCorrectionResultat_id: this.epreuvecorrectionId }).then(() => {
                this.initComposant()
            })
        } else {
            this.initComposant()
        }
    }
}
