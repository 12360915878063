export interface StateDefinitionBarresListesNotesAdmission {
    errorExport: any | null,
    tableauListeNotes: any[],
    meta: any | null,
    links: any | null,
    loading: boolean | false,
    loadingGenerate: GenerateFlux | false,
    error: any | null,
    totalRows: number | null,
    currentPage: number | null,
    lastPage: number | null,
    totalPage: number | null,
    selectedConcour: any[],
    params_definition_barres: {} | null,
    historiqueConcourPhase: any[]
}

export enum GenerateFlux {
    SCEI = 'scei',
    CMT = 'cmt'
}

export enum candidatRecu {
    NON_TRAITE = 0,
    OUI = 1,
    NON = 2,
}
