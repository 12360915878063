

































































import { Prop, Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { getFileNameFromHeader } from '@/utils/helpers'


/**
 * Composant principal de la pop up d'édition de candidats
 */
@Component({
    computed: {
        ...mapGetters('definitionBarresListeNotes', ['errorExport']),
        isCNG() {
            return this.$store.getters['auth/findParameter']('codeClient')?.value === 'CNG'
        }
    },
    components: {
        ErrorDisplay
    }
})

export default class PopupExportationListeDesNotes extends Vue {
    @Prop() showModal?: boolean
    @Prop() concours?: any

    exportingIsWorking = false
    returnParams: any = {}
    // valeurs affichées dans le menu déroulant filtre
    choixFiltres = {}

    // Sauvegarde le choix utilisateur des options du filtre
    filtreOptionSelected = ''
    filtreAbsenceSelect = '*'
    // recup de la valeur max à afficher dans le menu déroulant
    filtreNbMaxAbsences = 0

    choixColumnExport = [
        { id: 'infos_candidat', checked: false, title: 'Informations des candidats' },
        { id: 'epreuves', checked: true, title: 'Notes aux épreuves' },
        { id: 'resultats', checked: true, title: 'Resultats aux Concours' }
    ]

    /**
     * @description Transforme les choix utilisateur des colonnes, push les infos dans l'objet returnParams
     * @returns {void}
     */
    choixExportationColumns(): void {
        this.choixColumnExport.forEach((each) => {
            if (each.checked) {
                this.returnParams[each.id] = each.checked ? 1 : 0
            }
        })
    }

    /**
     * @description Exporte la liste des notes avec des paramètres spécifiques
     * @param {string} type - Type d'exportation
     * @returns {void}
     */
    exporterListeNotes(type = ''): void {
        this.$store.commit('definitionBarresListeNotes/SET_ERROR_EXPORT', null)
        this.choixExportationColumns()
        if (this.filtreOptionSelected && this.filtreOptionSelected !== '') {
            this.returnParams[this.filtreOptionSelected] = 1
        }
        // recupe de la valeur nb absence si l'utilisateur
        if (this.filtreAbsenceSelect !== null && this.filtreAbsenceSelect !== undefined && this.filtreAbsenceSelect !== '*') {
            this.returnParams['filter-nbAbsences'] = this.filtreAbsenceSelect
        }

        // Surcharge du header pour eviter les xls corrompues
        const payload = {
            headers:{ 'Content-Disposition': 'attachment; filename=template.xlsx', 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
            responseType: 'arraybuffer',
            concour_id : this.concours.concour_id,
            phase_id: this.concours.phase_id,
            params: this.returnParams
        }
        this.exportingIsWorking = true


        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        this.$store.dispatch(`definitionBarresListeNotes/exportListeNotes${type === 'jo' ? 'JO' : ''}`, payload)
            .then((response) => {
                // Logique de téléchargement
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || `exportAdmissibilite${type === 'jo' ? 'JO' : ''}.xlsx`)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                this.closeModal()
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
                this.returnParams = {}
            })
    }

    /**
     * @description Ferme la modale et réinitialise les choix utilisateurs
     * @returns {void}
     */
    closeModal(): void {
        this.filtreOptionSelected = ''
        this.filtreAbsenceSelect = '*'
        this.$emit('reinitShow', null)
    }

    /**
     * @description Montage du composant
     * @returns {void}
     */
    mounted(): void {
        // recup des épreuves et comptage afin de l'utiliser dans le nombre de jours d'absence
        const payload = { concour_id: this.$props.concours.concour_id, phase_id: this.$props.concours.phase_id }
        this.$store.dispatch('concour/getConcour', payload.concour_id).then((response) => {
            const delib = response.data.data.epreuves_deliberation
            this.filtreNbMaxAbsences = delib.filter((each: { phase_id: any }) => each.phase_id === payload.phase_id).length

            const  elimination: Array<any> = []
            for (let i = 0; i < this.concours.deliberation.barres.length; i ++ ) {
                elimination.push({
                    id: 'filter-barre'+( i + 1),
                    title: this.concours && this.concours.deliberation && this.concours.deliberation.barres[i] ? 'Rejet ' + this.concours.deliberation.barres[i].name : 'Barre ' + (i + 1),
                    optgroup: 'Élimination par'
                })
            }
            elimination.push({
                id: 'filter-toutesBarresEliminatoires',
                title: 'Rejet des deux barres'
            })
            elimination.push({
                id: 'filter-noteEliminatoire',
                title: 'Note éliminatoire',
                optgroup: 'Élimination par'
            })
            elimination.push({
                id: 'filter-absenceEliminatoire',
                title: 'Absence',
                optgroup: 'Élimination par'
            })
            this.choixFiltres = {elimination: elimination}
        })
    }
}
