




































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { BarTypePass } from '@/types/Barre'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('definitionBarresAnalysesHypotheses', ['tableauHypotheseTrie', 'loading', 'getError', 'hypothesesEpinglees']),
        ...mapGetters('definitionDesBarres', ['selectedTab'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class AnalysesHypotheses extends Vue {
    @Prop() concour: any

    Ability = Ability
    dataForTab: Array<any> = []
    showModalEditionCandidat = false
    filtres = []
    hypotheseEpinglees = []
    hypotheses_params = this.$props.concour?.hypotheses_params || []
    firstSearch = true
    exportingIsWorking = false
    nb_candidats_divise_par_deux = Math.ceil(this.$props.concour?.candidats_count / 2) || 0
    bar_rules: any = []
    genericfields: any = []
    first_loading = true
    definitionStep = [1, 0.5, 0.25, 0.1]

    /* Les options prennent comme valeur la précédente recherche si elle a eu lieux sinon le nb_candidat divisé / 2, maxBarre */
    options: any = {
        precision: 1 / this.hypotheses_params?.precision || 1,
        objectif: this.hypotheses_params?.objectif || this.nb_candidats_divise_par_deux,
        marge: this.hypotheses_params?.marge || 0,
        valMinBarre: [],
        valMaxBarre: [],
        maxRejetBarre: [],
        maxRejet: this.hypotheses_params?.maxRejet || this.nb_candidats_divise_par_deux
    }

    /**
     * @description Met à jour les valeurs des barres en fonction de la précision
     * @returns {void}
     */
    @Watch('options.precision')
    onPrecisionChange(): void {
        const updatePrecision = (value: number | string): number => {
            if (Number.isInteger(this.options.precision)) {
                return parseInt(value.toString())
            } else {
                let x = +value + (this.options.precision / 2)
                x = x - (x % this.options.precision)
                return parseFloat(x.toFixed(this.options.precision.toString().split('.')[1].length))
            }
        }

        this.options.objectif = updatePrecision(this.options.objectif)
        this.options.marge = updatePrecision(this.options.marge)
        this.options.maxRejet = updatePrecision(this.options.maxRejet)
        this.options.valMinBarre.forEach((value: number, index: number) => this.options.valMinBarre[index] = updatePrecision(value))
        this.options.valMaxBarre.forEach((value: number, index: number) => this.options.valMaxBarre[index] = updatePrecision(value))
        this.options.maxRejetBarre.forEach((value: number, index: number) => this.options.maxRejetBarre[index] = updatePrecision(value))
    }

    /**
     * watchTableau
     * @description MAJ du tableau lors du trie
     * @return {void}
     */
    @Watch('tableauHypotheseTrie')
    watchTableau(): void {
        this.setDataForGenericTab(this.$store.getters['definitionBarresAnalysesHypotheses/tableauHypotheseTrie'])
    }

    /**
     * watchEpingle
     * @description MAJ du tableau lors de l'épinglage
     * @return {void}
     */
    @Watch('hypothesesEpinglees')
    watchEpingle(): void {
        if (this.$store.getters['definitionDesBarres/selectedTab'] === 'analysesHypotheses') {
            this.hypotheseEpinglees = this.$store.getters['definitionBarresAnalysesHypotheses/hypothesesEpinglees']
            this.setDataForGenericTab(this.$store.getters['definitionBarresAnalysesHypotheses/tableauHypotheseTrie'])
        }
    }

    /**
     * watchSelectedTab
     * @description MAJ du tableau lors du changement de tab
     * @return {Promise<void>}
     */
    @Watch('selectedTab', { immediate: true })
    async watchSelectedTab(): Promise<void> {
        if (this.$store.getters['definitionDesBarres/selectedTab'] === 'analysesHypotheses') {
            const params = { concour_phase: this.$props.concour.id }
            await this.$store.dispatch('definitionBarresAnalysesHypotheses/getHypothesesEpinglees', params)
            this.setDataForGenericTab(this.$store.getters['definitionBarresAnalysesHypotheses/tableauHypotheseTrie'])
        }
    }

    /**
     * loadBarres
     * @description Charge les barres
     * @return {void}
     */
    @Watch('concour', { deep: true, immediate: true })
    loadBarres(): void {
        if (!this.$props.concour) {
            return
        }

        const barres: any = []
        this.$props.concour.deliberation.barres
            .filter((barre: any) => barre.type === BarTypePass.BAR_TYPE_PASS)
            .forEach((barre: any) => {
                barre.thresholds.forEach((threshold: any) => {
                    barres.push(this.$props.concour.deliberation.bar_rules
                        .find((bar_rule: any) => bar_rule.id === threshold.bar_rule_id).id)
                })
            })

        this.bar_rules = this.$props.concour.deliberation.bar_rules
            .filter((bar_rule: any) => barres.includes(bar_rule.id))
            .map((bar_rule: any) => {
                let value: any
                this.concour.deliberation.barres.some((barre: any) => {
                    return barre.thresholds.some((threshold: any) => {
                        if (threshold.bar_rule_id === bar_rule.id) {
                            value = threshold.value
                            return true
                        }
                    })
                })

                return {
                    name: bar_rule.name.charAt(0).toUpperCase() + bar_rule.name.slice(1),
                    id: bar_rule.id,
                    type: bar_rule.type,
                    value: value || null
                }
            })

        this.updateOptions()
        this.buildGenericFields()

        if (this.first_loading) {
            this.lancerRecherche(this.first_loading)
            this.first_loading = false
        }
    }

    /**
     * buildGenericFields
     * @description Construit les champs génériques
     * @return {void}
     */
    buildGenericFields(): void {
        const fields: any = []
        fields.push({ key: 'h', label: 'Hypothèse', sortable: false, class: 'header_width text-center', type: 'text' })
        fields.push({ key: 'barre1', label: this.bar_rules[0].name, sortable: true, class: 'text-center', type: 'text' })
        fields.push({ key: 'barre2', label: this.bar_rules[1].name, sortable: true, class: 'text-center', type: 'text' })
        fields.push({ key: 'recu', label: 'Nombre d\'admissibles', sortable: true, class: 'text-center', type: 'text' })
        fields.push({ key: 'rejet_barre1', label: `Rejets uniquement ${this.bar_rules[0].name.toLowerCase()}`, sortable: true, class: 'text-center', type: 'text' })
        fields.push({ key: 'rejet_barre2', label: `Rejets uniquement ${this.bar_rules[1].name.toLowerCase()}`, sortable: true, class: 'text-center', type: 'text' })
        fields.push({ key: 'rejet_global', label: 'Rejets uniquement une barre', sortable: true, class: 'text-center', type: 'text' })
        fields.push({ key: 'pinned', label: '', sortable: false, typeAction: 'edit', class: '', icon:'pen', disabled: false })
        this.genericfields = fields
    }

    /**
     * updateOptions
     * @description Met à jour les options
     * @return {void}
     */
    updateOptions(): void {
        this.options.valMinBarre = []
        this.options.valMaxBarre = []
        this.options.maxRejetBarre = []

        for (let i = 0; i < this.bar_rules.length; i++) {
            this.options.valMinBarre.push(this.hypotheses_params[`valMinBarre${i + 1}`] || 0)
            this.options.valMaxBarre.push(this.hypotheses_params[`valMaxBarre${i + 1}`] || Math.ceil(this.bar_rules[i].value) || this.hypotheses_params[`maxRejetBarre${i + 1}`] || this.nb_candidats_divise_par_deux)
            this.options.maxRejetBarre.push(this.hypotheses_params[`maxRejetBarre${i + 1}`] || this.nb_candidats_divise_par_deux)
        }
    }

    /**
     * setDataForGenericTab
     * @description Formatage des datas pour l'affichage dans le tableau générique
     * @param {any} poData - Donnéees à afficher
     * @param {boolean} isLoadMore - Ajout de données
     * @return {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (this.hypotheseEpinglees.length) {
            poData = this.hypotheseEpinglees.concat(poData)
        }
        if (poData) {
            let counter = 1

            for (const result of poData) {
                const line: any = []
                line.push({ label: 'Hypothèse', item: result.id ? counter : '', type: 'text', typeAction: null, class: result.id ? 'pinned_color text-center' : 'text-center' })
                line.push({ label: this.bar_rules[0].name, item: result.datas.barre1 || '-', type: 'text', typeAction: null, class: result.id ? 'pinned_color text-center' : 'text-center' })
                line.push({ label: this.bar_rules[1].name, item: result.datas.barre2 || '-', type: 'text', typeAction: null, class: result.id ? 'pinned_color text-center' : 'text-center' })
                line.push({ label: 'Recu', item: result.datas.recu || '-', type: 'text', typeAction: null, class: result.id ? 'pinned_color text-center' : 'text-center' })
                line.push({ label: '', item: result.datas.rejet_barre1 || '-', type: 'text', typeAction: null, class: result.id ? 'pinned_color text-center' : 'text-center' })
                line.push({ label: '', item: result.datas.rejet_barre2 || '-', type: 'text', typeAction: null, class: result.id ? 'pinned_color text-center' : 'text-center' })
                line.push({ label: '', item: result.datas.rejet_global || '-', type: 'text', typeAction: null, class: result.id ? 'pinned_color text-center' : 'text-center' })

                /* Logique afin de supprimer l icone de pin si deja pinned, les resultats n'ont pas d id */
                let pinned = {}
                if (this.hypotheseEpinglees.findIndex((pinned: any) => pinned.datas.barre1 === result.datas.barre1 && pinned.datas.barre2 === result.datas.barre2) === -1 || result.id) {
                    pinned = { label: 'pinned', item: result, type: 'action', typeAction: 'pinned', class: result.id ? 'commons_comment_button pinned_color' : 'commons_comment_button not_pinned_color', icon:'thumbtack', disabled: false }
                } else {
                    pinned = { label: 'pinned', item: '', type: 'text',  typeAction: null, class: result.id ? 'commons_comment_button pinned_color' : 'commons_comment_button not_pinned_color', icon:'thumbtack', disabled: false }
                }
                line.push(pinned)

                this.dataForTab.push(line)
                counter += 1
            }
        }
    }

    /**
     * handleTableEvent
     * @description Récupération des events du tableau
     * @param {any} paParams - Évènement du tableau (params[0] => l'action, params[1] => l'id de l'item)
     * @return {void}
     */
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'pinned':
                    this.pinned(paParams[1])
                    break
            }
        }
    }

    /**
     * pinned
     * @description Ajoute ou surpprime une épingle : si pas d'id, la valeur n'est pas pinned
     * @param {any} params - Paramètres de l'action
     * @return {void}
     */
    pinned(params: any): void {
        this.$store.dispatch(`definitionBarresAnalysesHypotheses/${params.id ? 'deleteHypothesesEpinglees' : 'postHypothesesEpinglees'}`,
            {
                concours_phase : this.$props.concour.id,
                params: params
            })
    }

    /**
     * filtreSortHandler
     * @description Filtre fait par le front, recup de la direction et compare via un sort.
     * @param {any} params - Paramètres de l'action
     * @return {void}
     */
    filtreSortHandler(params: any): void {
        const tableauATrier = this.$store.getters['definitionBarresAnalysesHypotheses/tableauHypotheseTrie']
            .sort((a: any, b: any) => {
                if (a.datas[params.sort] < b.datas[params.sort]) {
                    return params.direction === 'asc' ? -1 : 1
                }
                if (a.datas[params.sort] > b.datas[params.sort]) {
                    return params.direction === 'asc' ? 1 : -1
                }
                return 0
            })
        this.setDataForGenericTab(tableauATrier)
    }

    /**
     * lancerRecherche
     * @description Lance la recherche des hypothèses
     * @return {Promise<void>}
     */
    async lancerRecherche(load_data = false): Promise<void> {
        const options: any = {
            precision: Number(1 / this.options.precision),
            objectif: Number(this.options.objectif),
            marge: Number(this.options.marge),
            maxRejet:  Number(this.options.maxRejet)
        }

        for (let i = 0; i < this.bar_rules.length; i++) {
            options[`valMinBarre${i + 1}`] = Number(this.options.valMinBarre[i])
            options[`valMaxBarre${i + 1}`] = Number(this.options.valMaxBarre[i])
            options[`maxRejetBarre${i + 1}`] = Number(this.options.maxRejetBarre[i])
        }

        const response = await this.$store.dispatch('definitionBarresAnalysesHypotheses/getTableauHypothese',
            {
                options: options,
                concours_phase : this.$props.concour.id
            })
        if (load_data) {
            this.setDataForGenericTab(response.data.data)
        } else {
            this.firstSearch = false
        }
    }

    /**
     * exporterAnalysesHypotheses
     * @description Exporte le tableau des hypothèses
     * @return {void}
     */
    exporterAnalysesHypotheses(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)
        this.exportingIsWorking = true

        const payload = { concourPhase : this.concour.id }
        const date: Date = new Date()
        const fileName = `export_analyses_hypotheses_${date.getFullYear()}${(date.getMonth() + 1) < 10 ? '0' : ''}${date.getMonth() + 1}${date.getDate()}.xlsx`

        this.$store.dispatch('definitionBarresAnalysesHypotheses/exportTableauHypothese', payload)
            .then((response) => {
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * verifDesChamps
     * @description Vérifie les champs et contrôle qu'ils soient cohérents. min < max, borne maximum <= note_max
     * @return {any} - Retourne les informations des champs
     */
    verifDesChamps(): any {
        const infoChamps = { disabled: false, error: '' }
        const userInput = this.options

        if (userInput && this.bar_rules.length) {
            infoChamps.disabled = false
        }

        for (let i = 0; i < this.bar_rules.length; i++) {
            // controle valeur min
            if (userInput.valMinBarre[i] >= userInput.valMaxBarre[i]) {
                infoChamps.disabled = true
                infoChamps.error = `La valeur de la borne minimum de la barre ${this.bar_rules[i].name} doit être inférieure à la borne maximum.`
            }
        }

        return infoChamps
    }
}
