





















































import { Vue, Component, Watch }   from 'vue-property-decorator'
import { FontAwesomeIcon }  from '@fortawesome/vue-fontawesome'
import ListeNotes from '@/views/Deliberation/ListeNotes.vue'
import Statistiques from '@/views/Deliberation/Statistiques.vue'
import EcartMoyenneCorrecteur from '@/views/Deliberation/EcartMoyenneCorrecteur.vue'
import Normalisation from '@/views/Deliberation/Normalisation.vue'
import Harmonisation from '@/views/Deliberation/Harmonisation.vue'
import Arrondi from '@/views/Deliberation/Arrondi.vue'
import Back from '@/components/Tools/Back.vue'
import { mapGetters, mapState } from 'vuex'
import GradeThresholdCorrecteurLot from './GradeThresholdCorrecteurLot.vue'
import GradeThresholdEpreuve from './GradeThresholdEpreuve.vue'
import { getTypePassationSpec, TypePassation } from '@/types/Epreuve'
import { TargetAjustement } from '@/types/EpreuveCorrectionResultat'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapGetters('epreuveCorrectionResultat', ['epreuveCorrectionResultatSelect', 'tableAjustementParams', 'getAjustement']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ListeNotes,
        Statistiques,
        EcartMoyenneCorrecteur,
        Normalisation,
        Harmonisation,
        Arrondi,
        Back
    }
})
export default class EpreuveCorrectionCandidats extends Vue
{
    epreuvecorrectionId: any = '0'
    tabSelected = 'listenote'
    chargement = false
    libelleBack = ''

    @Watch('user_session_id')
    refreshInterface () {
        this.$router.push({ path: '/liste_epreuve_correction_resultats' })
    }

    // Retourne le nom du composant d'ajustement
    getDynamicTabLabel (ajustement: string) {
        const ajustement_select = this.$store.getters['epreuveCorrectionResultat/getAjustement'](ajustement)
        return ajustement_select.name
    }

    // Retourne le composant d'ajustement
    getDynamicComponent(ajustement: any) {
        const ajustement_select = this.$store.getters['epreuveCorrectionResultat/getAjustement'](ajustement)
        switch (ajustement_select.adjuster) {
            case 'ecart_moyenne':
                return EcartMoyenneCorrecteur
            case 'grade-threshold-adjuster':
                if (ajustement_select.target && ajustement_select.target === TargetAjustement.TARGET_GLOBAL) {
                    return GradeThresholdEpreuve
                } else if (ajustement_select.target && ajustement_select.target === TargetAjustement.TARGET_BATCH) {
                    return GradeThresholdCorrecteurLot
                } else {
                    return null
                }
            case 'normalisation':
                return  Normalisation
            case 'harmonisation':
                return Harmonisation
            case 'arrondi':
                return Arrondi
            default:
                return EcartMoyenneCorrecteur
        }
    }

    mounted () {
        this.chargement = true
        this.epreuvecorrectionId = this.$route.params.epreuve_correction_id ? this.$route.params.epreuve_correction_id : 0
        this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect = null
        this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultat', { epreuveCorrectionResultat_id: this.epreuvecorrectionId }).then(() => {
            const epcr = this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect
            const phaseLibelle = epcr.epreuve_correction && epcr.epreuve_correction.phase && epcr.epreuve_correction.phase.name ? epcr.epreuve_correction.phase.name : '-'
            const filiere = epcr?.epreuve_correction?.concours
                ?.map((concour: any) => concour.name)
                ?.join(', ') || ''

            this.libelleBack = phaseLibelle + ' - ' + filiere + ' - ' + (epcr.epreuve_correction && epcr.epreuve_correction.name ? epcr.epreuve_correction.name : '')
            if (epcr.epreuve_correction.type_passation === TypePassation.TYPE_PASSATION_EXTERNE) {
                this.libelleBack += ' ('+ getTypePassationSpec(epcr.epreuve_correction.type_passation).libelle +')'
            }
            this.chargement = false
        })
    }
}
