import { Publishable } from '@/types/Publishable'

export enum etat_validated_datas {
    DATAS_VALIDATED_NON = 0,
    DATAS_VALIDATED_OUI = 1,
}

export enum etat_transfered_datas {
    DATAS_TRANSFERED_NON = 0,
    DATAS_TRANSFERED_EN_COURS = 1,
    DATAS_TRANSEFERED_OUI = 2,
    DATAS_TRANSEFERED_ERREUR = 3,
}

export enum opened_datas {
    OPENED_NON = 0,
    OPENED_OUI = 1,
}

export interface PublicationInterface {
    id: number | 0,
    session_id: number | 0,
    publishable_id: number | 0,
    publishable: Publishable,
    entity_id: number | 0,
    entity?: any,
    entity_type: string | '',
    name: string | '',
    start_at: Date | number | string,
    end_at: Date | number | string,
    visibility_start_at: Date | number | string,
    visibility_end_at: Date | number | string,
    datas_validated: etat_validated_datas,
    datas_transfered: etat_transfered_datas,
    dossier_inscriptions_count: number | 0,
    opened: opened_datas,
    message?: PublicationMessageInterface
}

interface PublicationMessageInterface {
    code: string | '',
    content: string | '',
    id: number | 0,
    name: string | '',
    publishable: Publishable,
    publishable_id: number | 0,
    title: string | '',
    type: number | 0
}

export interface ParamsTrie {
    direction: string | '',
    sort: string | '',
    page: number | 0
}
