























































import { Vue, Component } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapGetters, mapState } from 'vuex'
import Publication from '@/views/GestionPortailCandidat/Onglets/Publication.vue'
import Parametrage from '@/views/GestionPortailCandidat/Onglets/Parametrage.vue'
import Candidats from '@/views/GestionPortailCandidat/Onglets/Candidats.vue'
import NotificationPortail from '@/views/GestionPortailCandidat/Onglets/NotificationPortail.vue'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        Publication,
        Parametrage,
        NotificationPortail,
        Candidats
    }
})

export default class GestionPortailCandidats extends Vue {
    ongletSelect = 'publications'
    typePublications = 'resultats'
}
