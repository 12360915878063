
















































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import {
    formatNumber,
    formatDate,
    formatStringDate,
    getFileNameFromHeader,
    base64ToArrayBuffer,
    isObject
} from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { EtatRemonteNote, getEtatRemonteNoteLibelle } from '@/types/ConcourPhase'
import _ from 'lodash'
import { getTypePassationSpec, TypePassation } from '@/types/Epreuve'
import { EtatTraitementDossier, getEtatTraitementDossier, TypeDossier } from '@/types/Candidat'
import { getTypeMethodArrondi, getTypeArrondi, TypeArrondi } from '@/types/EpreuveCorrectionResultat'

@Component({
    methods: { isObject, getEtatTraitementDossier },
    computed: {
        EtatTraitementDossier() {
            return EtatTraitementDossier
        },
        ...mapGetters('epreuveCorrectionResultat', ['epreuveCorrectionResultats', 'loading', 'totalRows', 'lastPage', 'totalPage', 'error', 'meta']),
        ...mapGetters('concourPhase', ['phaseAjustementCompteurs']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('candidat', ['loading_import'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class EpreuveCorrectionResultats extends Vue {
    getTypeArrondi = getTypeArrondi
    getTypeMethodArrondi = getTypeMethodArrondi
    formatDate = formatDate
    EtatRemonteNote = EtatRemonteNote
    getEtatRemonteNoteLibelle = getEtatRemonteNoteLibelle
    getTypePassationSpec = getTypePassationSpec
    TypePassation = TypePassation
    Ability = Ability
    sessionSelect: any = null
    tableLoading = false
    showValideAjustements = false
    firstLoading = true
    showExportStats = false
    showExportNotesEpreuvesExternes = false
    showImportNotes = false
    export_filiere_select = null
    export_phase_select = null
    export_in_progress = false
    perPage = -1
    filtres: any = []
    dataForTab: Array<any>  = []
    filieres: Array<any> = []
    phases: Array<any> = []
    dicoPhaseFilieres: { [indice: string]: { phase: any; concours: Array<any>; libelle: string; filiere: string; tableAjustements: Array<string>; nbEpreuves: number; nbEpreuvesAjustementValide: number; liste_etapes_ajustement: {[indice: string]: {libelle: string; epreuves: Array<any>} }}} = {}
    dicoPhaseFilieresValidation: { [indice: string]: { phase: any; concours: Array<any>; libelle: string; filiere: string; tableAjustements: Array<string>; nbEpreuves: number; nbEpreuvesAjustementValide: number; liste_etapes_ajustement: {[indice: string]: {libelle: string; epreuves: Array<any>} }}} = {}
    nb_filieres_valide = 0
    nb_filieres = 0
    params: any = {}
    phase_concours_selected: { phase: any; concours: Array<any>; libelle: string; filiere: string;tableAjustements: Array<string>; nbEpreuves: number; nbEpreuvesAjustementValide: number; liste_etapes_ajustement: {[indice: string]: {libelle: string; epreuves: Array<any>} }} | null = null
    etape_ajustement_selected = ''
    validationEnCours = false
    showRemonteNotes = false
    dataRemonteNote: any = {}
    loadEtatRemonteEnCours = false
    preventRemontee = false
    intervalRemonteeNote: any = null
    intervalImportNotesExternes: any = null
    intervalImportNotesTIPE: any = null
    tabSelected = 'externe'
    notesPrerequisAllOK = false
    notesPrerequisBarresAdmissibiliteValidees = false
    notesPrerequisAucuneBarresAdmissionValidee = true
    notesPrerequisHasDatesValidees = false
    notesPrerequisHasDossiersCandidats = false
    etatPrerequisRepartitionEquipes: any = {}
    liste_fichiers_notes: any = []
    loading_notes = false
    selectedFichier: any = null
    dossier_error: any = {}
    showErrorDossier = false
    statusImportNotes: any = null
    loadingImportNotes = false
    file: any = null
    includeExternes = false
    genericfields = [
        { key: 'epreuve_correction.phase.name', label: 'Phase', sortable: true, class: '', type: 'text' },
        { key: 'epreuve_correction.concours.name', label: 'Filière', sortable: true, class: '', type: 'text' },
        { key: 'epreuve_correction.name', label: 'Épreuve', sortable: true, class: '', type: 'text' },
        { key: 'correction_finished_at', label: 'Notation terminée', sortable: true, class: 'text-center', type: 'text' },
        { key: 'moyenne_notes_brutes', label: 'Moyenne notes brutes', sortable: true, class: 'text-center', type: 'text' },
        { key: 'ecart_type_notes_brutes', label: 'Écart type notes brutes', sortable: true, class: 'text-center', type: 'text' },
        { key: 'ajustement', label: 'Étape d\'ajustement', sortable: true, class: 'text-center', type: 'text' },
        { key: 'moyenne_notes_finales', label: 'Moyenne notes finales', sortable: true, class: 'text-center', type: 'text' },
        { key: 'ecart_type_notes_finales', label: 'Écart type notes finales', sortable: true, class: 'text-center', type: 'text' },
        { key: 'notes_finalisees_validated_at', label: 'Notes finalisées', sortable: true, class: 'text-center', type: 'text' },
        { key: 'enterEpreuve', label: '', sortable: false, class: '', type: 'action' }
    ]

    showModalMessageNotesFinales = false
    externUsers: Array<any> = []


    /**
     * @description Récupère les compteurs de validation des ajustements
     * @return {void}
     */
    @Watch('epreuveCorrectionResultats')
    getCompteurs(): void {
        this.$store.dispatch('concourPhase/getConcourPhaseAjustementCompteurs')
    }

    /**
     * @description Récupère l'arrondi
     * @param {any} data - Données
     * @return {string}
     */
    getArrondi(data: any): string {
        if (data.ajustements_params[this.etape_ajustement_selected].params && data.ajustements_params[this.etape_ajustement_selected].params.arrondi) {
            return this.getTypeArrondi(data.ajustements_params[this.etape_ajustement_selected].params.arrondi).val + ' ' + this.getTypeMethodArrondi( this.getTypeArrondi(data.ajustements_params[ this.etape_ajustement_selected].params.arrondi).method).libelle
        } else if (data.epreuve_correction && data.epreuve_correction.epreuves && data.epreuve_correction.epreuves.length > 0) {
            for (let i = 0; i < data.epreuve_correction.epreuves.length; i++ ) {
                if (data.epreuve_correction.epreuves[i].arrondi) {
                    return this.getTypeArrondi(data.epreuve_correction.epreuves[i].arrondi).val + ' ' + this.getTypeMethodArrondi(getTypeArrondi(data.epreuve_correction.epreuves[i].arrondi).method).libelle
                }
            }
        }
        return this.getTypeArrondi(TypeArrondi.ARRONDI_CENTIEMELEPLUSPROCHE).val + ' ' + this.getTypeMethodArrondi(getTypeArrondi(TypeArrondi.ARRONDI_CENTIEMELEPLUSPROCHE).method).libelle
    }

    /**
     * @description Rafraichit l'interface au changement de session
     * @return {Promise<void>}
     */
    @Watch('user_session_id')
    async refreshInterface(): Promise<void> {
        await this.load()
    }

    /**
     * @description Ouvre la popup de remontée des notes
     * @return {void}
     */
    openRemonteNotes(): void {
        this.showRemonteNotes = true
        if (this.intervalRemonteeNote) {
            clearInterval(this.intervalRemonteeNote)
        }
        this.getDataRemonteNotes()
    }

    /**
     * @description Charge les données de remontée des notes
     * @return {void}
     */
    getDataRemonteNotes(): void {
        if (_.isEmpty(this.dataRemonteNote)) {
            this.loadEtatRemonteEnCours = true
        }
        this.$store.dispatch('concourPhase/getViatiqueResultState', { session_id: this.sessionSelect.id })
            .then((response) => {
                if (!response.data.data.length) {
                    clearInterval(this.intervalRemonteeNote)
                    this.intervalRemonteeNote = null
                }

                this.dataRemonteNote = {}
                for (let i = 0; i < response.data.data.length; i++) {
                    if (!this.dataRemonteNote[response.data.data[i].entity_id]) {
                        this.dataRemonteNote[response.data.data[i].entity_id] = {}
                    }
                    this.dataRemonteNote[response.data.data[i].entity_id] = response.data.data[i]
                }
                this.preventRemontee = true
                for (const key in this.dataRemonteNote) {
                    if (this.dataRemonteNote[key].message) {
                        this.dataRemonteNote[key].message = JSON.parse(this.dataRemonteNote[key].message)
                    }
                }

                const dataRemonteNoteArray: any[] = Object.values(this.dataRemonteNote)
                const etatRemonteNoteTerminatedArray: any[] = dataRemonteNoteArray
                    .filter((data: any) => [EtatRemonteNote.ETAT_TRAITE, EtatRemonteNote.ETAT_ERREUR].includes(data.etat))
                if (dataRemonteNoteArray.length === etatRemonteNoteTerminatedArray.length) {
                    clearInterval(this.intervalRemonteeNote)
                    this.intervalRemonteeNote = null
                }
            })
            .finally(() => {
                this.preventRemontee = false
                this.loadEtatRemonteEnCours = false
            })
    }

    /**
     * @description Récupère les résultats depuis VIATIQUE
     * @return {void}
     */
    startViatiqueResult(): void {
        this.dataRemonteNote = {}
        this.preventRemontee = true

        this.$store.dispatch('concourPhase/startViatiqueResultState', { session_id: this.sessionSelect.id })
            .then(() => {
                if (this.intervalRemonteeNote) {
                    clearInterval(this.intervalRemonteeNote)
                }
                this.getDataRemonteNotes()
                this.intervalRemonteeNote = setInterval(() => {
                    this.getDataRemonteNotes()
                }, 3000)
            })
    }

    /**
     * @description Ferme la popup de remontée des notes
     * @return {void}
     */
    closeRemonteNotes(): void {
        this.showRemonteNotes = false
        if (this.intervalRemonteeNote) {
            clearInterval(this.intervalRemonteeNote)
            this.intervalRemonteeNote = null
        }
    }

    /**
     * @description Vérifie si le bouton de validation est désactivé
     * @return {boolean}
     */
    confirmValidIsDisabled(): boolean {
        if (this.validationEnCours || !this.phase_concours_selected || (this.phase_concours_selected && !this.phase_concours_selected.liste_etapes_ajustement)) {
            return true
        }

        return (this.phase_concours_selected && (!this.phase_concours_selected.liste_etapes_ajustement ||
            Object.keys(this.phase_concours_selected.liste_etapes_ajustement).length === 0 ||
            !this.phase_concours_selected.liste_etapes_ajustement[this.etape_ajustement_selected] ||
            !this.phase_concours_selected.liste_etapes_ajustement[this.etape_ajustement_selected].epreuves ||
            this.phase_concours_selected.liste_etapes_ajustement[this.etape_ajustement_selected].epreuves.length === 0));

    }

    /**
     * @description Ouvre la popup de validation des ajustements
     * @return {void}
     */
    openValideAjustements(): void {
        this.$store.commit('epreuveCorrectionResultat/SET_ERROR', null)
        this.etape_ajustement_selected = ''
        this.showValideAjustements = true
        this.phase_concours_selected = null
    }

    /**
     * @description Ferme la popup de validation des ajustements
     * @return {void}
     */
    cancelValideAjustements(): void {
        this.showValideAjustements = false
        this.phase_concours_selected = null
        this.etape_ajustement_selected = ''
    }

    /**
     * @description Vérifie le comportement de la validation partielle par épreuve
     * @return {void}
     */
    validAjustements(): void {
        this.externUsers = []
        if (this.etape_ajustement_selected === 'valide_notes_finales' &&  this.phase_concours_selected) {
            const params: any = {sort: 'name', direction: 'asc'}
            params['filter-concour_id'] = this.phase_concours_selected.concours[0].id
            params['filter-phase_id'] = this.phase_concours_selected.phase.id
            this.$store.dispatch('user/getExternUsers', params).then((response) => {
                if (response.data.data.length === 0) {
                    this.validAjustementsSuite()
                } else {
                    this.externUsers = response.data.data.sort((a: any, b: any) => {
                        if (a.name > b.name) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                    this.showModalMessageNotesFinales = true
                }
            })
        } else {
            this.validAjustementsSuite()
        }
    }

    /**
     * @description Ferme la popup de validation des notes finales
     * @return {void}
     */
    cancelNotesFinales(): void {
        this.showModalMessageNotesFinales = false
    }

    /**
     * @description Valide les ajustements
     * @return {void}
     */
    validAjustementsSuite(): void {
        this.showModalMessageNotesFinales = false
        if (this.phase_concours_selected) {
            this.validationEnCours = true

            const concourPhase = this.$store.getters['concourPhase/getConcourPhaseByConcourIdAndPhaseId'](this.phase_concours_selected.concours[0].id, this.phase_concours_selected.phase.id)
            if (concourPhase) {
                let payload: any

                if (this.etape_ajustement_selected === 'valide_notes_finales') {
                    payload = {
                        concourPhase_id: concourPhase.id,
                        validate: 1
                    }
                } else if (this.etape_ajustement_selected === 'invalide_notes_finales') {
                    payload = {
                        concourPhase_id: concourPhase.id,
                        validate: 0
                    }
                } else {
                    payload = {
                        concourPhase_id: concourPhase.id,
                        ajustement_name: this.etape_ajustement_selected,
                        validate: 1
                    }
                }

                const idInfo = 't_info_' + Math.random()
                const infosToaster = {
                    id: idInfo,
                    toaster: 'b-toaster-top-right',
                    variant: 'primary',
                    noCloseButton: true,
                    fade: true,
                    noAutoHide: true
                }
                this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

                this.$store.dispatch('concourPhase/updateConcourPhaseAjustement', payload)
                    .then(async () => {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé.', succesToaster)

                        this.showValideAjustements = false
                        this.tableLoading = true
                        this.params.perPage = this.perPage
                        this.params.scoped = 0
                        await this.$store.dispatch('concourPhase/getConcourPhases', { 'filter-ajustement': 1 })
                        await this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultats', this.params)
                        this.setDataForGenericTab(this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultats'])
                        this.tableLoading = false
                    })
                    .catch((error) => {
                        console.log('ko:' + error)
                    })
                    .finally(() => {
                        this.validationEnCours = false
                        this.$bvToast.hide(idInfo)
                    })
            }
        }
    }

    /**
     * @description Ouvre la popup d'export
     * @return {void}
     */
    openExport(): void {
        this.showExportStats = true
    }

    /**
     * @description Ferme la popup d'export
     * @return {void}
     */
    closeExport(): void {
        this.export_filiere_select = null
        this.showExportStats = false
    }

    /**
     * @description Exporte les statistiques des notes
     * @return {void}
     */
    exportNotes(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours ...', infosToaster)

        this.$store.dispatch('epreuveCorrectionResultat/exportStatistiquesAjustement', {
            concour_id: this.export_filiere_select,
            externes: this.includeExternes ? 1 : 0
        })
            .then((response) => {
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || 'export_statistiques_notes.pdf')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Export terminé.', succesToaster)
                this.closeExport()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Ouvre la popup d'export des notes des épreuves externes
     * @return {void}
     */
    openExportEpreuvesExternes(): void {
        this.showExportNotesEpreuvesExternes = true
    }

    /**
     * @description Ferme la popup d'export des notes des épreuves externes
     * @return {void}
     */
    closeExportEpreuvesExternes(): void {
        this.$store.commit('epreuveCorrectionResultat/SET_ERROR', null)
        this.export_filiere_select = null
        this.export_phase_select = null
        this.showExportNotesEpreuvesExternes = false
    }

    /**
     * @description Exporte les statistiques des notes des épreuves externes
     * @return {void}
     */
    exportNotesEpreuvesExternes(): void {
        if (!this.export_filiere_select || !this.export_phase_select || this.export_in_progress) {
            return
        }
        this.export_in_progress = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours ...', infosToaster)

        this.$store.dispatch('epreuveCorrectionResultat/exportNotesEpreuvesExternes', {
            concour_id: this.export_filiere_select,
            phase_id: this.export_phase_select
        })
            .then((response) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || 'export_notes_epreuves_externes.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Export terminé.', succesToaster)
                this.closeExport()
            })
            .finally(() => {
                this.export_in_progress = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Ouvre la popup d'import des notes
     * @return {Promise<void>}
     */
    async openImportNotes(): Promise<void> {
        this.tabSelected = 'externe'
        await this.loadTabData()
        this.showImportNotes = true
    }

    /**
     * @description Charge les données de l'onglet sélectionné
     * @param {boolean} refresh - Rafraichir les données affichées
     * @return {Promise<void>}
     */
    @Watch('tabSelected')
    async loadTabData(refresh = false): Promise<void> {
        this.$store.commit('epreuveCorrectionResultat/SET_ERROR', null)
        this.statusImportNotes = null
        this.file = null
        this.dossier_error = null
        this.showErrorDossier = false

        if (this.intervalImportNotesExternes) {
            clearInterval(this.intervalImportNotesExternes)
            this.intervalImportNotesExternes = null
        }

        if (this.intervalImportNotesTIPE) {
            clearInterval(this.intervalImportNotesTIPE)
            this.intervalImportNotesTIPE = null
        }

        switch (this.tabSelected) {
            case 'externe': {
                await this.getDataImportNotes(refresh)
                break
            }
            case 'tipe': {
                this.loading_notes = true
                await this.$store.dispatch('candidat/getEtatPrerequisRepartitionEquipes')
                this.etatPrerequisRepartitionEquipes = this.$store.getters['candidat/datasRepartitionEquipes'].prerequis
                this.checkNotesPrerequis()
                if (this.notesPrerequisAllOK) {
                    await this.loadListesFichiersNotes(refresh)
                } else {
                    this.loading_notes = false
                }
                break
            }
        }
    }

    /**
     * @description Charge les listes des dossiers pour les notes
     * @param {boolean} refresh - Rafraichir les données affichées
     * @return {Promise<void>}
     */
    async loadListesFichiersNotes(refresh = false): Promise<void> {
        await this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_NOTES_TIPE })
        this.liste_fichiers_notes = this.$store.state.candidat.liste_dossiers

        const boolean = this.liste_fichiers_notes
            .some((fichier: any) => fichier.etat === EtatTraitementDossier.ETAT_EN_COURS || fichier.etat === EtatTraitementDossier.ETAT_NON_LANCER)

        if (boolean) {
            if (!this.intervalImportNotesTIPE) {
                this.intervalImportNotesTIPE = setInterval(() => {
                    this.loadListesFichiersNotes(refresh)
                }, 3000)
            }
        } else {
            if (this.intervalImportNotesTIPE) {
                clearInterval(this.intervalImportNotesTIPE)
                this.intervalImportNotesTIPE = null

                if (refresh) {
                    this.load()
                }
            }
        }

        this.loading_notes = false
    }

    /**
     * @description Vérifie les prérequis pour l'import des notes
     * @return {void}
     */
    checkNotesPrerequis(): void {
        let checkPrerequis_ok = true
        this.notesPrerequisBarresAdmissibiliteValidees  = true // Les barres d'admissibilités sont validées
        this.notesPrerequisAucuneBarresAdmissionValidee = true // Aucune barre d'admission n'est validée
        this.notesPrerequisHasDatesValidees             = true // Les dates des séries sont validées
        this.notesPrerequisHasDossiersCandidats         = true // Il existe des dossiers candidats

        // Check si les barres d'admissibilité ne sont pas toutes validées
        if (this.etatPrerequisRepartitionEquipes.nb_candidats !== this.etatPrerequisRepartitionEquipes.nb_candidats_statut || this.etatPrerequisRepartitionEquipes.nb_barres !== this.etatPrerequisRepartitionEquipes.nb_barres_validees) {
            this.notesPrerequisBarresAdmissibiliteValidees = false
            checkPrerequis_ok = false
        }
        // Check si aucune barre d'admission n'est validée
        if (this.etatPrerequisRepartitionEquipes.nb_barres_validees_phase_en_cours > 0) {
            this.notesPrerequisAucuneBarresAdmissionValidee = false
            checkPrerequis_ok = false
        }
        // Check si les dates des séries sont validées
        if (!this.$store.state.session.sessionSelect.series_validated_at) {
            this.notesPrerequisHasDatesValidees = false
            checkPrerequis_ok = false
        }
        // Check s'il existe des dossiers candidats
        if (!this.$store.state.candidat.candidats.length) {
            this.$store.dispatch('candidat/getCandidats', { sort: 'name', direction: 'asc', phase: 'admission' })
                .then(() => {
                    this.notesPrerequisHasDossiersCandidats = !!this.$store.state.candidat.candidats.length
                    checkPrerequis_ok = this.notesPrerequisHasDossiersCandidats
                })
        } else {
            this.notesPrerequisHasDossiersCandidats = true
        }

        this.notesPrerequisAllOK = checkPrerequis_ok
    }

    /**
     * @description Importe les données
     * @return {void}
     */
    importDatas(): void {
        if (!this.selectedFichier) {
            return
        }

        this.$store.dispatch('candidat/updateDossier', { dossier_id: this.selectedFichier })
            .then(() => {
                this.selectedFichier = null
                this.loadTabData(true)
            })
    }

    /**
     * @description Affiche les erreurs d'un fichier
     * @param {any} dossier - Dossier
     * @return {void}
     */
    showErrorsFichiers(dossier: any): void {
        this.dossier_error = dossier
        this.showErrorDossier = true
    }

    /**
     * @description Ferme les erreurs d'un fichier
     * @return {void}
     */
    closeErrorFichiers(): void {
        this.dossier_error = null
        this.showErrorDossier = false
    }

    /**
     * @description Ferme la popup d'import des notes
     * @return {void}
     */
    closeImportNotes(): void {
        this.showImportNotes = false
        this.statusImportNotes = null
        this.file = null
        this.dossier_error = null
        this.showErrorDossier = false

        if (this.intervalImportNotesExternes) {
            clearInterval(this.intervalImportNotesExternes)
            this.intervalImportNotesExternes = null
        }

        if (this.intervalImportNotesTIPE) {
            clearInterval(this.intervalImportNotesTIPE)
            this.intervalImportNotesTIPE = null
        }
    }

    /**
     * @description Charge le statut de l'import des notes
     * @param {boolean} refresh - Rafraichir les données affichées
     * @return {Promise<void>}
     */
    async getDataImportNotes(refresh = false): Promise<void> {
        const response = await this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_IMPORT_NOTES_EXTERNE })
        if (response?.data?.data?.length) {
            this.statusImportNotes = _.orderBy(response.data.data, ['id'], ['desc'])[0]

            if (this.statusImportNotes?.erreur_message?.message?.endsWith('.')) {
                this.statusImportNotes.erreur_message.message = this.statusImportNotes.erreur_message.message.slice(0, -1)
            }

            if (typeof this.statusImportNotes?.erreur_message?.errors === 'object') {
                this.statusImportNotes.erreur_message.errors =
                    Object.entries(this.statusImportNotes.erreur_message.errors)
                        .map(([, value]: any) => Object.entries(value)
                            .map(([key2, value2]: any) => `${key2.charAt(0).toUpperCase() + key2.slice(1)} - ${value2}`))
                        .flat(Infinity)
            }

            switch (this.statusImportNotes.etat) {
                case EtatTraitementDossier.ETAT_NON_LANCER:
                case EtatTraitementDossier.ETAT_EN_COURS:
                    if (!this.intervalImportNotesExternes) {
                        this.intervalImportNotesExternes = setInterval(() => {
                            this.getDataImportNotes(refresh)
                        }, 3000)
                    }
                    break
                case EtatTraitementDossier.ETAT_ERREUR:
                case EtatTraitementDossier.ETAT_TRAITE:
                    if (this.intervalImportNotesExternes) {
                        clearInterval(this.intervalImportNotesExternes)
                        this.intervalImportNotesExternes = null
                    }
                    if (refresh && this.statusImportNotes.etat === EtatTraitementDossier.ETAT_TRAITE) {
                        this.load()
                    }
                    break
            }
        }
    }

    /**
     * @description Événement de sélection de fichier
     * @param {any} event - Événement
     * @return {void}
     */
    selectFile(event: any): void {
        this.file = event.target.files[0]
    }

    /**
     * @description Importe les notes
     * @return {void}
     */
    uploadNotes(): void {
        if (!this.file || this.loadingImportNotes) {
            return
        }
        this.loadingImportNotes = true

        this.$store.dispatch('epreuveCorrectionResultat/importNotes', this.file)
            .then(() => {
                // Reset input file
                const input: any = document.getElementById('input-file')
                if (input) {
                    input.value = null
                    input.type = 'text'
                    this.$nextTick(() => {
                        input.type = 'file'
                    })
                }
                this.file = null

                this.getDataImportNotes(true)
            })
            .catch((error) => {
                console.log('ko:' + error)
                const idError = 't_error_' + Math.random()
                const errorToaster = {
                    id: idError,
                    toaster: 'b-toaster-top-right',
                    variant: 'danger',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast("Une erreur s'est produite.", errorToaster)
            })
            .finally(() => {
                this.loadingImportNotes = false
            })
    }

    /**
     * @description Télécharge le modèle de fichier d'import des notes
     * @return {void}
     */
    downloadGabarit(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours ...', infosToaster)

        this.$store.dispatch('epreuveCorrectionResultat/exportModeleImportNotes')
            .then((response) => {
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || 'modele_import_notes.csv')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Création des lignes du tableau
     * @param {any} poData - Données
     * @param {boolean} isLoadMore - Ajout de données à la liste
     * @return {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            this.filieres = []
            const dicoFilieres: { [concour_id: string]: { total: number; valides: number} } = {}
            this.dicoPhaseFilieres = {}

            for (const result of poData) {
                let nameEpreuveCorrection = result.epreuve_correction.name
                if (result.epreuve_correction.type_passation === TypePassation.TYPE_PASSATION_EXTERNE) {
                    nameEpreuveCorrection += ` (${getTypePassationSpec(result.epreuve_correction.type_passation).libelle})`
                }

                const phaseName = result.epreuve_correction?.phase?.name || '-'
                const phase = result.epreuve_correction?.phase || '-'

                if (phase && phase !== '-') {
                    this.phases.push(phase)
                }

                let filieres: any = '-'
                const concours = result.epreuve_correction.concours
                    .filter((c: any) => c.session_id === this.$store.getters['auth/user_session_id'])
                if (result.epreuve_correction && concours) {
                    filieres = []
                    for (const concourkey in concours) {
                        const concourTemp = concours[concourkey]

                        if (concourTemp) {
                            if (!dicoFilieres[concourTemp.id]) {
                                this.filieres.push(concourTemp)
                                dicoFilieres[concourTemp.id] = {
                                    total: 0,
                                    valides: 0
                                }
                            }
                            dicoFilieres[concourTemp.id].total++
                            if (result.notes_finalisees_validated_at) {
                                dicoFilieres[concourTemp.id].valides++
                            }

                            if (concourTemp.name) {
                                filieres.push(concourTemp.name)
                            }
                        }
                    }
                    filieres = filieres.join(', ')
                }

                if (!this.dicoPhaseFilieres[`${phase.id}-${filieres}`]) {
                    this.dicoPhaseFilieres[`${phase.id}-${filieres}`] = {
                        phase: phase,
                        concours: result.epreuve_correction.concours,
                        libelle: `${phaseName} - ${filieres}`,
                        filiere: filieres,
                        liste_etapes_ajustement: {},
                        tableAjustements: [],
                        nbEpreuves: 0,
                        nbEpreuvesAjustementValide: 0
                    }
                }


                const puce_correction_fini = []
                let class_saisie_examinateur = 'text-tertiary'
                if (result.correction_finished_at) {
                    class_saisie_examinateur = 'text-success'
                }
                puce_correction_fini.push({ name:'circle', class: class_saisie_examinateur })
                const title_correction_fini = result.correction_finished_at ? formatStringDate(result.correction_finished_at) : ''

                const moyenne_notes_brutes = result.moyenne_notes_brutes ? formatNumber(result.moyenne_notes_brutes) : '-'
                const ecart_type_notes_brutes = result.ecart_type_notes_brutes ? formatNumber(result.ecart_type_notes_brutes) : '-' // au centieme
                const moyenne_notes_finales = result.moyenne_notes_finales ? formatNumber(result.moyenne_notes_finales) : '-'
                const ecart_type_notes_finales = result.ecart_type_notes_finales ? formatNumber(result.ecart_type_notes_finales) : '-'

                let currentAjustement = ''

                let etape_ajustement = this.getDynamicTabLabel(result.epreuve_correction, result.ajustement) // Init par défaut à "Pas d\'ajustement"
                let class_etape_ajustement = 'text-secondary text-center libelle_etape_ajustement'

                /** **************************************************************************************************************************
                 * DEBUT - Partie spécifique aux PhaseFilières comprenant des ajustements. Dans ce cas, on init tout ce qui concerne les ajustements
                 * Ces filières ne sont donc pas soumises non plus à la validation d'ajustement.
                 * ***************************************************************************************************************************/
                if (phase.ajustements) { //  && result.ajustements_params
                    this.dicoPhaseFilieres[`${phase.id}-${filieres}`].tableAjustements = result.ajustements_params ? Object.keys(result.ajustements_params) : []

                    if (result.ajustement) {
                        currentAjustement = result.ajustement
                    } else if (result.correction_finished_at) {
                        currentAjustement =  this.dicoPhaseFilieres[`${phase.id}-${filieres}`].tableAjustements[0]
                    }

                    let index_ajustement_en_cours = 0
                    if (result.ajustements_params && result.ajustements_params[currentAjustement]?.validated_at) {
                        // On regarde si on a un ajustement suivant
                        const index_suivant = this.dicoPhaseFilieres[`${phase.id}-${filieres}`].tableAjustements.indexOf(result.ajustement) + 1
                        if (this.dicoPhaseFilieres[`${phase.id}-${filieres}`].tableAjustements[index_suivant]) {
                            index_ajustement_en_cours = (this.dicoPhaseFilieres[`${phase.id}-${filieres}`].tableAjustements.indexOf(result.ajustement) + 1)
                        } else {
                            index_ajustement_en_cours = this.dicoPhaseFilieres[`${phase.id}-${filieres}`].tableAjustements.indexOf(result.ajustement)
                        }
                    }

                    const ajustement_en_cours = this.dicoPhaseFilieres[`${phase.id}-${filieres}`].tableAjustements[index_ajustement_en_cours]
                    const etape_en_cours = this.dicoPhaseFilieres[`${phase.id}-${filieres}`].tableAjustements[index_ajustement_en_cours]
                    etape_ajustement = (etape_en_cours ? (index_ajustement_en_cours + 1) + '. ' : '')  +  this.getDynamicTabLabel(result.epreuve_correction, etape_en_cours) // 'Pas d\'ajustement'

                    if (ajustement_en_cours && !this.dicoPhaseFilieres[`${phase.id}-${filieres}`].liste_etapes_ajustement[ajustement_en_cours]) {
                        this.dicoPhaseFilieres[`${phase.id}-${filieres}`].liste_etapes_ajustement[ajustement_en_cours] = {
                            libelle: this.getDynamicTabLabel(result.epreuve_correction, ajustement_en_cours),
                            epreuves: []
                        }
                    }

                    if (ajustement_en_cours && result.ajustements_params[ajustement_en_cours]?.validated_at) {
                        class_etape_ajustement = 'text-success text-center libelle_etape_ajustement'
                    } else if (result.correction_finished_at) {
                        class_etape_ajustement = 'text-primary text-center libelle_etape_ajustement'
                    }
                    this.dicoPhaseFilieres[`${phase.id}-${filieres}`].nbEpreuves++

                    if (ajustement_en_cours && result.correction_finished_at){ //  && result.epreuve_correction.type_passation !== TypePassation.TYPE_PASSATION_EXTERNE) {
                        if (!result.ajustements_params[ajustement_en_cours]?.validated_at) {
                            this.dicoPhaseFilieres[`${phase.id}-${filieres}`].liste_etapes_ajustement[ajustement_en_cours].epreuves.push(result)
                        } else if (ajustement_en_cours === this.dicoPhaseFilieres[`${phase.id}-${filieres}`].tableAjustements[this.dicoPhaseFilieres[`${phase.id}-${filieres}`].tableAjustements.length - 1]) {
                            // Charge les épreuves éligibles à la validation
                            if (!this.dicoPhaseFilieres[`${phase.id}-${filieres}`].liste_etapes_ajustement['valide_notes_finales']) {
                                this.dicoPhaseFilieres[`${phase.id}-${filieres}`].liste_etapes_ajustement['valide_notes_finales'] = {
                                    libelle: 'valide_notes_finales',
                                    epreuves: []
                                }
                            }

                            if (!result.notes_finalisees_validated_at) {
                                this.dicoPhaseFilieres[`${phase.id}-${filieres}`].liste_etapes_ajustement['valide_notes_finales'].epreuves.push(result)
                                this.dicoPhaseFilieres[`${phase.id}-${filieres}`].nbEpreuvesAjustementValide++
                            }

                            // Charge les épreuves éligible à l'invalidation
                            if (!this.dicoPhaseFilieres[`${phase.id}-${filieres}`].liste_etapes_ajustement['invalide_notes_finales']) {
                                this.dicoPhaseFilieres[`${phase.id}-${filieres}`].liste_etapes_ajustement['invalide_notes_finales'] = {
                                    libelle: 'invalide_notes_finales',
                                    epreuves: []
                                }
                            }

                            if (result.notes_finalisees_validated_at) {
                                this.dicoPhaseFilieres[`${phase.id}-${filieres}`].liste_etapes_ajustement['invalide_notes_finales'].epreuves.push(result)
                            }
                        }
                    }
                }
                /** **************************************************************************************************************************
                 * FIN - Partie spécifique aux PhaseFilières comprenant des ajustements.
                 * ***************************************************************************************************************************/
                const puce_notes_finalisees_validated = []
                let class_notes_finalisees_validated = 'text-tertiary'
                if (phase.ajustements) {
                    if (result.notes_finalisees_validated_at) {
                        class_notes_finalisees_validated = 'text-success'
                    }
                } else {
                    // Cas d'une FilierePhase ne comprenant pas d'ajustements.
                    // Dans ce cas, pas d'étape intermédiaire d'ajustement, on check directement si les notes sont validées.
                    if (result.notes_finalisees_validated_at) {
                        class_notes_finalisees_validated = 'text-success'
                    }
                }

                puce_notes_finalisees_validated.push({ name:'circle', class: class_notes_finalisees_validated })
                const title_notes_finalisees_validated = result.notes_finalisees_validated_at ? formatStringDate(result.notes_finalisees_validated_at) : ''

                const puce_enter_epreuve = []
                let class_enter_epreuve = 'fa-lg text-center'
                let enter_epreuve_disabled = true
                if (result.correction_finished_at) {
                    enter_epreuve_disabled = false
                    class_enter_epreuve += ' text-info'
                } else {
                    class_enter_epreuve += ' text-tertiary'
                }
                puce_enter_epreuve.push({ name:'arrow-circle-right', class: class_enter_epreuve })

                const line = [
                    { label: '', item: phaseName, type: 'text', typeAction: null, class: '' },
                    { label: '', item: filieres, type: 'text', typeAction: null, class: '' },
                    { label: 'Epreuve de correction', item: nameEpreuveCorrection, type: 'text', typeAction: null, class: '' },
                    { label: '', item: puce_correction_fini, type: 'icons', typeAction: null, class: 'text-center', title: title_correction_fini },
                    { label: '', item: moyenne_notes_brutes, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: ecart_type_notes_brutes, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: etape_ajustement, type: 'text', typeAction: null, class: class_etape_ajustement },
                    { label: '', item: moyenne_notes_finales, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: ecart_type_notes_finales, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: puce_notes_finalisees_validated, type: 'icons', typeAction: null, class: 'text-center', title: title_notes_finalisees_validated },
                    { label: '', item: result.id, icon: 'arrow-circle-right', type: 'action', typeAction: 'detailAjustement', class: class_enter_epreuve, disabled: enter_epreuve_disabled }
                ]
                this.dataForTab.push(line)
            }

            this.nb_filieres = 0
            this.nb_filieres_valide = 0

            for (const concour_id in dicoFilieres) {
                this.nb_filieres++
                if (dicoFilieres[concour_id].total === dicoFilieres[concour_id].valides) {
                    this.nb_filieres_valide++
                }
            }

            this.setDicoPhaseFilieresValidation()
        }

        this.phases = _.uniqBy(this.phases, 'id')
    }

    /**
     * @description Retourne le nom du composant d'ajustement
     * @param {any} epreuve_correction - Epreuve de correction
     * @param {string} ajustement - Ajustement
     * @return {string}
     */
    getDynamicTabLabel(epreuve_correction: any, ajustement: string): string {
        if (ajustement && epreuve_correction) {
            return epreuve_correction?.phase?.ajustements[ajustement]?.name || `Pas d'ajustement`
        }
        return `Pas d'ajustement`
    }

    /**
     * @description Initialise l'objet dicoPhaseFilieresValidation qui permettra d'alimenter la liste des phaseFilières proposées pour la validation des ajustements.
     * @return {void}
     */
    setDicoPhaseFilieresValidation(): void {
        this.dicoPhaseFilieresValidation = {}

        Object.keys(this.dicoPhaseFilieres).forEach(key =>  {
            const item = this.dicoPhaseFilieres[key]
            if (item.phase.ajustements) {
                if (item.concours.length === 1) {
                    this.dicoPhaseFilieresValidation[key] = item
                }
            }
        })

        Object.keys(this.dicoPhaseFilieres).forEach(key =>  {
            const item = this.dicoPhaseFilieres[key]
            if (item.phase.ajustements) {
                if (item.concours.length > 1) {
                    for (let i = 0; i < item.concours.length; i++) {
                        if (this.dicoPhaseFilieresValidation[`${item.phase.id}-${item.concours[i].name}`]) {
                            this.dicoPhaseFilieresValidation[`${item.phase.id}-${item.concours[i].name}`].nbEpreuves =
                                this.dicoPhaseFilieresValidation[`${item.phase.id}-${item.concours[i].name}`].nbEpreuves + item.nbEpreuves
                            this.dicoPhaseFilieresValidation[`${item.phase.id}-${item.concours[i].name}`].nbEpreuvesAjustementValide =
                                this.dicoPhaseFilieresValidation[`${item.phase.id}-${item.concours[i].name}`].nbEpreuvesAjustementValide + item.nbEpreuvesAjustementValide

                            for (const etape_ajustement in item.liste_etapes_ajustement) {
                                if (this.dicoPhaseFilieresValidation[`${item.phase.id}-${item.concours[i].name}`].liste_etapes_ajustement[etape_ajustement]) {
                                    this.dicoPhaseFilieresValidation[`${item.phase.id}-${item.concours[i].name}`].liste_etapes_ajustement[etape_ajustement].epreuves =
                                        this.dicoPhaseFilieresValidation[`${item.phase.id}-${item.concours[i].name}`].liste_etapes_ajustement[etape_ajustement].epreuves
                                            .concat(item.liste_etapes_ajustement[etape_ajustement].epreuves)
                                } else {
                                    this.dicoPhaseFilieresValidation[`${item.phase.id}-${item.concours[i].name}`].liste_etapes_ajustement[etape_ajustement] =
                                        item.liste_etapes_ajustement[etape_ajustement]
                                }
                            }
                        }
                    }
                }
            }
        })
    }

    /**
     * @description Création des filtres du tableau
     * @return {void}
     */
    setFiltersForGenericTab(): void {
        const options_filieres = []

        const filieres: any = _.orderBy(this.$store.getters['concour/banques'], 'ordre', 'asc')
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        const options_phase = [
            { index: 'admissibilite', name: 'Admissibilité' },
            { index: 'admission', name: 'Admission' }
        ]

        this.filtres = [
            { libelle: 'Phases', defautOptionlibelle: 'Toutes les', model: 'epreuve_correction.phase.name', value: '-', index: '', datas: options_phase, loading: false, options: { type: 'deroulant', fieldsKey: 'epreuve_correction.phase.name' } },
            { libelle: 'Filière', defautOptionlibelle: 'Rechercher une', model: 'epreuveCorrectionConcoursId', value: '-', index: 'name', datas: options_filieres, loading: false, options: { type: 'deroulant', fieldsKey: 'epreuve_correction.concours.name' } },
            { libelle: 'Epreuve de correction', defautOptionlibelle: 'Rechercher une', model: 'epreuve_correction.name', value: '', index: 'name', datas: null, loading: false, options: { type: 'form', fieldsKey: 'epreuve_correction.name' } }
        ]

        if (localStorage?.paramsListeEpreuveCorrectionResultatsFiltre) {
            setTimeout(() => {
                const exaGenTab: any = this.$refs['genericTableListeEpreuveCorrectionResultats'] as ExaGenericTable
                exaGenTab.setFiltres(JSON.parse(localStorage.paramsListeEpreuveCorrectionResultatsFiltre))
            }, 500)
        }
    }

    /**
     * @description Événements du tableau
     * @param {any} paParams - Paramètres
     * @return {void}
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    if (this.perPage !== -1) {
                        this.loadHandler(paParams[1])
                    }
                    break
                case 'detailAjustement':
                    this.$router.push('/liste_epreuve_correction_resultats/' + paParams[1])
                    break
            }
        }
    }

    /**
     * @description Application de filtres et rechargement des résultats
     * @param {any} params - Paramètres
     * @return {void}
     */
    filtreSortHandler(params: any): void {
        if (this.firstLoading) {
            this.firstLoading = false
            return
        }

        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            if (window?.localStorage) {
                window.localStorage.setItem('paramsListeEpreuveCorrectionResultats', JSON.stringify(params))
                const exaGenTab: any = this.$refs['genericTableListeEpreuveCorrectionResultats'] as ExaGenericTable
                window.localStorage.setItem('paramsListeEpreuveCorrectionResultatsFiltre', JSON.stringify(exaGenTab.getFiltres()))
            }
            this.$store.commit('epreuveCorrectionResultat/SET_EPREUVE_CORRECTION_RESULTATS', [])
            this.setDataForGenericTab(this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultats'])
            this.params = params || {}
            this.params.perPage = this.perPage
            this.params.scoped = 0
            this.tableLoading = true

            this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultats', this.params)
                .then(() => {
                    this.setDataForGenericTab(this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultats'])
                    this.tableLoading = false
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        }
    }

    /**
     * @description Chargement des résultats
     * @param {any} params - Paramètres
     * @return {void}
     */
    loadHandler(params: any): void {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            if (window?.localStorage) {
                window.localStorage.setItem('paramsListeEpreuveCorrectionResultats', JSON.stringify(params))
                const exaGenTab: any = this.$refs['genericTableListeEpreuveCorrectionResultats'] as ExaGenericTable
                window.localStorage.setItem('paramsListeEpreuveCorrectionResultatsFiltre', JSON.stringify(exaGenTab.getFiltres()))
            }
            this.params = params || {}
            this.params.perPage = this.perPage
            this.params.scoped = 0
            this.tableLoading = true

            this.$store.dispatch('epreuveCorrectionResultat/getMoreEpreuveCorrectionResultats', this.params)
                .then(() => {
                    this.setDataForGenericTab(this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultats'])
                    this.tableLoading = false
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        }
    }

    /**
     * @description Initialisation des données
     * @return {Promise<void>}
     */
    async load(): Promise<void> {
        this.tableLoading = true
        this.filtres = []

        await this.$store.dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] })
        await this.$store.dispatch('concour/getConcoursActifs')
        this.sessionSelect = this.$store.getters['session/sessionSelect']

        await this.$store.dispatch('concourPhase/getConcourPhases', { 'filter-ajustement': 1 })
        await this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultats', {'perPage': this.perPage, scoped: 0})
        this.tableLoading = false
        this.setDataForGenericTab(this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultats)
        this.setFiltersForGenericTab()
        this.firstLoading = true
    }

    /**
     * @description Montage du composant
     * @return {Promise<void>}
     */
    async mounted(): Promise<void> {
        if (this.$store.getters['auth/user_session_id']) {
            await this.load()
        }
    }

    /**
     * @description Supprime l'intervale avant la destruction du composant
     * @return {void}
     */
    beforeDestroy(): void {
        if (this.intervalRemonteeNote) {
            clearInterval(this.intervalRemonteeNote)
            this.intervalRemonteeNote = null
        }

        if (this.intervalImportNotesExternes) {
            clearInterval(this.intervalImportNotesExternes)
            this.intervalImportNotesExternes = null
        }

        if (this.intervalImportNotesTIPE) {
            clearInterval(this.intervalImportNotesTIPE)
            this.intervalImportNotesTIPE = null
        }
    }
}
