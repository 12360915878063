























































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import EditorTinyMCE from '@/components/Tools/EditorTinyMCE.vue'
import { displayGroupPublishableToString, display_interface_publishable, code_publishable } from '@/types/Publishable'
import { type_publishable_message } from '@/types/PublishableMessage'

@Component({
    methods: {
        displayGroupPublishableToString
    },
    computed: {
        Ability() {
            return Ability
        },
        type_publishable_message() {
            return type_publishable_message
        },
        ...mapGetters('candidat', ['candidats', 'loading', 'totalRows', 'lastPage', 'totalPage']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('publishableMessage', ['publishableMessages', 'publishables', 'loading', 'error'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        EditorTinyMCE
    }
})

export default class Parametrage extends Vue {
    loadingData = true
    showModalMessagechangeWithoutSaving = false
    useOnAllForm = false
    nextPublishableSelected: any = null
    publishableSelected: any = null
    publishableMessageSelected: any = null
    publishableMessageSelectedOption: any = null
    publishableMessageContent: any = {
        content_html: null,
        content_pdf: null,
        link_title: null
    }

    /**
     * onPublishableMessageSelectedOptionChanged
     * @description Vérification et chargement des données à afficher
     * @param {any} value - Nouvelle valeur
     * @param {any} oldValue - Ancienne valeur
     * @return {void}
     */
    @Watch('publishableMessageSelectedOption')
    onPublishableMessageSelectedOptionChanged(value: any, oldValue: any): void {
        if (!this.showModalMessagechangeWithoutSaving && oldValue && this.isEdited()) {
            // Affiche la modale s'il y a une modification
            this.showModalMessagechangeWithoutSaving = true
        } else {
            // Évite de supprimer les modifications si on annule le changement
            if (this.publishableMessageSelected?.id !== this.publishableMessageSelectedOption?.id) {
                this.useOnAllForm = false
                this.publishableMessageSelected = this.publishableMessageSelectedOption
                this.publishableMessageContent = {
                    content_html: this.publishableMessageSelectedOption?.content_html || null,
                    content_pdf: this.publishableMessageSelectedOption?.content_pdf || null,
                    link_title: this.publishableMessageSelectedOption?.link_title || null
                }
            }
            this.showModalMessagechangeWithoutSaving = false
        }
    }

    getEditorMode(publishableMessage: any): string {
        const defaultMode = 'templateParamGestionPortailClient'

        switch (publishableMessage.publishable.display_interface) {
            case display_interface_publishable.CONVOCATIONS_ADMISSION:
            case display_interface_publishable.CONVOCATIONS_ADMISSIBILITE:
                if (publishableMessage.code === 'acces_ok') {
                    return 'templateParamGestionPortailClientPlanning'
                }
                return 'templateParamGestionPortailClientError'

            case display_interface_publishable.COPIE:
                if (publishableMessage.code === 'acces_ok') {
                    return 'templateCopie'
                }
                return defaultMode

            default:
                return defaultMode
        }
    }

    getSpecificEditorMode(publishableMessage: any, type: 'html' | 'pdf'): string {
        switch (publishableMessage.publishable.code) {
            case code_publishable.RESULTATS_BANQUE_ADMISSIBILITE:
            case code_publishable.RESULTATS_BANQUE_ADMISSION:
                return `templateParamGestionPortailClientResultatBanque${type.toUpperCase()}`

            case code_publishable.RESULTATS_ADMISSION:
            case code_publishable.RESULTATS_ADMISSIBILITE:
                return `templateParamGestionPortailClientResultat${type.toUpperCase()}`

            default:
                return 'html_pdf'
        }
    }

    /**
     * cancelChangeWithoutSaving
     * @description Annulation du changement sans sauvegarde
     * @return {void}
     */
    cancelChangeWithoutSaving(): void {
        this.publishableMessageSelectedOption = this.publishableMessageSelected
    }

    /**
     * confirmChangeWithoutSaving
     * @description Confirmation du changement sans sauvegarde
     * @return {void}
     */
    confirmChangeWithoutSaving(): void {
        this.onPublishableMessageSelectedOptionChanged(this.publishableMessageSelectedOption, undefined)
        if (this.nextPublishableSelected) {
            this.publishableSelected = this.nextPublishableSelected
            this.nextPublishableSelected = null
        }
    }

    /**
     * savePublishableMessageSelected
     * @description Sauvegarde du contenu de l'éditeur
     * @param {string} value - Valeur
     * @param {number} type - Type
     * @return {void}
     */
    savePublishableMessageSelected(value: string, type: number): void {
        switch (type) {
            case type_publishable_message.TYPE_HTML:
                this.publishableMessageContent.content_html = value
                break
            case type_publishable_message.TYPE_PDF:
                this.publishableMessageContent.content_pdf = value
                break
        }
    }

    /**
     * changePublishable
     * @description Changement de la publication sélectionnée
     * @param {any} publi - Publication
     * @return {void}
     */
    changePublishable(publi: any): void {
        if (publi === this.publishableSelected) {
            return
        }

        if (this.isEdited()) {
            this.nextPublishableSelected = publi
        } else {
            this.publishableSelected = publi
        }
        this.publishableMessageSelectedOption = null
    }

    setLinkTitle(title: string): void {
        this.publishableMessageContent.link_title = title
    }

    /**
     * save
     * @description Sauvegarde des modifications
     * @return {void}
     */
    save(): void {
        const pdfInput: any = document.getElementsByClassName('bouton-pdf-input')
        if (pdfInput.length > 0) {
            let emptyPdfInput = false

            for (let i = 0; i < pdfInput.length; i++) {
                pdfInput[i].value = pdfInput[i].value.trim()
                if (!pdfInput[i].reportValidity()) {
                    emptyPdfInput = true
                }
            }

            if (emptyPdfInput) {
                return
            }
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const payload = {
            id: this.publishableMessageSelected.id,
            content_html: this.publishableMessageContent.content_html,
            content_pdf: this.publishableMessageContent.content_pdf,
            link_title: this.publishableMessageContent.link_title,
            type: this.publishableMessageSelected.type,
            applyall: this.useOnAllForm ? '1' : '0'
        }

        this.$store.dispatch('publishableMessage/updatePublishableMessage', payload)
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)

                this.loadingData = true
                await this.$store.dispatch('publishableMessage/getPublishableMessages')
                this.publishableMessageSelected.content_html = this.publishableMessageContent.content_html
                this.publishableMessageSelected.content_pdf = this.publishableMessageContent.content_pdf
                this.publishableMessageSelected.link_title = this.publishableMessageContent.link_title
                this.useOnAllForm = false
                this.loadingData = false
            })
            .catch((error) => console.log('ko:' + error))
            .finally(() => this.$bvToast.hide(idInfo))
    }

    /**
     * isEdited
     * @description Vérifie si le contenu de l'éditeur a été modifié
     * @return {boolean}
     */
    isEdited(): boolean {
        const cleanContent = (content: string) => {
            const txt = document.createElement('textarea')
            txt.innerHTML = content || ''
            const result = txt.value.replace(/<[^>]+>/gm, '').trim()
            txt.remove()
            return result
        }

        return !!this.publishableSelected &&
            !!this.publishableMessageSelected &&
            (cleanContent(this.publishableMessageSelected?.content_html) !== cleanContent(this.publishableMessageContent?.content_html) ||
                cleanContent(this.publishableMessageSelected?.content_pdf) !== cleanContent(this.publishableMessageContent?.content_pdf) ||
                this.publishableMessageSelected?.link_title !== this.publishableMessageContent?.link_title)
    }

    /**
     * mounted
     * @description Chargement des données du composant
     * @return {Promise<void>}
     */
    async mounted(): Promise<void> {
        await this.$store.dispatch('publishableMessage/getPublishableMessages')
        this.publishableSelected = this.$store.getters['publishableMessage/publishables'][0]
        this.loadingData = false
    }
}
