














































import { Vue, Component } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import DashboardEcritsPreparation from '@/components/DashboardEcrits/DashboardEcritsPreparation.vue'
import AffichageCommentCandidat from '@/components/DashboardOraux/AffichageCommentCandidat.vue'
import { mapState } from 'vuex'

@Component({
    computed: {
        ...mapState('dashboardEcrits', ['dashboard_ecrits_informations'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        DashboardEcritsPreparation,
        AffichageCommentCandidat
    }
})

export default class DashboardEcrits extends Vue {
    tabSelected = 'preparation'
}
