
































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import { formatDate, formatNumber, exportDivElementAsImage } from '@/utils/helpers'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, BoxplotChart, ScatterChart } from 'echarts/charts'
import {
    DatasetComponent,
    GridComponent,
    MarkLineComponent,
    TitleComponent,
    TooltipComponent,
    TransformComponent
} from 'echarts/components'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { incrementArrayChartOutliersDetail, getCurrentNbOfCurrentOutliers } from '@/store/modules/EchartsToolsManage'
import AjustementNotesSeuils from '@/components/Deliberation/AjustementNotesSeuils.vue'
import { ScopeAjustement } from '@/types/EpreuveCorrectionResultat'

use([
    CanvasRenderer,
    BarChart,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    TransformComponent,
    BoxplotChart,
    ScatterChart,
    CanvasRenderer,
    MarkLineComponent
])

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('epreuveCorrectionResultat', ['epreuveCorrectionResultatSelect', 'ajustementIsValidable', 'ajustementIsEditable', 'tableAjustementParams', 'epreuveCorrectionCorrecteurs', 'dicoNotesByUserId', 'notes', 'loading', 'totalRows', 'lastPage', 'totalPage', 'error'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ECharts,
        ErrorDisplay,
        AjustementNotesSeuils
    }
})

export default class GradeThresholdEpreuve extends Vue {
    @Prop() epreuvecorrectionId: any
    @Prop() visible?: boolean
    @Prop() indexAjustement?: number
    ScopeAjustement = ScopeAjustement
    Ability = Ability
    formatDate = formatDate
    formatNumber = formatNumber
    loadingData = false
    option_graph_bar_av: any = null
    option_graph_stats_av: any = null
    option_graph_bar_ap: any = null
    option_graph_stats_ap: any = null
    selected_correcteur_id = 0
    nb_class = 20
    note_max = 20
    gradethresholdMinimumOriginal = 0.00
    gradethresholdMaximumOriginal = 20.00
    gradethresholdMinimum = 0.00
    gradethresholdMaximum = 20.00
    showModalMessageValidation = false
    validationEnCours = false
    arrayChartOutliersTotal: any = []
    showAjustements = false
    index_actual_ajustement: any = null
    index_prec_ajustement: any = null

    points_saisis: any = []

    mark_lines: Array<any> = []
    mark_lines_ap: Array<any> = []
    libelle_ajustement = ''

    /**
     * @Description Ouvre la popup d'ajustement des notes
     * @returns {void}
     */
    ajuster_notes(): void {
        this.showAjustements = true
    }

    /**
     * @Description Ferme la popup d'ajustement des notes
     * @returns {void}
     */
    close_ajuster_notes(): void {
        this.showAjustements = false
        this.load_notes()
    }

    /**
     * @Description Met à jour le nombre d'outliers
     * @returns {void}
     */
    maj_nb_outliers(): void {
        this.arrayChartOutliersTotal =  this.$store.state.echartsToolsManage.arrayChartOutliersTotal
    }

    /**
     * @Description Ouvre la popup de validation de l'ajustement
     * @returns {void}
     */
    open_confirm_validation(): void {
        this.$store.state.epreuveCorrectionResultat.error = null
        this.showModalMessageValidation = true
    }

    /**
     * @Description Ferme la popup de validation de l'ajustement
     * @returns {void}
     */
    cancel_validation(): void {
        this.showModalMessageValidation = false
        if (this.gradethresholdMinimum !== this.gradethresholdMinimumOriginal || this.gradethresholdMaximum !== this.gradethresholdMaximumOriginal) {
            this.gradethresholdMinimum = this.gradethresholdMinimumOriginal
            this.gradethresholdMaximum = this.gradethresholdMaximumOriginal
            this.build_chart_options()
        }
    }

    /**
     * @Description Valide l'ajustement
     * @returns {void}
     */
    confirm_validation(): void {
        this.validationEnCours = true
        const route_validate = this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect']?.ajustements_params[this.$props.indexAjustement]?.validated_at ? 'invalidateAdjustement' : 'validateAdjustement'
        const payload = {
            params: [],
            validate: this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect']?.ajustements_params[this.$props.indexAjustement]?.validated_at ? 0 : 1

        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('epreuveCorrectionResultat/'+ route_validate, {
            epreuve_correction_resultat_id: this.epreuvecorrectionId,
            adjustement_id: this.$props.indexAjustement,
            payload: payload
        })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)

                this.showModalMessageValidation = false
                if (this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect']?.ajustements_params[this.$props.indexAjustement]?.params.n_min &&
                    this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect']?.ajustements_params[this.$props.indexAjustement]?.params.n_max) {
                    this.gradethresholdMinimum = this.gradethresholdMinimumOriginal = parseFloat(this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.ajustements_params[this.$props.indexAjustement].params.n_min)
                    this.gradethresholdMaximum = this.gradethresholdMaximumOriginal = parseFloat(this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.ajustements_params[this.$props.indexAjustement].params.n_max)
                }
                this.loadingData = true
                this.load_notes()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.validationEnCours = false
            })
    }

    // Création des options pour les graphiques
    /**
     * @Description Création des options pour les graphiques
     * @param {string} from - 'none' | 'min' | 'max'
     * @returns {Promise<void>}
     */
    async build_chart_options(from = 'none'): Promise<void> {
        if (from !== 'none')  {
            this.gradethresholdMinimum = typeof this.gradethresholdMinimum !== 'number' ? parseFloat(this.gradethresholdMinimum) : this.gradethresholdMinimum
            this.gradethresholdMaximum = typeof this.gradethresholdMaximum !== 'number' ? parseFloat(this.gradethresholdMaximum) : this.gradethresholdMaximum

            if (this.gradethresholdMaximum > this.note_max) {
                this.gradethresholdMaximum = 20
            }
            if (this.gradethresholdMinimum >= this.gradethresholdMaximum) {
                const step = 0.01
                if (from === 'min') {
                    this.gradethresholdMinimum = (this.gradethresholdMaximum - step)
                } else {
                    this.gradethresholdMaximum = (this.gradethresholdMinimum + step)
                }
            }
        }
        const data = this.$store.getters['epreuveCorrectionResultat/dicoNotesByUserId'](this.selected_correcteur_id, this.nb_class, this.note_max)

        // Réinit forcé des tableaux des nbr d'outliers
        this.$store.state.echartsToolsManage.arrayChartOutliersTotal = []
        this.$store.state.echartsToolsManage.arrayChartOutliersDetail = []

        const xaxisData = []
        const rapnb_saallaNoteMax =  this.note_max / this.nb_class
        for (let i = 0; i < this.note_max; i = i + rapnb_saallaNoteMax) {
            xaxisData.push('[' + i + '-' + (i + rapnb_saallaNoteMax) + ((i + rapnb_saallaNoteMax) === this.note_max ? ']' : '['))
        }

        // Créer les mark lines
        this.mark_lines = []
        this.mark_lines_ap = []
        await this.$nextTick()
        this.mark_lines.push({
            type : 'average',
            name: 'gradethreshold minimum',
            valueIndex: 1,
            xAxis: this.gradethresholdMinimum,
            itemStyle:{ normal:{ color:'#ff0000' } },
            label: {
                position: 'end',
                color: '#FF0000',
                formatter: () => { return 'Nmin = ' + this.gradethresholdMinimum }

            },
            lineStyle: {
                type: 'solid'
            }
        })

        // Récupérer les points définis et les affichés en marklines
        if (this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect']?.ajustements_params[this.$props.indexAjustement]?.params?.thresholds) {
            this.points_saisis = this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect'].ajustements_params[this.$props.indexAjustement].params.thresholds
        }

        for (const p in this.points_saisis) {
            this.mark_lines.push({
                type : 'average',
                name: 'P' + (parseInt(p)+1),
                xAxis: this.points_saisis[p].origin,
                itemStyle:{ normal:{ color:'#b64384' } },
                label: {
                    position: 'end',
                    color: '#b64384',
                    formatter: () => { return 'P'+ (parseInt(p)+1) +': ' + this.points_saisis[p].origin }

                },
                lineStyle: {
                    type: 'dotted'
                }
            })

            this.mark_lines_ap.push(                            {
                type : 'average',
                name: 'P' + (parseInt(p)+1),
                xAxis: this.points_saisis[p].adjusted,
                itemStyle:{ normal:{ color:'#b64384' } },
                label: {
                    position: 'end',
                    color: '#b64384',
                    formatter: () => { return 'P'+ (parseInt(p)+1) +': ' + this.points_saisis[p].adjusted }

                },
                lineStyle: {
                    type: 'dotted'
                }
            })
        }

        this.mark_lines.push({
            type : 'average',
            name: 'gradethreshold maximum',
            valueIndex: 0,
            xAxis: this.gradethresholdMaximum,
            itemStyle:{ normal:{ color:'#ff0000' } },
            label: {
                position: 'end',
                color: '#FF0000',
                formatter: () => { return 'Nmax = ' + this.gradethresholdMaximum }
            }
        })

        this.option_graph_bar_av = {
            autoresize: true,
            cursor: 'default',
            animationDuration: 500,
            grid: {
                left: 40,
                top: 20,
                right: 40,
                bottom: 5
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c}'
            },
            xAxis: [{
                type: 'category',
                data: xaxisData
            },
            {
                type: 'value',
                min: 0,
                max: this.note_max,
                show: false
            }],
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: this.index_prec_ajustement ? data.ajustements.table[this.index_prec_ajustement] : data.brute.table,
                    xAxisIndex: 0,
                    type: 'bar',
                    showBackground: false,
                    silent: false,
                    color: '#3A93BA',
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    }
                },
                {
                    xAxisIndex: 1,
                    data : [],
                    type: 'bar',
                    markLine : {
                        silent: true,
                        symbol:['none', 'none'],
                        data : this.mark_lines
                    }
                }
            ]
        }

        this.option_graph_stats_av = {
            animationDuration: 500,
            autoresize: true,
            cursor: 'default',
            grid: {
                left: 40,
                top: 0,
                right: 40,
                bottom: 25
            },
            dataset: [
                { source: [this.index_prec_ajustement ? data.ajustements.liste[this.index_prec_ajustement] : data.brute.liste] },
                {
                    transform: {
                        type: 'boxplot'
                    }
                },
                { fromDatasetIndex: 1, fromTransformResult: 1 }
            ],
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (params: any) => {
                    if (params && params.data && params.data.length > 4) {
                        return [
                            'Supérieur: ' + Math.round(params.data[5] * 100) / 100,
                            '3eme Quartile: ' + Math.round(params.data[4] * 100) / 100,
                            'Mediane: ' + Math.round(params.data[3] * 100) / 100,
                            '1er Quartile: ' + Math.round(params.data[2] * 100) / 100,
                            'Inférieur: ' + Math.round(params.data[1] * 100) / 100
                        ].join('<br/>')
                    } else {
                        return 'Outlier : ' + Math.round(params.data[1] * 100) / 100
                    }
                }
            },
            xAxis: {
                type: 'value',
                name: '',
                min: 0,
                max: this.note_max
            },
            yAxis: {
                name: '',
                type: 'category'
            },
            series: [
                {
                    name: ' ',
                    type: 'boxplot',
                    datasetIndex: 1
                },
                {
                    name: 'Outlier',
                    type: 'scatter',
                    datasetIndex: 2,
                    itemStyle: {
                        color: '#5470C6'
                    },
                    label: {
                        show: true,
                        formatter: function(params: any) {
                            // on se sert du formatage d'un label vide pour faire l'incrémentation des entrées d'Outlier.
                            incrementArrayChartOutliersDetail('graph_stats_av', params.value)
                            return ''
                        }
                    },
                    tooltip: {
                        trigger: 'item',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: function(params: any) {
                            // reformatage du tooltip pour l'outlier
                            let nbrEntites = 0
                            if (params.value) {
                                nbrEntites = getCurrentNbOfCurrentOutliers('graph_stats_av', params.value)
                            }
                            return [
                                'Outlier : ' + params.value[1],
                                'Entités : ' + nbrEntites
                            ].join('<br/>')
                        }
                    }
                }
            ]
        }

        this.option_graph_bar_ap = {
            animationDuration: 500,
            autoresize: true,
            cursor: 'default',
            grid: {
                left: 40,
                top: 20,
                right: 40,
                bottom: 5
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c}'
            },
            xAxis: [{
                type: 'category',
                data: xaxisData
            },
            {
                type: 'value',
                min: 0,
                max: this.note_max,
                show: false
            }],
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: data.ajustements.table[this.$props.indexAjustement],
                    type: 'bar',
                    showBackground: false,
                    silent: false,
                    color: '#99CBB4',
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    }
                },
                {
                    xAxisIndex: 1,
                    data : [],
                    type: 'bar',
                    markLine : {
                        silent: true,
                        symbol:['none', 'none'],
                        data : this.mark_lines_ap
                    }
                }
            ]
        }

        this.option_graph_stats_ap = {
            animationDuration: 500,
            autoresize: true,
            cursor: 'default',
            grid: {
                left: 40,
                top: 0,
                right: 40,
                bottom: 25
            },
            dataset: [
                { source: [data.ajustements.liste[this.$props.indexAjustement]] },
                {
                    transform: {
                        type: 'boxplot'
                    }
                },
                { fromDatasetIndex: 1, fromTransformResult: 1 }
            ],
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (params: any) => {
                    if (params.data && params.data.length > 4) {
                        return [
                            'Supérieur: ' + Math.round(params.data[5] * 100) / 100,
                            '3eme Quartile: ' + Math.round(params.data[4] * 100) / 100,
                            'Mediane: ' + Math.round(params.data[3] * 100) / 100,
                            '1er Quartile: ' + Math.round(params.data[2] * 100) / 100,
                            'Inférieur: ' + Math.round(params.data[1] * 100) / 100
                        ].join('<br/>')
                    } else {
                        return 'Outlier : ' + Math.round(params.data[1] * 100) / 100
                    }
                }
            },
            xAxis: {
                type: 'value',
                name: '',
                min: 0,
                max: this.note_max
            },
            yAxis: {
                name: '',
                type: 'category'
            },
            series: [
                {
                    name: ' ',
                    type: 'boxplot',
                    datasetIndex: 1
                },
                {
                    name: 'Outlier',
                    type: 'scatter',
                    datasetIndex: 2,
                    itemStyle: {
                        color: '#5470C6'
                    },
                    label: {
                        show: true,
                        formatter: function(params: any) {
                            // on se sert du formatage d'un label vide pour faire l'incrémentation des entrées d'Outlier.
                            incrementArrayChartOutliersDetail('graph_stats_ap', params.value)
                            return ''
                        }
                    },
                    tooltip: {
                        trigger: 'item',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: function(params: any) {
                            // reformatage du tooltip pour l'outlier
                            let nbrEntites = 0
                            if (params.value) {
                                nbrEntites = getCurrentNbOfCurrentOutliers('graph_stats_ap', params.value)
                            }
                            return [
                                'Outlier : ' + params.value[1],
                                'Entités : ' + nbrEntites
                            ].join('<br/>')
                        }
                    }
                }
            ]
        }
    }

    /**
     * @Description Redimenssionnement des graphiques selon écran d'affichage
     * @returns {void}
     */
    resize_charts(): void {
        // Réinit forcé des tableaux des nbr d'outliers
        this.$store.state.echartsToolsManage.arrayChartOutliersTotal = []
        this.$store.state.echartsToolsManage.arrayChartOutliersDetail = []

        const cbarav: any = this.$refs.graph_bar_av
        const cstatsav: any = this.$refs.graph_stats_av
        const cbarap: any = this.$refs.graph_bar_ap
        const cstatsap: any = this.$refs.graph_stats_ap
        const chartBoite1 = this.$refs.chartBoite1 as HTMLElement
        const chartBoite2 = this.$refs.chartBoite2 as HTMLElement

        cbarav?.resize({
            width: chartBoite1.offsetWidth + 'px',
            height:  (this.$refs.div_graph_bar_av as HTMLElement).offsetHeight + 'px'
        })
        cstatsav?.resize({
            width: chartBoite1.offsetWidth + 'px',
            height:  (this.$refs.div_graph_stats_av as HTMLElement).offsetHeight + 'px'
        })
        cbarap?.resize({
            width:  chartBoite2.offsetWidth + 'px',
            height:  (this.$refs.div_graph_bar_ap as HTMLElement).offsetHeight + 'px'
        })
        cstatsap?.resize({
            width:  chartBoite2.offsetWidth + 'px',
            height:  (this.$refs.div_graph_stats_ap as HTMLElement).offsetHeight + 'px'
        })
    }

    /**
     * @Description Chargement des notes
     * @returns {void}
     */
    load_notes(): void {
        this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultatsNotes', {
            epreuve_correction_id: this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect'].epreuve_correction_id
        })
            .then(() => {
                this.build_chart_options()
                this.resize_charts()
                window.addEventListener('resize', this.resize_charts)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.loadingData = false
            })
    }

    /**
     * @Description Exporter les graphiques
     * @param {string} elementDomId - Id de l'élément à exporter
     * @param {string} rootFileExportName - Nom du fichier à exporter
     * @returns {void}
     */
    exporter_graph_image(elementDomId: string, rootFileExportName: string): void {
        exportDivElementAsImage(elementDomId, rootFileExportName)
    }

    /**
     * @Description Chargement des données
     * @returns {void}
     */
    load_datas(): void {
        this.$store.state.epreuveCorrectionResultat.notes = null
        this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultats', {
            'filter-epreuve_correction_id': this.epreuvecorrectionId,
            scoped: 0
        })
            .then((response) => {
                this.$store.commit('epreuveCorrectionResultat/SET_EPREUVE_CORRECTION_CORRECTEURS', response.data.data)
                if (this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect']?.ajustements_params[this.$props.indexAjustement]?.params?.n_min &&
                    this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect']?.ajustements_params[this.$props.indexAjustement]?.params?.n_max) {
                    this.gradethresholdMinimum = this.gradethresholdMinimumOriginal = parseFloat(this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.ajustements_params[this.$props.indexAjustement].params.n_min)
                    this.gradethresholdMaximum = this.gradethresholdMaximumOriginal = parseFloat(this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect.ajustements_params[this.$props.indexAjustement].params.n_max)
                }
                this.load_notes()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    /**
     * @description Montage du composant
     * @returns {Promise<void>}
     */
    async mounted(): Promise<void> {
        this.loadingData = true

        this.libelle_ajustement = this.$store.getters['epreuveCorrectionResultat/getAjustement'](this.$props.indexAjustement).name
        this.index_actual_ajustement = this.$store.getters['epreuveCorrectionResultat/tableAjustementParams'].findIndex((a: any) => a.ajustement === this.$props.indexAjustement)
        this.index_prec_ajustement = this.$store.getters['epreuveCorrectionResultat/getIndexAjustementPrec'](this.$props.indexAjustement)

        if (JSON.stringify(this.$store.state.epreuveCorrectionResultat.epreuveCorrectionResultatSelect) === '{}') {
            await this.$store.dispatch('epreuveCorrectionResultat/getEpreuveCorrectionResultat', { epreuveCorrectionResultat_id: this.epreuvecorrectionId })
        }
        this.load_datas()
    }

    /**
     * @description Avant destruction du composant
     * @returns {void}
     */
    beforeDestroy(): void {
        window.removeEventListener('resize', this.resize_charts)
    }
}
