export interface SerieInterface {
    id: number | 0,
    session_id: number | 0,
    code: string,
    name: string,
    planifie: number | 0,
    publie: number | 0,
    valide: number | 0,
    debut: Date,
    fin: Date,
    debut_saisie_dispo: Date,
    fin_saisie_dispo: Date,
    debut_pub_s_salle: Date,
    debut_pub_a_salle: Date,
    fin_pub: Date
}

export interface StateSeries {
    series: Array<SerieInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    serieSelect: SerieInterface | {}
}

export enum etat_validation_serie {
    NON_VALIDEE,
    VALIDEE,
}
