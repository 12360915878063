






























import { Vue, Component, Prop, Watch }    from 'vue-property-decorator'
import { mapGetters }               from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditCandidat            from '@/components/Candidat/PopupEditCandidat.vue'
import PopupEditCommentCandidat     from '@/components/Candidat/PopupEditCommentCandidat.vue'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),

        ...mapGetters('definitionBarresStatistiquesEpreuves', ['tableauStatistiquesEpreuves', 'meta', 'links', 'loading', 'error', 'currentPage', 'lastPage', 'totalPage', 'totalRows']),
        ...mapGetters('definitionDesBarres', ['selectedTab'])

    },
    components: {
        ExaGenericTable,
        PopupEditCandidat,
        PopupEditCommentCandidat,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class StatistiquesEpreuves extends Vue
{
    @Prop() concour: any

    Ability = Ability
    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;
    barres: any     = []
    filters: any    = []

    filtres:    any         = []
    dataForTab: Array<any>  = []

    genericfields =  [
        { key: 'epreuve.name',                      label: 'Épreuve',                  sortable: false,     class: '', type: 'text', epreuve_id: null },
        { key: 'moyenne_notes_finales',             label: 'Moyenne',                  sortable: false,     class: 'text-center', type: 'text', epreuve_id: null },
        { key: 'ecart_type_notes_finales',          label: 'Écart type',               sortable: false,     class: 'text-center', type: 'text', epreuve_id: null },
        { key: 'nb_note_min_finales',               label: 'Nombre "0"',               sortable: false,     class: 'text-center', type: 'text', epreuve_id: null },
        { key: 'premier_quartile_notes_finales',    label: '1er Quartile',             sortable: false,     class: 'text-center', type: 'text', epreuve_id: null },
        { key: 'mediane_notes_finales',             label: 'Médiane',                  sortable: false,     class: 'text-center', type: 'text', epreuve_id: null },
        { key: 'troisieme_quartile_notes_finales',  label: '3ème Quartile',            sortable: false,     class: 'text-center', type: 'text', epreuve_id: null },
        { key: 'nb_note_max_finales',               label: 'Nombre "20"',              sortable: false,     class: 'text-center', type: 'text', epreuve_id: null }
    ]

    @Watch('selectedTab', { immediate: true })
    watchSelectedTab() {
        if (this.$store.getters['definitionBarresStatistiquesEpreuves/tableauStatistiquesEpreuves'].length !== 0 && this.$store.getters['definitionDesBarres/selectedTab'] === 'statistiquesEpreuves') {
            this.setDataForGenericTab(this.$store.getters['definitionBarresStatistiquesEpreuves/tableauStatistiquesEpreuves'])
        }
        if (this.$store.getters['definitionBarresStatistiquesEpreuves/tableauStatistiquesEpreuves'].length === 0 && this.$store.getters['definitionDesBarres/selectedTab'] === 'statistiquesEpreuves') {
            this.initDatas()
        }
    }

    // Création des lignes du tableau
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const epreuve               = result.epreuve.long_name ? result.epreuve.long_name : ' - '
                const moyenne               = result.moyenne_notes_finales ? result.moyenne_notes_finales.toFixed(2) : ' - '
                const ecartType             = result.ecart_type_notes_finales ? result.ecart_type_notes_finales.toFixed(2) : ' - '
                const nbNotesMin            = result.nb_note_min_finales ? result.nb_note_min_finales : ' - '
                const premierQuartile       = result.premier_quartile_notes_finales ? result.premier_quartile_notes_finales.toFixed(2) : ' - '
                const mediane               = result.mediane_notes_finales ? result.mediane_notes_finales.toFixed(2) : ' - '
                const troisiemeQuartile     = result.troisieme_quartile_notes_finales ? result.troisieme_quartile_notes_finales.toFixed(2) : ' - '
                const nbNotesMax            = result.nb_note_max_finales ? result.nb_note_max_finales : ' - '

                const line = [
                    { label: '',            item: epreuve,              type: 'text',    typeAction: null,          class: '' },
                    { label: '',            item: moyenne,              type: 'text',    typeAction: null,          class: 'text-center' },
                    { label: '',            item: ecartType,            type: 'text',    typeAction: null,          class: 'text-center' },
                    { label: '',            item: nbNotesMin,           type: 'text',    typeAction: null,          class: 'text-center' },
                    { label: '',            item: premierQuartile,      type: 'text',    typeAction: null,          class: 'text-center' },
                    { label: '',            item: mediane,              type: 'text',    typeAction: null,          class: 'text-center' },
                    { label: '',            item: troisiemeQuartile,    type: 'text',    typeAction: null,          class: 'text-center' },
                    { label: '',            item: nbNotesMax,           type: 'text',    typeAction: null,          class: 'text-center' }
                ]
                /* Si des corrélations sont à afficher */
                if (result.correlations)
                {
                    // recup des valeurs des correlations
                    const noteCorrelation = result.correlations

                    // TODO supprimer le compteur et le if (i < 2) quand on voudra mettre en place la 3 éme colonne
                    let i = 0
                    // boucle dans les correlation et push dans line des infos
                    for (const correlation in noteCorrelation) {
                        // parseFloat((4).toFixed(2))
                        if (i < 2) {
                            line.push({ label: '',            item: Math.round((noteCorrelation[correlation].toFixed(2) * 100)) + '%',           type: 'text',    typeAction: null,          class: 'text-center' })
                        }
                        i++
                    }
                }
                this.dataForTab.push(line)
            }

            this.dynamicGenericField()
        }
    }

    // Création des filtres
    setFiltersForGenericTab()
    {
        this.filters = []
    }

    // Events du tableau
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'sortHandler':
                    this.loadHandler(paParams[1])
                    break

                case 'filterHandler':
                    this.loadHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break

                default:
                    break
            }
        }
    }

    // Initialisation des données
    initDatas ()
    {
        const payload = {
            concour_id: this.$props.concour.concour_id,
            phase_id: this.$props.concour.phase_id,
            params:  { page: 1, sort: 'epreuve.ordre', direction: 'asc' }
        }
        this.$store.dispatch('definitionBarresStatistiquesEpreuves/getTableauStatistiquesEpreuves', payload).then(() => {
            this.setDataForGenericTab(this.$store.getters['definitionBarresStatistiquesEpreuves/tableauStatistiquesEpreuves'])
        })
    }

    // Chargement des résultats
    loadHandler (params: any)
    {
        const payload = {
            concour_id: this.$props.concour.concour_id,
            phase_id: this.$props.concour.phase_id,
            params: params
        }
        this.$store.dispatch('definitionBarresStatistiquesEpreuves/getMoreTableauStatistiquesEpreuves', payload)
    }

    // Création des entêtes de colonnes
    dynamicGenericField() {
        /* Remise a l'initialisation des fields */
        this.genericfields =  [
            { key: 'epreuve.name',                      label: 'Épreuve',                  sortable: false,     class: '', type: 'text', epreuve_id: null },
            { key: 'moyenne_notes_finales',             label: 'Moyenne',                  sortable: false,     class: 'text-center', type: 'text', epreuve_id: null },
            { key: 'ecart_type_notes_finales',          label: 'Écart type',               sortable: false,     class: 'text-center', type: 'text', epreuve_id: null },
            { key: 'nb_note_min_finales',               label: 'Nombre "0"',               sortable: false,     class: 'text-center', type: 'text', epreuve_id: null },
            { key: 'premier_quartile_notes_finales',    label: '1er Quartile',             sortable: false,     class: 'text-center', type: 'text', epreuve_id: null },
            { key: 'mediane_notes_finales',             label: 'Médiane',                  sortable: false,     class: 'text-center', type: 'text', epreuve_id: null },
            { key: 'troisieme_quartile_notes_finales',  label: '3ème Quartile',            sortable: false,     class: 'text-center', type: 'text', epreuve_id: null },
            { key: 'nb_note_max_finales',               label: 'Nombre "20"',              sortable: false,     class: 'text-center', type: 'text', epreuve_id: null }
        ]
        // verification du getter, contrôle si champs tableau correlation
        const getterTableauStatistiquesEpreuves = this.$store.getters['definitionBarresStatistiquesEpreuves/tableauStatistiquesEpreuves']
        // recup des barres afin d'utiliser le nom des barres sur l'affichage
        const barres = this.$props.concour.deliberation.barres
        if (getterTableauStatistiquesEpreuves && getterTableauStatistiquesEpreuves[0] && getterTableauStatistiquesEpreuves[0].correlations)
        {
            const noteCorrelation = getterTableauStatistiquesEpreuves[0].correlations
            // compteur afin de parcourir les barres et recup les infos d'affichage
            let i = 0
            for (const correlation in noteCorrelation) {
                // push d'affichage : label : Si barres[i] n'existe pas, alors on affiche la valeur de  la clef de l'objet correlation
                // TODO supprimer le compteur et le if (i < 2) quand on voudra mettre en place la 3 éme colonne
                if (i < 2)
                {
                    this.genericfields.push({ key: 'Correlation ' + correlation,               label: 'Corrélation Total ' + (barres[i] && barres[i].nom ? barres[i].nom : correlation),              sortable: false,     class: 'text-center', type: 'text', epreuve_id: null })
                }
                i++
            }
        }
    }

}
