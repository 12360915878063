








































































import { Vue, Component, Watch }  from 'vue-property-decorator'
import { mapGetters, mapState }   from 'vuex'
import { Ability }                from '@/types/Ability'
import { FontAwesomeIcon }        from '@fortawesome/vue-fontawesome'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('candidat', ['candidats', 'authUser', 'candidats_avec_commentaires_importants']),
        ...mapState('dashboardEcrits', ['dashboard_ecrits_informations', 'loading_dashboard_ecrit'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class DashboardEcritsPreparation extends Vue {
    ability                   = Ability
    selected_tab              = 'commentaires'
    Ability                   = Ability
    tuiles: any               = []
    info: any                 = []
    full_lancement_delib = false



    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    /**  creer un objet de deux tableaux (box1, box2) correspondant aux deux colonnes d'affichage. */
    createPreparationDashboard() {
        this.tuiles = {
            box1: [
                {
                    name: 'Gestion des centres d\'écrit',
                    icone: 'school',
                    tooltip: null,
                    informations: [
                        {
                            description: 'CENTRES ONT ÉTÉ PUBLIÉS',
                            link: '/centres_ecrits',
                            etat: this.getColor(this.info.centres_nb_publies, this.info.centres_nb_total),
                            stat1: this.info.centres_nb_publies + '/' + this.info.centres_nb_total
                        },
                        {
                            description: 'CENTRES AVEC L\'ORGANISATION VALIDÉE',
                            link: '/centres_ecrits',
                            etat: this.getColor(this.info.centres_nb_organisations_valides, this.info.centres_nb_total),
                            stat1: this.info.centres_nb_organisations_valides + '/' + this.info.centres_nb_publies
                        },
                        {
                            description: 'CENTRES AVEC LES INFORMATIONS VALIDÉES',
                            link: '/centres_ecrits',
                            etat: this.getColor(this.info.centres_nb_informations_valides, this.info.centres_nb_publies),
                            stat1: this.info.centres_nb_informations_valides + '/' + this.info.centres_nb_publies
                        }]
                },
                {
                    name: 'GESTIONS DES CANDIDATS',
                    icone: 'users',
                    tooltip: !this.info.is_barres_validees,
                    informations: [
                        {
                            description: 'CANDIDATS INSCRITS AUX CONCOURS',
                            link: '/candidats_ecrits',
                            etat: this.info.candidats_nb_total !== 0 ? this.info.is_barres_validees ? 'text-success' : 'text-info' : 'text-secondary',
                            stat1: this.info.candidats_nb_total,
                            sousDescription: this.info.candidats_concours
                        },
                        {
                            description: 'CANDIDATS RÉPARTIS DANS LES CENTRES',
                            link: '/repartition_candidats',
                            etat: this.info.candidats_nb_repartis !== 0 ? this.info.candidats_nb_repartis === this.info.candidats_nb_total ? 'text-success' : 'text-info' : 'text-secondary',
                            stat1: this.info.candidats_nb_repartis + '/' + this.info.candidats_nb_total
                        },
                        {
                            description: 'DEMANDES D\'AMÉNAGEMENTS',
                            link: '/candidats_ecrits',
                            etat: this.getColor(this.info.amenagement_nb_traites, this.info.amenagement_nb_total),
                            stat1: this.info.amenagement_nb_traites + '/' + this.info.amenagement_nb_total
                        }
                    ]
                }
            ],
            box2: [
                {
                    name: 'Suivi des sujets',
                    icone: 'clipboard-list-check',
                    tooltip: null,
                    informations: [
                        {
                            description: 'CENTRES ONT DES SUJETS EN PRODUCTION',
                            link: '/suivi_sujets_fournitures',
                            etat: this.getColor(this.info.centres_nb_f_suj_production, this.info.centres_nb_total),
                            stat1: this.info.centres_nb_f_suj_production + '/' + this.info.centres_nb_publies
                        },
                        {
                            description: 'CENTRES ONT ACCUSÉ RÉCEPTION DES SUJETS',
                            link: '/suivi_sujets_fournitures',
                            etat: this.getColor(this.info.centres_nb_f_suj_ar, this.info.centres_nb_total),
                            stat1: this.info.centres_nb_f_suj_ar + '/' + this.info.centres_nb_publies
                        },
                        {
                            description: 'CENTRES AVEC DES ANOMALIES DE RÉCEPTION SUR LES SUJETS',
                            link: '/suivi_sujets_fournitures',
                            etat: this.getColor(this.info.centres_nb_f_suj_annomalies, this.info.centres_nb_publies),
                            stat1: this.info.centres_nb_f_suj_annomalies + '/' + this.info.centres_nb_publies
                        }]
                },
                {
                    name: 'Suivi des fournitures papetières',
                    icone: 'clipboard-list-check',
                    tooltip: null,
                    informations: [
                        {
                            description: 'CENTRES ONT RENSEIGNÉ LEUR INVENTAIRE',
                            link: '/suivi_sujets_fournitures',
                            etat: this.getColor(this.info.centres_nb_f_pap_inventaire, this.info.centres_nb_publies),
                            stat1: this.info.centres_nb_f_pap_inventaire + '/' + this.info.centres_nb_publies
                        },
                        {
                            description: 'CENTRES ONT DES FOURNITURES PAPETIÈRES EN PRODUCTION',
                            link: '/suivi_sujets_fournitures',
                            etat: this.getColor(this.info.centres_nb_f_pap_production, this.info.centres_nb_publies),
                            stat1: this.info.centres_nb_f_pap_production + '/' + this.info.centres_nb_publies
                        },
                        {
                            description: 'CENTRES ONT ACCUSÉ RÉCEPTION DES FOURNITURES PAPETIÈRES',
                            link: '/suivi_sujets_fournitures',
                            etat: this.getColor(this.info.centres_nb_f_pap_ar, this.info.centres_nb_publies),
                            stat1: this.info.centres_nb_f_pap_ar + '/' + this.info.centres_nb_publies
                        },
                        {
                            description: 'CENTRES AVEC DES ANOMALIES DE RÉCEPTION SUR LES FOURNITURES PAPETIÈRES',
                            link: '/suivi_sujets_fournitures',
                            etat: this.getColor(this.info.centres_nb_f_pap_annomalies, this.info.centres_nb_publies),
                            stat1: this.info.centres_nb_f_pap_annomalies + '/' + this.info.centres_nb_publies
                        }]
                },
                {
                    name: 'Suivi des fournitures administratives',
                    icone: 'clipboard-list-check',
                    tooltip: null,
                    informations: [
                        {
                            description: 'CENTRES ONT DES FOURNITURES ADMINISTRATIVES EN PRODUCTION',
                            link: '/suivi_sujets_fournitures',
                            etat: this.getColor(this.info.centres_nb_f_adm_production, this.info.centres_nb_total),
                            stat1: this.info.centres_nb_f_adm_production + '/' + this.info.centres_nb_publies
                        },
                        {
                            description: 'CENTRES ONT ACCUSÉ RÉCEPTION DES FOURNITURES ADMINISTRATIVES',
                            link: '/suivi_sujets_fournitures',
                            etat: this.getColor(this.info.centres_nb_f_adm_ar, this.info.centres_nb_total),
                            stat1: this.info.centres_nb_f_adm_ar + '/' + this.info.centres_nb_publies
                        },
                        {
                            description: 'CENTRES AVEC DES ANOMALIES DE RÉCEPTION SUR LES FOURNITURES ADMINISTRATIVES',
                            link: '/suivi_sujets_fournitures',
                            etat: this.getColor(this.info.centres_nb_f_adm_annomalies, this.info.centres_nb_publies),
                            stat1: this.info.centres_nb_f_adm_annomalies + '/' + this.info.centres_nb_publies
                        }]
                }
            ]
        }
    }

    /**
     * Récupération sur le serveur des concours
     */
    loadConcoursIfNotExists ()
    {
        return new Promise((resolve) =>
        {
            // Check si le chargement est sur la session active
            let session_active_load = false
            if (this.$store.state.session.sessionSelect && this.$store.getters['concour/banques'].length !== 0 &&
            this.$store.state.session.sessionSelect.id === this.$store.getters['concour/banques'][0].session_id) {
                session_active_load = true
            }

            if (this.$store.getters['concour/banques'].length === 0 || !session_active_load)
            {
                this.$store.dispatch('concour/getConcoursActifs').then(() =>
                {
                    resolve(true)
                })
            }
            else
            {
                resolve(true)
            }
        })
    }

    /** gestion de la couleur des lignes d'informations  */
    getColor(number1?: number | boolean, number2?: number) {
        if (!number2) {
            return number1 === true ? 'text-success' : 'text-secondary'
        } else {
            if (number1 === 0)
            {
                return 'text-secondary'
            } else if (number1 !== number2) {
                return 'text-info'
            } else if (number1 === number2) {
                return 'text-success'
            }
        }
    }

    load () {
        this.$store.dispatch('dashboardEcrits/getDashboardInformations').then(() => {
            this.info = this.$store.state.dashboardEcrits.dashboard_ecrits_informations
            if (this.info !== null) {
                this.createPreparationDashboard()
            }
            // Si aucun concours n'a été chargé, alors on les récupère également
            this.loadConcoursIfNotExists()
        })
    }

    beforeMount () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
