















































































































































































/* Les données affichées dans la page proviennent du clic de la fleche dans DefinitionDesBarres.vue, ce clic charge definitionDesBarres/selectedConcour */
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ListeDesNotesAdmission from '@/views/DefinitionDesBarres/ListeDesNotesAdmission.vue'
import Back from '@/components/Tools/Back.vue'
import PopupDefinirLesBarresAdmission from '@/components/DefinitionDesBarres/PopupDefinirLesBarresAdmission.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import PopupExportationListeDesNotesAdmission from '@/components/DefinitionDesBarres/PopupExportationListeDesNotesAdmission.vue'
import { vowelOrNot, formatDateVariante, getFileNameFromHeader, base64ToArrayBuffer, formatDate } from '@/utils/helpers'
import { typeBarre } from '@/types/DefinitionDesBarres'
import _ from 'lodash'
import { BarTypePass } from '@/types/Barre'

@Component({
    methods: { formatDate, vowelOrNot },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('definitionDesBarres', ['tableauDesConcours', 'meta', 'links', 'loading', 'error', 'currentPage', 'lastPage', 'totalPage', 'totalRows', 'selectedConcour']),
        ...mapGetters('definitionBarresListeNotesAdmission', ['errorListeNoteAdmission', 'historiqueConcourPhase']),
        ...mapGetters('rankingGroup', ['getRankinGroupNameById'])
        // ...mapGetters('concourPhase', ['phaseCompteurs'])
    },
    components: {
        ListeDesNotesAdmission,
        FontAwesomeIcon,
        Back,
        PopupDefinirLesBarresAdmission,
        ErrorDisplay,
        PopupExportationListeDesNotesAdmission
    }
})

export default class DefinitionDesBarresAdmission extends Vue {
    // ************* DATAS
    formatDateVariante                  = formatDateVariante
    concour: any                        = []
    /* Recup de l'id concour afin de le faire passer dans les onglets */
    concourID                           = this.$route.params.id
    /* PopupDefinir les barres */
    showPopupDefinirBarres              = false
    togglePopupValidationBarres         = false
    togglePopupInvalidationBarres       = false
    togglePopupHistoriqueConcourPhase   = false
    errorKeeper                         = null
    Ability                             = Ability
    showModalExportation                = false
    session                             = this.$store.getters['session/sessionSelect']
    nbrClasses                          = -1 // nombre de classés
    barreAdmission: Array<any>          = []
    barres: any                         = []
    isBarreAdmissibiliteValidated       = false // on ne peut pas définir ou valider la barre d'admission tant que la barre d'admissibilité n'a pas été validée.
    bar_rules: any                    = []


    // ************* LISTENERS
    /**
     * Recharge l'interface si l'id de la session change
     * @returns {void}
     */
    @Watch('user_session_id')
    refreshInterface(): void {
        this.$router.push({ path: '/definition_des_barres' })
    }

    /**
     * Recharge les données si le concours sélectionné change
     * @returns {Promise<void>}
     */
    @Watch('selectedConcour')
    async watchSelectConcour(): Promise<void> {
        this.concour = this.$store.getters['definitionDesBarres/selectedConcour']
        this.setNbrClasses(this.$store.getters['definitionDesBarres/selectedConcour'].deliberation.barres)
        this.isBarreAdmissibiliteValidated = this.checkIsBarreAdmissibiliteValidated()

        // Chargement de la session active
        await this.loadSessionActiveIfNotExists()
        // Chargement des banques
        await this.loadBanquesIfNotExists()
        // on récupère l'historique des validations/invalidations
        await this.$store.dispatch('definitionBarresListeNotesAdmission/getHistoriqueConcourPhase', { concourphase_id: this.$store.getters['definitionDesBarres/selectedConcour'].id })
    }

    hiddenBarres = [
        BarTypePass.BAR_TYPE_ANONYMOUS
    ]

    getBarreRuleByIdOfConcour(id: any, bar_rules: any) {
        for(const bar_rule of bar_rules){
            if(bar_rule.id === id) {
                return bar_rule
            }
        }
        return null
    }

    ranking_groups:Array<any> = []

    @Watch('concour', { deep: true })
    loadBarres() {
        this.barres = []
        this.ranking_groups = []
        if(this.concour && this.concour.deliberation && this.concour.deliberation.bar_rules) {
            this.bar_rules = this.concour.deliberation.bar_rules
        }

        if(this.concour.deliberation && this.concour.deliberation.barres){
            for(const barre of this.concour.deliberation.barres) {
                if(!this.hiddenBarres.includes(barre.type)) {
                    for(const threshold of barre.thresholds) {
                        if (!this.ranking_groups.find((b: any) => String(b.ranking_group_id) === String(barre.ranking_group_id))){
                            this.ranking_groups.push({
                                ranking_group_id: barre.ranking_group_id,
                                name: barre.thresholds.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, this.concour.deliberation.bar_rules)?.name : barre.name
                            })
                        }

                        const check_barre = this.barres.find((b: any) => String(b.id) === String(barre.id) && String(b.bar_rule_id) === String(threshold.bar_rule_id))
                        if (!check_barre) {
                            this.barres.push({
                                id: barre.id,
                                value: threshold.value,
                                bar_rule_id: threshold.bar_rule_id,
                                name: barre.thresholds.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, this.concour.deliberation.bar_rules)?.name: barre.name
                            })
                        }
                    }
                }
            }
        }
        if(this.concour.candidatsByRankingGroup) {
            for (let i = 0; i < this.concour.candidatsByRankingGroup.length; i++) {
                for(let j = 0; j < this.ranking_groups.length; j++) {
                    if(String(this.concour.candidatsByRankingGroup[i].ranking_group_id) === String(this.ranking_groups[j].ranking_group_id)) {
                        this.ranking_groups[j].ranking_group_id_count = this.concour.candidatsByRankingGroup[i].ranking_group_id_count
                    }
                }
            }
        }
        this.setNbrClasses(this.barres)
    }

    /**
     * Charge les barres lors de la modification du concours
     * @returns {void}
     */
    @Watch('concour', { deep: true })
    loadBarresOld(): void {
        this.barres = []

        if(this.concour.deliberation && this.concour.deliberation.barres){
            for(const barre of this.concour.deliberation.barres) {
                if(!this.hiddenBarres.includes(barre.type)) {
                    for(const threshold of barre.thresholds) {
                        const check_barre = this.barres.find((b: any) => String(b.id) === String(barre.id) && String(b.bar_rule_id) === String(threshold.bar_rule_id))
                        if (!check_barre) {
                            this.barres.push({
                                id: barre.id,
                                value: threshold.value,
                                bar_rule_id: threshold.bar_rule_id,
                                name: barre.thresholds.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, this.concour.deliberation.bar_rules)?.name: barre.name
                            })
                        }
                    }
                }
            }
        }

    }

    // ************* METHODS
    /**
     * Charge la session active si elle n'existe pas
     * @param {boolean} force - Force le chargement
     * @returns {Promise<void>}
     */
    async loadSessionActiveIfNotExists(force = false): Promise<void> {
        if (!this.$store.getters['session/sessionSelect'] || force) {
            await this.$store.dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] })
        }
    }

    /**
     * Charge les banques si elles n'existent pas
     * @returns {Promise<void>}
     */
    async loadBanquesIfNotExists(): Promise<void> {
        if (!this.$store.getters['banque/banques']?.length) {
            await this.$store.dispatch('banque/getBanques', { isPrecedente: false })
        }
    }

    /**
     * Retourne la liste passée en paramètre ordonnée par created_at descendant
     * @param {any} liste - Liste à ordonner
     * @returns {any}
     */
    orderedListeByCreatedDateDesc(liste: any): any {
        return _.orderBy(liste, 'created_at', 'desc')
    }

    /**
     * Vérifie via le concours_phase équivalent pour la phase d'admissibilite si la barre d'admissibilite a été validée
     * @returns {boolean}
     */
    checkIsBarreAdmissibiliteValidated(): boolean {
        // récupération du concours_phase équivalent pour la phase d'admission
        const concourMatch = this.$store.state.definitionDesBarres.tableauDesConcours.filter((c: any) => c.concour_id === this.$store.getters['definitionDesBarres/selectedConcour'].concour_id)
        if (concourMatch.length === 1) {
            return true
        } else {
            const concourMatch0 = this.$store.state.definitionDesBarres.tableauDesConcours.filter((c: any) => c.concour_id === this.$store.getters['definitionDesBarres/selectedConcour'].concour_id && c.phase_id === 1)
            if (concourMatch0.length > 0) {
                return (concourMatch0[0].deliberation.barreValide === 1 || concourMatch0[0].deliberation.barreValide === true)
            }
        }
        return false
    }

    /**
     * Récupère le nom de la banque par son id
     * @param {number} idBanque - Id de la banque
     * @returns {string}
     */
    getBanqueNameById(idBanque: number): string {
        return this.$store.getters['banque/getBanqueById'](idBanque)?.name || ''
    }

    /**
     * Récupère la valeur max possible pour la barre d'admission
     * @param {any} barres - Barres
     * @returns {void}
     */
    setNbrClasses(barres: any): void {
        const barreAdmission = barres.filter((b: any) => b.code === typeBarre.CODE_BARRE_ADMISSION)
        if (barreAdmission.length > 0) {
            this.barreAdmission = barreAdmission[0]
            this.nbrClasses = barreAdmission[0].maxBarre
        }
    }

    /**
     * Ouvre le popup de définition des barres
     * @returns {void}
     */
    popupDefinitionDesBarres(): void {
        this.showPopupDefinirBarres = true
    }

    /**
     * Ferme le popup de définition des barres
     * @returns {void}
     */
    closePopupDefinirLesBarres(): void {
        this.showPopupDefinirBarres = false
    }

    /**
     * Toggle le popup de validation des barres
     * @returns {void}
     */
    togglePopupBarreValidation(): void {
        this.errorKeeper = null
        this.$store.commit('definitionDesBarres/SET_ERROR', null)
        this.togglePopupValidationBarres = !this.togglePopupValidationBarres
    }

    /**
     * Toggle le popup d'invalidation des barres
     * @returns {void}
     */
    togglePopupBarreInvalidation(): void {
        this.errorKeeper = null
        this.$store.commit('definitionDesBarres/SET_ERROR', null)
        this.togglePopupInvalidationBarres = !this.togglePopupInvalidationBarres
    }

    /**
     * Toggle le popup d'historique des validations / invalidations des barres
     * @returns {void}
     */
    setTogglePopupHistoriqueConcourPhase(): void {
        this.errorKeeper = null
        this.$store.commit('definitionDesBarres/SET_ERROR', null)
        this.togglePopupHistoriqueConcourPhase = !this.togglePopupHistoriqueConcourPhase
    }

    getStringBarresHistory(data: any) {
        const retour = []
        for(const barre of data.barres) {
            if(!this.hiddenBarres.includes(barre.type)) {
                for(const threshold of barre.thresholds) {
                    retour.push((barre.thresholds.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, data.bar_rules)?.name : barre.name) + ' : <b>' + (threshold.value ? threshold.value : '-') + '</b>')
                }
            }
        }
        return retour
    }

    /**
     * Exporte la liste des candidats admissibles
     * @param {string} format - Format de l'export
     * @returns {void}
     */
    exportListeCandidatAdmissiblesCng (format = ''): void {
        this.$store.commit('definitionBarresListeNotes/SET_ERROR_EXPORT', null)
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        let payload :any = {
            concour_id : this.concour.concour_id,
            phase_id: this.concour.phase_id
        }
        if (format) {
            payload = {
                ...payload,
                format: format
            }
        } else {
            payload = {
                ...payload,
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
                responseType: 'arraybuffer'
            }
        }

        this.$store.dispatch('definitionBarresListeNotes/exportListeCandidatAdmissiblesCng', payload)
            .then((response) => {
                // Logique de téléchargement
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([format ? base64ToArrayBuffer(response.data) : response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || 'exportAdmission.pdf')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Fonction de validation et invalidation selon le flag passé en paramétres
     * @param {number} state - Etat
     * @returns {void}
     */
    validateInvalidateBarre(state: number): void {
        /* TOAST enregistrement en cours */
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        /* fin toast */

        const params = {
            concourphase: this.concourID,
            _method: 'PUT',
            validate: state,
            data: {
                bar_rules: this.concour.deliberation.bar_rules,
                barres: JSON.parse(JSON.stringify(this.concour.deliberation.barres))
            }
        }
        this.$store.dispatch('definitionDesBarres/putDefinitionDesBarres', params)
            .then(() => {
                this.$store.dispatch('definitionDesBarres/getTableauDesConcours')
                    .then((resp) => {
                        const id = parseInt(this.$route.params.id)
                        this.concour = resp.data.data.filter((concour: { id: any }) => concour.id === id)[0]
                        this.$store.commit('definitionDesBarres/SET_SELECTED_CONCOUR', this.concour)

                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }

                        const message: string = state === 1 ? 'Validation des barres terminée.' : 'Invalidation des barres terminée.'
                        this.$bvToast.toast(message, succesToaster)
                        if(this.togglePopupInvalidationBarres){
                            this.togglePopupBarreInvalidation()
                        }
                        if(this.togglePopupValidationBarres){
                            this.togglePopupBarreValidation()
                        }
                    })
                    .catch(() => {
                        this.errorKeeper = this.$store.getters['definitionDesBarres/error']
                    })
            })
            .catch(() => {
                this.errorKeeper = this.$store.getters['definitionDesBarres/error']
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Ouvre le popup d'exportation
     * @returns {void}
     */
    exporterListeDesNotes(): void {
        this.showModalExportation = true
    }

    /**
     * Réinitialise le popup d'exportation
     * @returns {void}
     */
    reinitShowModalExportation(): void {
        this.showModalExportation = false
    }

    /**
     * Charge les données
     * @returns {Promise<void>}
     */
    async load(): Promise<void> {
        /* toute la logique dessous sert uniquement en cas de refresh de la page */
        this.$store.commit('definitionBarresListeNotesAdmission/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.$store.commit('definitionDesBarres/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée

        this.concour = this.$store.getters['definitionDesBarres/selectedConcour']
        if (this.concour) {
            this.$store.dispatch('definitionDesBarres/getTableauDesConcours')
                .then((resp) => {
                    const cTemp = resp.data.data.filter((concour: { id: any }) => concour.id === parseInt(this.$route.params.id))[0]
                    if (cTemp) {
                        this.concour = resp.data.data.filter((concour: { id: any }) => concour.id === parseInt(this.$route.params.id))[0]
                    }
                    this.$store.commit('definitionDesBarres/SET_SELECTED_CONCOUR', this.concour)
                })
        }
        /*
        if (!this.$route.path.startsWith('/resultats')) {
            await this.$store.dispatch('concourPhase/getConcourPhaseCompteurs', { concourphase_id: this.concourID })
        }
        */
    }

    codeClient = 'CCMP'

    /**
     * Montage du composant
     * @returns {Promise<void>}
     */
    async mounted(): Promise<void> {
        const parameter = this.$store.getters['auth/findParameter']('codeClient')?.value
        this.codeClient = parameter || 'CCMP'
        if (this.$store.getters['auth/user_session_id']) {
            await this.load()
        }
    }
}

