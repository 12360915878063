




















































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { GridComponent, MarkLineComponent, MarkPointComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { ecart_type, moyenne, quantile } from '@/utils/helpers'
import { ScopeAjustement } from '@/types/EpreuveCorrectionResultat'

use([
    GridComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
    MarkLineComponent,
    MarkPointComponent
])

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('epreuveCorrectionResultat', ['notes']),
        ...mapState('ajustement', ['error'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ECharts,
        ErrorDisplay
    }
})

export default class AjustementNotesSeuils extends Vue {
    @Prop() mode_ajustement?: string
    @Prop() show?: boolean
    @Prop() datas?: any
    @Prop() max_grade?: number
    @Prop() index_ajustement?: number
    @Prop() index_prec_ajustement?: any
    @Prop() if_test?: boolean

    Ability = Ability
    ScopeAjustement = ScopeAjustement
    resultats: any = []
    resultats_global: any = []
    simulation_resultats: any = []
    option: any = null
    option_stats: any = null
    view_courbe = false
    points_saisis: any = []
    old_points_saisis: any = []
    liste_notes: any = []
    waiting_process = false
    wainting_request = false
    another_update = false
    replaceModal = false
    stats_av : any = {
        moyenne: 0,
        ecart_type: 0,
        mediane: 0,
        ecart_interquartile: 0
    }

    stats_ap : any = {
        moyenne: 0,
        ecart_type: 0,
        mediane: 0,
        ecart_interquartile: 0
    }

    stats_global: any = {
        moyenne: 0,
        ecart_type: 0,
        mediane: 0,
        ecart_interquartile: 0
    }

    @Watch('view_courbe')
    onViewCourbeChange() {
        if (this.view_courbe) {
            this.resizeCourbe()
        }
    }

    /**
     * @description Retourne le nom des correcteurs
     * @return {string}
     */
    getNamesCorrectors(): string {
        let liste_correctors: Array<any> = []
        if (this.$props.mode_ajustement === ScopeAjustement.BATCH_CORRECTION) {
            liste_correctors = this.$props.datas.scope.corrector_group.correctors
        } else if (this.$props.mode_ajustement === ScopeAjustement.CORRECTOR_GROUP) {
            liste_correctors = this.$props.datas.scope.correctors
        }

        let name_correctors = liste_correctors
            .map((corrector: any) => `${corrector.name} ${corrector.first_name}`)
            .join(', ')

        if (this.$props.mode_ajustement === ScopeAjustement.BATCH_CORRECTION) {
            name_correctors += ' - LOT ' + this.$props.datas.scope.external_num
        }

        return name_correctors
    }

    /**
     * Affiche la modal de confirmation de remplacement des valeurs
     * @return {void}
     */
    showReplaceModal(): void {
        if (this.points_saisis.length) {
            this.replaceModal = true
        } else {
            this.addComputedPoints()
        }
    }

    /**
     * Ferme la modal de confirmation de remplacement des valeurs
     * @return {void}
     */
    closeReplaceModal(): void {
        this.replaceModal = false
    }

    /**
     * @description Applique les ajustements choisis
     * @return {void}
     */
    save(): void {
        this.waiting_process = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        let id_select = this.$store.getters['epreuveCorrectionResultat/epreuveCorrectionResultatSelect'].id
        if (this.$props.mode_ajustement !== ScopeAjustement.EPREUVE) {
            id_select = this.$props.datas.id
        }

        this.$store.dispatch('epreuveCorrectionResultat/saveAdjustement', {
            epreuve_correction_resultat_id: id_select,
            adjustement_id: this.$props.index_ajustement,
            payload: {
                ajustement_name: this.$props.index_ajustement,
                epreuvecorrectionresultat_id: id_select,
                params: {
                    thresholds: this.points_saisis
                }
            }
        })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)

                this.close()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.waiting_process = false
            })
    }

    /**
     * @description Ferme l'outil d'ajustement des notes
     * @return {void}
     */
    close(): void {
        this.$emit('close')
    }

    /**
     * @description Créer le graphique de la courbe de point
     * @return {void}
     */
    @Watch('points_saisis', { immediate: true, deep: true })
    createCourbeGraph(): void {
        const data = [[0, 0]]
        this.points_saisis?.forEach((point: any) => {
            data.push([point.origin, point.adjusted])
        })
        data.push([20, 20])

        this.option = {
            tooltip: {
                trigger: 'item',
                formatter : '{c}'
            },
            grid: {
                top: '8%'
            },
            xAxis: {
                min: 0,
                max: 20,
                type: 'value',
                splitNumber: 20,
                name: "Notes avant ajustement",
                nameLocation: "center",
                nameRotate: 0,
                nameTextStyle: {
                    padding: 10,
                    color: '#909090'
                },
                axisLine: { onZero: true },
                tooltip: true
            },
            yAxis: {
                min: 0,
                max: 20,
                type: 'value',
                splitNumber: 20,
                name: "Notes après ajustement",
                nameLocation: "center",
                nameRotate: 90,
                nameTextStyle: {
                    padding: 10,
                    color: '#016793'
                },
                axisLine: { onZero: true },
                tooltip: true
            },
            series: [
                {
                    id: 'a',
                    type: 'line',
                    smooth: false,
                    symbolSize: 10,
                    data: data,
                    color: '#1da6ef'
                }
            ]
        }

        this.waitRequestAnimationFrame()
            .then(() => this.resizeCourbe())
    }

    /**
     * @description Redimensionne le graphique de la courbe
     * @return {void}
     */
    resizeCourbe(): void {
        const wrapper: HTMLElement = document.getElementById('aside-ajustement-notes') as HTMLElement
        const courbe: any = this.$refs['courbe']

        if (this.view_courbe && wrapper && courbe) {
            courbe.resize({
                width: `${wrapper.clientWidth - 16}px`,
                height: '478px'
            })
        }
    }

    /**
     * @description Créer le graphique de la courbe de point
     * @return {void}
     */
    @Watch('resultats')
    @Watch('simulation_resultats')
    @Watch('resultats_global')
    createStatsGraph(): void {
        const series: any = []
        const style = {
            type: 'line',
            step: true,
            symbolSize: 0
        }

        series.push({
            ...style,
            id: 'av_ajustement',
            data: this.resultats,
            color: '#909090'
        })
        series.push({
            ...style,
            id: 'ap_ajustement',
            data: this.simulation_resultats,
            color: '#016793'
        })

        if (this.$props.mode_ajustement !== ScopeAjustement.EPREUVE) {
            series.push({
                ...style,
                id: 'gb_ajustement',
                data: this.resultats_global,
                color: '#f8bf2F'
            })
        }

        this.option_stats = {
            tooltip: {
                trigger: 'none',
                axisPointer: {
                    type: 'cross'
                }
            },
            xAxis: {
                min: 0,
                max: 20,
                type: 'value',
                splitNumber: 20,
                name: "Notes",
                nameTextStyle: {
                    padding: 0
                },
                axisLine: { onZero: true },
                tooltip: true
            },
            yAxis: {
                min: 0,
                max: 100,
                type: 'value',
                splitNumber: 10,
                name: "Proportion de candidats (%)",
                axisLine: { onZero: true },
                axisLabel: {
                    formatter: '{value} %'
                },
                tooltip: true
            },
            series: series
        }

        this.waitRequestAnimationFrame()
            .then(() => this.resizeStats())
    }

    /**
     * @description Redimensionne le graphique des statistiques
     * @return {void}
     */
    resizeStats(): void {
        this.option_stats = {
            ...this.option_stats,
            yAxis: {
                nameRotate: (window.innerWidth > 1400 ? 0 : 90),
                nameLocation: (window.innerWidth > 1400 ? 'end' : 'center'),
                nameTextStyle: {
                    padding: (window.innerWidth > 1400 ? 0 : 30)
                }
            }
        }

        const wrapper: HTMLElement = document.getElementById('graph-ajustement-notes') as HTMLElement
        const graph_stats: any = this.$refs['graph_stats']
        if (wrapper && graph_stats) {
            graph_stats.resize({
                width: `${wrapper.clientWidth - 16}px`,
                height: `${wrapper.clientHeight > 500 ? 500 : wrapper.clientHeight}px`
            })
        }
    }

    /**
     * @description Créer la courbe globale des correcteurs
     * @return {void}
     */
    createGlobalCourbe(): void {
        this.resultats_global = this.$store.state.epreuveCorrectionResultat.notes_globales
            .sort((a: any, b: any) => {
                const noteA = this.$props.index_prec_ajustement ? a.notes[this.$props.index_prec_ajustement] : a.note_brute
                const noteB = this.$props.index_prec_ajustement ? b.notes[this.$props.index_prec_ajustement] : b.note_brute
                return noteA - noteB
            })
            .map((resultat: any, index: number, array: any[]) => {
                const note = this.$props.index_prec_ajustement ? resultat.notes[this.$props.index_prec_ajustement] : resultat.note_brute
                return [note, (index + 1) * 100 / array.length]
            })

        this.createStatsGraph()
    }

    /**
     * @description Arrondi au centième
     * @param {number} value - Valeur à arrondir
     * @return {number}
     */
    round(value: number): number {
        return Math.round(value * 100000) / 100000
    }

    /**
     * @description Attends le rafraichissement complet du navigateur
     * @return {Promise<void>}
     */
    waitRequestAnimationFrame(): Promise<void> {
        return new Promise((resolve) => {
            this.$nextTick(() => {
                window.requestAnimationFrame(() => {
                    window.requestAnimationFrame(() => {
                        resolve()
                    })
                })
            })
        })
    }

    /**
     * @description Cherche le point suivant avant ajustement
     * @param {any} e - Evènement
     * @return {void}
     */
    searchPoint(e: any): void {
        if (e) {
            e.preventDefault()
            const index_point = parseInt(e.currentTarget.attributes['data-index'].value)
            const direction = e.currentTarget.attributes['data-direction'].value
            let needSimulation = false;
            let newValue: number;

            const closest = this.liste_notes.reduce((prev: any, curr: any) => {
                return (Math.abs(curr[0] - this.points_saisis[index_point].origin) < Math.abs(prev[0] - this.points_saisis[index_point].origin) ? curr : prev)
            })
            const index = this.liste_notes.indexOf(closest)

            switch (direction) {
                case 'up':
                    if (index <= this.liste_notes.length - 1) {
                        if (this.points_saisis[index_point].origin < closest[0]) {
                            newValue = closest[0]
                        } else {
                            newValue = this.liste_notes[index + 1][0]
                        }

                        newValue = this.adjustValue(this.round(newValue), 0.00001, 19.99999)
                        if (index_point === this.points_saisis.length - 1 || newValue < this.points_saisis[index_point + 1].origin) {
                            this.points_saisis[index_point].origin = newValue
                            needSimulation = true
                        }
                    }
                    break
                case 'down':
                    if (index >= 0) {
                        if (this.points_saisis[index_point].origin > closest[0]) {
                            newValue = closest[0]
                        } else {
                            newValue = this.liste_notes[index - 1][0]
                        }

                        newValue = this.adjustValue(this.round(newValue), 0.00001, 19.99999)
                        if (index_point === 0 || newValue > this.points_saisis[index_point - 1].origin) {
                            this.points_saisis[index_point].origin = newValue
                            needSimulation = true
                        }
                    }
                    break
            }

            if (needSimulation) {
                this.simulateNotes()
            }
        }
    }

    /**
     * @description Incrémente / Décrémente les points
     * @param {string} direction - Direction
     * @param {number} index_point - Index du point
     * @param {string} type_point - Type de point
     * @return {void}
     */
    incrementPoints(direction: string, index_point: number, type_point: string): void {
        switch (direction) {
            case 'up':
                if (this.points_saisis[index_point][type_point] < 20) {
                    this.points_saisis[index_point][type_point] = this.round(this.points_saisis[index_point][type_point] + 0.1)
                }
                break
            case 'down':
                if (this.points_saisis[index_point][type_point] > 0) {
                    this.points_saisis[index_point][type_point] = this.round(this.points_saisis[index_point][type_point] - 0.1)
                }
                break
        }

        this.simulateNotes()
    }

    /**
     * @description Ajustement des notes
     * @return {boolean} Renvoie true si les valeurs ont été modifiées
     */
    adjustInputs(): boolean {
        if (JSON.stringify(this.old_points_saisis) === JSON.stringify(this.points_saisis)) {
            return false
        }

        const editedIndexes: number[] = []
        this.points_saisis.forEach((point: any, index: number) => {
            if (JSON.stringify(point) !== JSON.stringify(this.old_points_saisis[index])) {
                editedIndexes.push(index)
            }
        })

        const min = this.liste_notes[0][0] < 0.00001 ? 0.00001 : this.round(this.liste_notes[0][0])
        const max = this.liste_notes[this.liste_notes.length - 1][0] > 19.99999 ? 19.99999 : this.round(this.liste_notes[this.liste_notes.length - 1][0])

        editedIndexes.forEach((index: number) => {
            if (this.points_saisis[index]) {
                this.points_saisis[index].origin = this.adjustValue(
                    this.round(this.points_saisis[index].origin),
                    this.round(this.points_saisis[index - 1]?.origin + 0.00001) || min,
                    this.round(this.points_saisis[index + 1]?.origin - 0.00001) || max
                )
                this.points_saisis[index].adjusted = this.adjustValue(
                    this.round(this.points_saisis[index].adjusted),
                    this.round(this.points_saisis[index - 1]?.adjusted) || 0,
                    this.round(this.points_saisis[index + 1]?.adjusted) || 20
                )
            }
        })

        this.old_points_saisis = JSON.parse(JSON.stringify(this.points_saisis))
        return true
    }

    /**
     * @description Applique les seuils sur la collection de notes
     * @return {void}
     */
    simulateNotes(): void {
        if (!this.adjustInputs()) {
            return
        }

        if (this.wainting_request) {
            this.another_update = true
            return
        }
        this.wainting_request = true

        if (this.points_saisis.length !== 0) {
            this.$store.commit('ajustement/SET_ERROR', null)

            const epreuve_correction_id = this.$props.datas?.epreuve_correction_id || 0
            const payload = {
                params : {
                    thresholds: this.points_saisis,
                    max_grade: this.max_grade
                }
            }

            if (this.$props.mode_ajustement !== ScopeAjustement.EPREUVE) {
                Vue.set(payload, 'scope_id', this.$props.datas.id)
                Vue.set(payload, 'scope_type', this.$props.mode_ajustement)
            }

            this.$store.commit('ajustement/SET_ERROR', null)

            this.$store.dispatch('ajustement/simulateSeuils', {
                epreuve_correction_id: epreuve_correction_id,
                adjustment: this.$props.index_ajustement,
                payload: payload
            })
                .then((response) => {
                    this.simulation_resultats = []

                    if (this.points_saisis.length) {
                        let data: any;
                        if (this.resultats.length) {
                            data = this.resultats
                        } else {
                            data = this.resultats_global
                        }

                        data.forEach((resultat: any) => {
                            const search_resultat = response.data.find((res: any) => parseFloat(res.origin) === resultat[0])
                            if (search_resultat) {
                                this.simulation_resultats.push([parseFloat(search_resultat.adjusted), resultat[1]])
                            }
                        })

                        if (this.another_update) {
                            this.wainting_request = false
                            this.another_update = false
                            this.simulateNotes()
                        }
                    }
                })
                .finally(() => {
                    this.another_update = false
                    this.wainting_request = false
                })
        }
    }

    /**
     * @description Récupère le pourcentage associé à une note
     * @param {number} point - Point
     * @return {string}
     */
    getPourcentage(point: number): string {
        const all_notes = this.liste_notes.filter((resultat: any) => resultat[0] <= point)
        if (all_notes.length) {
            return all_notes[all_notes.length - 1][1]
        }
        return '0.0'
    }

    /**
     * @description Ajoute un point
     * @return {void}
     */
    addSeuil(): void {
        const last_point = this.points_saisis[this.points_saisis.length - 1]
        if (last_point) {
            this.points_saisis.push({ origin: this.round(last_point.origin + 0.01), adjusted: last_point.adjusted === this.max_grade ? last_point.adjusted : last_point.adjusted })
        } else {
            this.points_saisis.push({ origin: 1.00, adjusted: 2.00 })
        }
        this.simulateNotes()
    }

    /**
     * @description Supprime un point
     * @param {number} index - Index du point
     * @return {void}
     */
    deleteSeuil(index: number): void {
        this.points_saisis.splice(index, 1)
        if (this.points_saisis.length) {
            this.simulateNotes()
        } else {
            this.simulation_resultats = []
            this.old_points_saisis = []
        }
    }

    /**
     * @description Ajout des points calculés
     * @return {void}
     */
    addComputedPoints(): void {
        const findPercentage = (percentage: number): number => {
            const find: any = this.liste_notes
                .find((resultat: any): boolean => resultat[1] >= percentage)

            return find ? this.round(find[0]) : 0
        }

        this.points_saisis = [
            { origin: findPercentage(20), adjusted: 5.66528 },
            { origin: findPercentage(40), adjusted: 8.13614 },
            { origin: findPercentage(60), adjusted: 10.26386 },
            { origin: findPercentage(80), adjusted: 12.73472 },
            { origin: findPercentage(99.5), adjusted: 20 }
        ]

        this.simulateNotes()
        this.replaceModal = false
    }

    /**
     * @description Retourne une chaine des noms de concours de l'épreuve
     * @param {any} concours - Concours
     * @return {string}
     */
    getLibellesConcours(concours: any): string {
        return concours?.map((c: any) => c.name).join(', ') || ''
    }

    /**
     * @description Ajuste la valeur entre min et max
     * @param {number} value - Valeur à ajuster
     * @param {number} min - Valeur minimale
     * @param {number} max - Valeur maximale
     * @return {number}
     */
    adjustValue(value: number, min: number, max: number): number {
        if (value < min) {
            return min;
        } else if (value > max) {
            return max;
        }
        return value;
    }

    /**
     * @description Calcule les statistiques
     * @return {void}
     */
    @Watch('option_stats')
    calculStats(): void {
        // Moyenne
        const array_notes_av = this.resultats.map((resultat: any): number => resultat[0])
        const array_notes_ap = this.simulation_resultats.map((resultat: any): number => resultat[0])

        // Moyenne
        this.stats_av.moyenne = moyenne(array_notes_av)
        this.stats_ap.moyenne = moyenne(array_notes_ap)

        // Ecart type
        this.stats_av.ecart_type = ecart_type(array_notes_av, this.stats_av.moyenne)
        this.stats_ap.ecart_type = ecart_type(array_notes_ap, this.stats_ap.moyenne)

        // Médiane
        this.stats_av.mediane = quantile(array_notes_av, 0.5)
        this.stats_ap.mediane = quantile(array_notes_ap,0.5)

        // Ecart interquartile
        const quantile_1_av = quantile(array_notes_av, 0.25)
        const quantile_3_av = quantile(array_notes_av, 0.75)

        this.stats_av.ecart_interquartile = (quantile_3_av - quantile_1_av).toFixed(2)

        const quantile_1_ap = quantile(array_notes_ap, 0.25)
        const quantile_3_ap = quantile(array_notes_ap, 0.75)

        this.stats_ap.ecart_interquartile = (quantile_3_ap - quantile_1_ap).toFixed(2)

        if (this.$props.mode_ajustement !== ScopeAjustement.EPREUVE) {
            const array_notes_gb = this.resultats_global.map((resultat: any): number => resultat[0])

            // Moyenne
            this.stats_global.moyenne = moyenne(array_notes_gb)

            // Ecart type
            this.stats_global.ecart_type = ecart_type(array_notes_gb, this.stats_global.moyenne)

            // Médiane
            this.stats_global.mediane = quantile(array_notes_gb, 0.5)

            // Ecart interquartile
            const quantile_1_gb = quantile(array_notes_gb, 0.25)
            const quantile_3_gb = quantile(array_notes_gb, 0.75)

            this.stats_global.ecart_interquartile = (quantile_3_gb - quantile_1_gb).toFixed(2)
        }
    }

    /**
     * @description Montage du composant
     * @return {void}
     */
    mounted(): void {
        // Récupération des points enregistrés
        if (this.$props.datas) {
            if (this.$props.datas?.ajustements_params[this.$props.index_ajustement]?.params?.thresholds) {
                this.points_saisis = this.$props.datas.ajustements_params[this.$props.index_ajustement].params.thresholds
            }

            if (this.$props.mode_ajustement !== ScopeAjustement.EPREUVE) {
                this.createGlobalCourbe()
            }

            this.resultats = this.$store.getters['epreuveCorrectionResultat/notes']
                // Trie des notes
                .sort((a: any, b: any) => {
                    const noteA = this.$props.index_prec_ajustement ? a.notes[this.$props.index_prec_ajustement] : a.note_brute
                    const noteB = this.$props.index_prec_ajustement ? b.notes[this.$props.index_prec_ajustement] : b.note_brute
                    return noteA - noteB
                })
                // Récupération des valeurs utiles
                .map((resultat: any, index: number, array: any[]) => {
                    const note = this.$props.index_prec_ajustement ? resultat.notes[this.$props.index_prec_ajustement] : resultat.note_brute
                    return [note, (index + 1) * 100 / array.length]
                })

            let resultats: any;
            if (this.resultats.length) {
                resultats = this.resultats
            } else {
                resultats = this.resultats_global
            }

            this.liste_notes = resultats
                .reduce((acc: any, [note, percentage]: Array<number>): any => {
                    const existing = acc.find(([n]: any): boolean => n === note);
                    if (!existing) {
                        acc.push([note, percentage]);
                    } else if (percentage > existing[1]) {
                        existing[1] = percentage;
                    }
                    return acc;
                }, [])
                .map((resultat: any) => [this.round(resultat[0]), this.round(resultat[1])])

            if (this.points_saisis.length !== 0) {
                this.simulateNotes()
            }

            window.addEventListener('resize', this.resizeCourbe)
            window.addEventListener('resize', this.resizeStats)
        }
    }

    /**
     * @description Avant démontage du composant
     * @return {void}
     */
    beforeUnmount() {
        window.removeEventListener('resize', this.resizeCourbe)
        window.removeEventListener('resize', this.resizeStats)
    }
}
