




















































































































































































import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import { Ability } from '@/types/Ability'
import { TypePassation } from '@/types/Epreuve'
import { EtatActionPlanification, EtatHistoPlanification, EtatPlanificationAuto, EtatPlanificationSerie, getItemEtatSerie, TypePlanification } from '@/types/Planification'
import { etat_validation_serie } from '@/types/Serie'
import { formatDateDocument, formatDateHoursMinutes } from '@/utils/helpers'
import PlanificationAutomatique from '@/views/Planification/PlanificationAutomatique.vue'
import PlanificationManuelle from '@/views/Planification/PlanificationManuelle.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'

@Component({
    computed: {
        ...mapState('session', ['sessionSelect']),
        ...mapState('serie', ['series']),
        ...mapState('planification', [
            'historiques',
            'historique_en_cours',
            'serie_selected',
            'planification_en_cours',
            'historiques_en_cours'
        ]),
        ...mapState('sessionuser', ['statsValidation']),
        ...mapState('auth', ['user', 'user_session_id']),
        ...mapGetters('auth', ['can', 'user_session_id']),
        ...mapGetters('planification', ['getHistoriqueById'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        PlanificationAutomatique,
        PlanificationManuelle,
        PopupEditCandidat
    }
})
export default class Planification extends Vue {
    ongletSelect                       = 'null'
    edition_active                     = false
    reload_cand                        = false
    reload_serie                       = false
    EtatHistoPlanification             = EtatHistoPlanification
    EtatActionPlanification            = EtatActionPlanification
    TypePlanification                  = TypePlanification
    EtatPlanificationSerie             = EtatPlanificationSerie
    EtatPlanificationAuto              = EtatPlanificationAuto
    Ability                            = Ability
    getItemEtatSerie                   = getItemEtatSerie
    serie_validate_select: any         = null
    showModaleValidateSerie            = false
    validate_serie                     = 0
    planification_open                 = false
    showModaleValidateSessionEdition   = false
    etat_validation_serie              = etat_validation_serie
    showModalEditionCandidat?: boolean = false
    selected_tab                       = 'candidat'

    /**
     * @description Vérifie si un historique est en cours et active l'édition automatique
     * @returns {void}
     */
    @Watch('historiques_en_cours')
    checkHistoriqueEnCours(): void {
        if (this.$store.state.planification.historiques_en_cours.length !== 0) {
            const has_histo_en_cours = this.$store.state.planification.historiques
                .find((h: any) => h.etat === EtatHistoPlanification.ETAT_EN_COURS && h.user_id === this.$store.state.auth.user.id && h.type === TypePlanification.TYPE_PLANIF_AUTO)

            // Activation automatique de l'édition si un historique est en cours et que le user_id de l'histo correspond à l'utilisateur connecté
            if (has_histo_en_cours) {
                this.edition_active = true
                this.$store.commit('planification/SET_HISTORIQUE_EN_COURS', has_histo_en_cours)
            } else {
                this.edition_active = false
            }
        }
    }

    /**
     * @description Vérifie si un historique est en cours et active l'édition automatique
     * @returns {Promise<void>}
     */
    @Watch('historiques')
    async checkAutoActiveEdition(): Promise<void> {
        const has_histo_en_cours = this.$store.state.planification.historiques
            .find((h: any) => h.etat === EtatHistoPlanification.ETAT_EN_COURS && h.user_id === this.$store.state.auth.user.id)

        // Activation automatique de l'édition si un historique est en cours et que le user_id de l'histo correspond à l'utilisateur connecté
        if (has_histo_en_cours) {
            await this.getStatsAuto(has_histo_en_cours.id, has_histo_en_cours.type)
            this.edition_active = true
            this.$store.commit('planification/MAJ_STATE_PLANIFICATION', EtatPlanificationAuto.EN_COURS)
            if (has_histo_en_cours.type === TypePlanification.TYPE_PLANIF_AUTO) {
                this.ongletSelect = 'auto'
            } else if (has_histo_en_cours.type === TypePlanification.TYPE_PLANIF_MANUEL) {
                this.ongletSelect = 'manuel'
            }
        } else {
            this.edition_active = false
        }
    }

    /**
     * @description Met à jour les historiques en fonction de l'onglet sélectionné
     * @returns {void}
     */
    @Watch('ongletSelect')
    refreshHistos(): void {
        if (!this.$store.state.planification.serie_selected) {
            if (this.$store.state.planification.historique_en_cours) {
                const serie_temp = this.$store.state.serie.series
                    .find((s: any) => s.id === this.$store.state.planification.historique_en_cours.serie_id)
                this.$store.commit('planification/SET_SERIE_SELECTED', serie_temp)
            } else {
                const serie_temp = this.$store.state.serie.series[0]
                this.$store.commit('planification/SET_SERIE_SELECTED', serie_temp)
            }
        }
        if (this.$store.state.planification.serie_selected && this.$store.state.planification.serie_selected.id) {
            this.reload_serie = true
            this.reload_cand = this.ongletSelect === 'manuel';
        }
    }

    /**
     * @description Récupère le code de la matière pour l'action
     * @param {any} action - Action de planification
     * @returns {string}
     */
    getMatiereCode(action: any): string {
        const epreuve = this.$store.getters['epreuve/getEpreuveCorrectionById'](action.epreuve_correction_id)
        return `${epreuve?.type_passation === TypePassation.TYPE_PASSATION_TP ? 'TP' : ''} ${action.code_matiere}`
    }

    /**
     * @description Active les boutons Valider et Annuler pour l'historique
     * @param {boolean} etat - Etat de l'édition
     * @returns {void}
     */
    active_edition(etat: boolean): void {
        this.edition_active = etat
    }

    /**
     * @description Ouvre le popup de confirmation de validation de la session de modification en cours aprés validation de série
     * @returns {void}
     */
    confirmationValidation(): void {
        this.showModaleValidateSessionEdition = true
    }

    /**
     * @description Ferme la popup de validation de la session aprés validation série
     * @returns {void}
     */
    closeValidateEdition(): void {
        this.showModaleValidateSessionEdition = false
    }

    /**
     * @description Valide la session d'édition en cours
     * @returns {void}
     */
    validateSessionEdition(): void {
        this.reload_cand = false
        this.$store.commit('planification/SET_ERROR', null)
        this.$store.dispatch('planification/validateHistoPlanif', { histo_planif_id: this.$store.state.planification.historique_en_cours.id })
            .then(async () => {
                // Refresh de l'historique
                await this.loadHistos()
                // Reset l'historique en cours
                this.$store.state.planification.historique_en_cours = null
                // Refresh état des séries
                await this.$store.dispatch('serie/getSeries')
                this.edition_active = false
                this.$store.commit('planification/MAJ_STATE_PLANIFICATION', EtatPlanificationAuto.TERMINEE_STATS)
                this.$store.state.planification.creneaux_dispos = {}
            })
            .finally(() => {
                this.showModaleValidateSessionEdition = false
            })
    }

    /**
     * @description Annule la session d'édition en cours
     * @returns {void}
     */
    cancelSessionEdition(): void {
        this.reload_cand = false
        this.$store.commit('planification/SET_ERROR', null)
        this.$store.dispatch('planification/cancelHistoPlanif', { histo_planif_id: this.$store.state.planification.historique_en_cours.id })
            .then(async () => {
                // Refresh de l'historique
                await this.loadHistos()
                // Reset l'historique en cours
                this.$store.state.planification.historique_en_cours = null
                // Refresh état des séries
                await this.$store.dispatch('serie/getSeries')
                // Refresh de la sélection
                this.reload_cand = true
                this.edition_active = false
                this.$store.commit('planification/MAJ_STATE_PLANIFICATION', EtatPlanificationAuto.TERMINEE_STATS)
                this.$store.state.planification.creneaux_dispos = {}
            })
    }

    /**
     * @description Retourne une phrae pour décrire le move des créneaux
     * @param {string} move - Type de mouvement
     * @param {any} new_creneau - Nouveau créneau
     * @param {any} old_creneau - Ancien créneau
     * @returns {string}
     */
    getStringMove(move: string, new_creneau: any, old_creneau: any): string {
        switch (move) {
            case 'ADD':
                return 'planifié le ' + formatDateDocument(new_creneau.jour) + ' de ' + formatDateHoursMinutes(new_creneau.h_debut) + ' à ' + formatDateHoursMinutes(new_creneau.h_fin)
            case 'DEPLACE':
                return 'deplacé du ' + formatDateDocument(old_creneau.jour) + ' de ' + formatDateHoursMinutes(old_creneau.h_debut) + ' à ' + formatDateHoursMinutes(old_creneau.h_fin) + ' au ' + formatDateDocument(new_creneau.jour) + ' de ' + formatDateHoursMinutes(new_creneau.h_debut) + ' à ' + formatDateHoursMinutes(new_creneau.h_fin)
            case 'DELETE':
                return 'retiré le ' + formatDateDocument(old_creneau.jour) + ' de ' + formatDateHoursMinutes(old_creneau.h_debut) + ' à ' + formatDateHoursMinutes(old_creneau.h_fin)
        }
        return ''
    }

    /**
     * @description Retourne les classes css selon état de l'historique
     * @param {EtatHistoPlanification} etat - Etat de l'historique
     * @returns {string}
     */
    getHistoClass(etat: EtatHistoPlanification): string {
        let class_css = 'accordion-button collapsed '

        switch (etat) {
            case EtatHistoPlanification.ETAT_EN_COURS:
                class_css += 'text-dark'
                break
            case EtatHistoPlanification.ETAT_VALIDE:
                class_css += 'text-dark'
                break
            case EtatHistoPlanification.ETAT_ANNULE:
                class_css += 'text-secondary'
                break
        }

        return class_css
    }


    /**
     * @description Annule un ensemble d'actions jusqu'à l'ID en paramètre
     * @param {number} histo_id - ID de l'historique
     * @param {number} action_id - ID de l'action
     * @returns {Promise<void>}
     */
    async undoActionHisto(histo_id: number, action_id: number): Promise<void> {
        this.reload_cand = false
        await this.$store.dispatch('planification/undoHistoPlanif', { histo_planif_id: histo_id, action_planif_id: action_id })

        // Refresh de la sélection
        this.reload_cand = true
    }

    /**
     * @description Rétabli un ensemble d'actions jusqu'à l'ID en paramètre
     * @param {number} histo_id - ID de l'historique
     * @param {number} action_id - ID de l'action
     * @returns {Promise<void>}
     */
    async redoActionHisto(histo_id: number, action_id: number): Promise<void> {
        this.reload_cand = false
        this.$store.commit('planification/SET_LOADING', true)
        await this.$store.dispatch('planification/redoHistoPlanif', { histo_planif_id: histo_id, action_planif_id: action_id })

        // Refresh de la sélection
        this.reload_cand = true
        this.$store.commit('planification/SET_LOADING', false)
    }

    /**
     * @description Charge les historiques sur la série sélectionnée
     * @returns {Promise<void>}
     */
    async loadHistos(): Promise<void> {
        this.reload_serie = false
        await this.$store.dispatch('planification/getHistoPlanif', { serie_id: this.$store.state.planification.serie_selected.id, type_planif: TypePlanification.AUCUN })
        // Charge les historiques en cours pour l'utilisateur
        await this.$store.dispatch('planification/getHistoPlanifEnCours', { serie_id: -1, type_planif: TypePlanification.AUCUN })
    }

    /**
     * @description Charge l'historique cliqué avec les stats si planif auto
     * @param {number} histo_id - ID de l'historique
     * @param {TypePlanification} type_histo - Type de planification
     * @returns {Promise<void>}
     */
    async getStatsAuto(histo_id: number, type_histo: TypePlanification): Promise<void> {
        // Récupère les stats si le clic concerné une planif auto
        if (this.$store.state.planification.planification_en_cours !== EtatPlanificationAuto.EN_COURS) {
            const histo_temp = this.$store.getters['planification/getHistoriqueById'](histo_id)
            this.$store.commit('planification/SET_HISTORIQUE_EN_COURS', histo_temp)
            this.$store.commit('planification/MAJ_STATE_PLANIFICATION', EtatPlanificationAuto.CONSULTATION)

            // Charge les actions liées à l'historique sélectionné
            await this.$store.dispatch('planification/getActionsHisto', { histo_id: histo_id })
            if (type_histo === TypePlanification.TYPE_PLANIF_AUTO && this.$store.state.planification.historique_en_cours.actions.length !== 0 && this.$store.state.planification.historique_en_cours.actions[0].info !== 'Supprimer') {
                this.$store.commit('planification/REFRESH_STATS', this.$store.state.planification.historique_en_cours.actions[0].stats)
            }
        }
    }

    /**
     * @description Ouvre le popup de confirmation de validation d'une série
     * @param {any} serie - Série à valider
     * @param {boolean} validate - Valider ou invalider
     * @returns {void}
     */
    openModaleValidationSerie(serie: any, validate: boolean): void {
        this.serie_validate_select = serie
        this.validate_serie = validate ? 1 : 0
        this.showModaleValidateSerie = true
    }

    /**
     * @description Ferme et annule la validation d'une série
     * @returns {void}
     */
    closeValidateSerie(): void {
        this.serie_validate_select = null
        this.showModaleValidateSerie = false
    }

    /**
     * @description Valide une série
     * @returns {void}
     */
    confirmValidateSerie(): void {
        this.$store.dispatch('planification/validateSerie', { serie_id: this.serie_validate_select.id, payload : { valide: this.validate_serie, publie: 0, _method: 'PUT' } })
            .then(async () => {
                // Refresh series
                await this.$store.dispatch('serie/getSeries')
                this.$store.state.planification.serie_selected = this.$store.state.serie.series.find((s: any) => s.id === this.$store.state.planification.serie_selected.id)
                this.serie_validate_select = null
            })
            .finally(() => {
                this.showModaleValidateSerie = false
            })
    }

    /**
     * @description Retourne les classes actives ou disabled selon état
     * @param {TypePlanification} type_onglet - Type de planification
     * @returns {string}
     */
    getClassOnglet(type_onglet: TypePlanification): string {
        let class_onglet = ''

        if (this.edition_active) {
            class_onglet += 'disabled'
        }

        if (this.$store.state.planification.historique_en_cours) {
            // Récupération de la série de l'historique en cours si pas déja sélectionnée
            if (this.$store.state.planification.serie_selected && this.$store.state.planification.serie_selected.id !== this.$store.state.planification.historique_en_cours.serie_id) {
                this.$store.state.planification.serie_selected = this.$store.state.serie.series.find((s: any) => s.id === this.$store.state.planification.historique_en_cours.serie_id)
            }

            if ((type_onglet === TypePlanification.TYPE_PLANIF_AUTO && this.$store.state.planification.historique_en_cours.type === type_onglet && this.$store.state.planification.planification_en_cours === EtatPlanificationAuto.EN_COURS)) {
                class_onglet += ' active'
            }
            if ((type_onglet === TypePlanification.TYPE_PLANIF_MANUEL && this.$store.state.planification.historique_en_cours.type === type_onglet && this.$store.state.planification.planification_en_cours === EtatPlanificationAuto.EN_COURS)) {
                class_onglet += ' active'
            }
        }

        return class_onglet
    }


    /**
     * @description Récupération de la série sélectionnée dans les onglets d'à côté
     * @param {number} serie_id - ID de la série sélectionnée
     * @returns {Promise<void>}
     */
    async get_serie_id(serie_id: number): Promise<void> {
        if (this.ongletSelect !== 'null') {
            const serie_temp = this.$store.state.serie.series.find((s: any) => s.id === serie_id)
            this.$store.commit('planification/SET_SERIE_SELECTED', serie_temp)
            await this.loadHistos()
        }
    }

    /**
     * @description Ouvre la popup d'édition d'un candidat
     * @param {id: number; tab?: string} params - Paramètres d'ouverture
     * @returns {Promise<void>}
     */
    async openEditCandidat(params: {id: number; tab?: string}): Promise<void> {
        if (params?.id) {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            await this.$store.dispatch('candidat/getCandidat', params)
            this.$store.commit('candidat/SET_SELECTED_CANDIDAT', params.id)
            this.selected_tab = params?.tab || 'candidat'
            this.showModalEditionCandidat = true
        }
    }

    /**
     * @description Fermeture de la modale d'édition candidat
     * @returns {void}
     */
    reinitShowModalCandidat(): void {
        this.$store.state.candidat.selectedCandidat = null
        if (this.ongletSelect === 'manuel') {
            (this.$refs['manuelle'] as PlanificationManuelle).loadCandidatPlanification()
        }
        this.showModalEditionCandidat = false
    }

    /**
     * @description Chargement des données
     * @returns {Promise<void>}
     */
    @Watch('user_session_id')
    async load(): Promise<void> {
        this.$store.commit('planification/SET_ERROR', null)
        this.$store.commit('planification/SET_LOADING', true)
        // Check si la planification est ouverte - Condition
        // → Les candidats doivent avoir étaient répartis dans les équipes
        // → Les disponibilités des examinateurs doivent être validées
        // → Les examinateurs sont répartis dans les équipes
        await this.$store.dispatch('sessionuser/getSessionUserStatsvalidation')
        const check_validation_dispo = (this.$store.state.sessionuser.statsValidation.disponibilites_nb_valides === this.$store.state.sessionuser.statsValidation.ensembles_affectations_affectes)
        this.planification_open = !!(this.$store.state.session.sessionSelect.candidats_ensembles_validated_at && this.$store.state.session.sessionSelect.ensembles_examinateurs_validated_at && check_validation_dispo);

        // Chargement des épreuves de correction
        await this.$store.dispatch('epreuve/getEpreuvesCorrections', { params: null })
        const filter_by = [TypePassation.TYPE_PASSATION_ORAL, TypePassation.TYPE_PASSATION_TP]
        this.$store.state.planification.dico_epreuve_corrections = this.$store.getters['epreuve/epreuves_corrections_filter'](filter_by)

        // Chargement des matières
        await this.$store.dispatch('matiere/getMatieres')
        // Chargement des banques
        await this.$store.dispatch('banque/getBanques', { isPrecedente: false })
        // Chargement des séries
        await this.$store.dispatch('serie/getSeries')
        if (this.$route.query && this.$route.query.serie_id) {
            this.$store.state.planification.serie_selected = this.$store.state.serie.series.find((s: any) => s.id === Number(this.$route.query.serie_id))
        } else {
            this.$store.state.planification.serie_selected = this.$store.state.serie.series[0]
        }

        // Chargement des ensembles
        await this.$store.dispatch('ensemble/getEnsembles', { params: null })
        this.$store.commit('planification/SET_LOADING', true)
        if (this.$route.query && this.$route.query.onglet === 'M') {
            this.ongletSelect = 'manuel'
        } else {
            this.ongletSelect = 'auto'
        }
        this.$store.commit('planification/SET_LOADING', false)
    }

    /**
     * @description Avant le montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            await this.load()
        }
    }
}
