







































import { Vue, Component, Prop } from 'vue-property-decorator'
import { FontAwesomeIcon }      from '@fortawesome/vue-fontawesome'
import { mapState }             from 'vuex'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'


// import ECharts modules manually to reduce bundle size
import {
    CanvasRenderer
} from 'echarts/renderers'
import {
    BarChart, BoxplotChart, ScatterChart
} from 'echarts/charts'
import {
    DatasetComponent,
    GridComponent,
    MarkLineComponent,
    TitleComponent,
    TooltipComponent,
    TransformComponent
} from 'echarts/components'

use([
    CanvasRenderer,
    BarChart,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    TransformComponent,
    BoxplotChart,
    ScatterChart,
    CanvasRenderer,
    MarkLineComponent
])

@Component({
    computed: {
        ...mapState('session', ['sessionSelect']),
        ...mapState('planification', ['serie_selected', 'error_planification', 'loading_graph', 'etendue_trous'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay,
        ECharts
    }
})
export default class Statistiques extends Vue
{
    @Prop() openModal?: boolean
    option_etendue: any = null
    option_trous: any = null

    /**
     * Load options for graph
     */
    init_graphique () {
        // Option étendue
        const xAxis_e = []
        const series_e = []
        for (const j in this.$store.state.planification.etendue_trous.etendu) {
            if (Number(j) <= 1) {
                xAxis_e.push(j + ' jour')
            } else {
                xAxis_e.push(j + ' jours')
            }
            series_e.push(this.$store.state.planification.etendue_trous.etendu[j])
        }

        this.option_etendue = null
        this.option_etendue = {
            autoresize: true,
            cursor: 'default',
            calculable: true,
            animationDuration: 500,
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c} candidat(s)'
            },
            xAxis: {
                type: 'category',
                data: xAxis_e
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: series_e,
                    type: 'bar',
                    color: '#5b9bd5'
                }
            ]
        }

        // Option trous
        const xAxis_t = []
        const series_t = []
        for (const j in this.$store.state.planification.etendue_trous.trous) {
            if (Number(j) <= 1) {
                xAxis_t.push(j + ' jour')
            } else {
                xAxis_t.push(j + ' jours')
            }

            series_t.push(this.$store.state.planification.etendue_trous.trous[j])
        }

        this.option_trous = null
        this.option_trous = {
            autoresize: true,
            cursor: 'default',
            calculable: true,
            animationDuration: 500,
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c} candidat(s)'
            },
            xAxis: {
                type: 'category',
                data: xAxis_t
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: series_t,
                    type: 'bar',
                    color: '#5b9bd5'
                }
            ]
        }
    }

    /**
     * Fermeture du popup de statistiques
     */
    closeModal() {
        this.$emit('close', false)
    }

    mounted () {
        this.$store.dispatch('planification/getEtendue', { serie_id: this.$store.state.planification.serie_selected.id }).then(() => {
            this.init_graphique()
        })
    }
}
