
















































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { convertDateLocalValeurT, convertDateUTCValeurT, dateIsBetween, dateNow, formatDateHoursMinutes, getDayMonthYear } from '@/utils/helpers'
import { code_publishable_message } from '@/types/PublishableMessage'
import { etat_validated_datas, etat_transfered_datas, opened_datas, PublicationInterface } from '@/types/Publication'
import { Ability } from '@/types/Ability'

@Component({
    methods: { convertDateLocalValeurT },
    computed: {
        Ability() {
            return Ability
        },
        etat_transfered_datas() {
            return etat_transfered_datas
        },
        ...mapGetters('publication', ['get_publications', 'get_loading']),
        ...mapGetters('auth', ['can'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class Publications extends Vue {
    @Prop({ type: String, default: 'resultats' }) type!: string

    publications: any = []
    selectedPublication: any = null
    showModaleEditionAcces = false
    inverseSwitch = false
    applyAllPublications = false
    errorSates: any = null
    loading = false

    /**
     * Génère le titre de la modale d'édition des accès de la publication
     * @param {PublicationInterface} publication - Publication
     * @returns {string}
     */
    getModalTitle(publication: PublicationInterface): string {
        return `${publication?.name ? `<span class="fw-bold">${publication.name}</span> - ` : ''}${publication?.publishable?.name}`
    }

    /**
     * Retourne le libellé de l'état de validation des données
     * @param {PublicationInterface} publication - Publication
     * @returns {any}
     */
    getEtatValidation(publication: PublicationInterface): any {
        switch (publication.datas_validated) {
            // Résultats ni transférés ni validés
            case etat_validated_datas.DATAS_VALIDATED_NON:
                return { libelle: publication.publishable.libelle_check.datas_ko, icon: 'times', color: 'secondary', state: false }

            case etat_validated_datas.DATAS_VALIDATED_OUI:
                switch (publication.datas_transfered) {
                    // Résultats non transférés mais validés
                    case etat_transfered_datas.DATAS_TRANSFERED_NON:
                    case etat_transfered_datas.DATAS_TRANSFERED_EN_COURS:
                        return { libelle: publication.publishable.libelle_check.datas_ok, icon: 'repeat-alt', color: 'info', state: false }

                    // Transfert EN ERREUR
                    case etat_transfered_datas.DATAS_TRANSEFERED_ERREUR:
                        return { libelle: publication.publishable.libelle_check.datas_erreur_transfert, icon: 'times', color: 'danger', state: false }

                    // Résultats validés
                    case etat_transfered_datas.DATAS_TRANSEFERED_OUI:
                        return { libelle: publication.publishable.libelle_check.datas_ok, icon: 'check', color: 'success-bold', state: true }
                }
        }

        return { libelle: '-', icon: '', color: 'secondary', state: false }
    }

    /**
     * Calcule le libellé de la programmation des accès
     * @param {PublicationInterface} publication - Publication
     * @param {string} target - Cible programmation ('access', 'visibility')
     * @returns {any}
     */
    getProgrammation(publication: PublicationInterface, target: 'access' | 'visibility' = 'access'): any {
        let startAt: Date | number | string = publication.start_at
        let endAt: Date | number | string = publication.end_at

        if (target === 'visibility') {
            startAt = publication.visibility_start_at
            endAt = publication.visibility_end_at
        }

        if (!startAt && !endAt) {
            return { libelle: '-', color: 'secondary', state: false }
        } else {
            const isBetween: boolean = dateIsBetween(dateNow(), startAt, endAt, [])
            return {
                libelle: `${getDayMonthYear(startAt)} (${formatDateHoursMinutes(startAt)}) - ${getDayMonthYear(endAt)} (${formatDateHoursMinutes(endAt)})`,
                color: isBetween ? 'success-bold' : 'secondary',
                state: isBetween
            }
        }
    }

    /**
     * Calcule l'état de blocage des accès
     * @param {PublicationInterface} publication - Publication
     * @returns {any}
     */
    getBlocage(publication: PublicationInterface): any {
        switch (publication.opened) {
            case opened_datas.OPENED_OUI:
                return { color: 'tertiary', icon: 'lock-open-alt', state: true }

            case opened_datas.OPENED_NON:
                return { color: 'danger', icon: 'lock-alt', state: false }

            default:
                return { color: 'secondary', icon: 'lock', state: false }
        }
    }

    /**
     * Calcule l'état selon les flags : validation des données, programmation des accès, blocage des accès
     * @param {PublicationInterface} publication - Publication
     * @returns {any}
     */
    getEtat(publication: PublicationInterface): any {
        if (
            this.getEtatValidation(publication).state &&
            this.getProgrammation(publication).state &&
            this.getProgrammation(publication, 'visibility').state &&
            this.getBlocage(publication).state
        ) {
            return { libelle: 'PUBLIÉ', classe: 'bg_publier', icon: 'check', color: 'success-bold' }
        } else {
            return { libelle: 'NON PUBLIÉ', classe: 'bg_no_publier', icon: 'times', color: 'secondary' }
        }
    }

    /**
     * Calcule la classe pour le fond de la ligne
     * @param {PublicationInterface} publication - Publication
     * @returns {string}
     */
    getClasse(publication: PublicationInterface): string {
        return publication.datas_transfered === etat_transfered_datas.DATAS_TRANSEFERED_OUI &&
        this.getProgrammation(publication).color === 'success-bold' &&
        publication.opened === opened_datas.OPENED_OUI
            ? 'bg_publier' : 'bg_no_publier'
    }

    /**
     * Ouverture du popup d'édition des accès d'une publication
     * @param {PublicationInterface} publication - Publication
     * @returns {Promise<void>}
     */
    async openEditionAcces(publication: PublicationInterface): Promise<void> {
        this.selectedPublication = JSON.parse(JSON.stringify(publication))

        if (publication.visibility_start_at && publication.visibility_end_at) {
            this.selectedPublication.visibility_start_at = convertDateLocalValeurT(publication.visibility_start_at)
            this.selectedPublication.visibility_end_at = convertDateLocalValeurT(publication.visibility_end_at)
        }
        if (publication.start_at && publication.end_at) {
            this.selectedPublication.start_at = convertDateLocalValeurT(publication.start_at)
            this.selectedPublication.end_at = convertDateLocalValeurT(publication.end_at)
        }
        this.inverseSwitch = !this.selectedPublication.opened
        this.applyAllPublications = false

        // Récupère les messages concernant le publishable sélectionné
        if (this.$store.getters['publishableMessage/publishableMessages'].length === 0) {
            await this.$store.dispatch('publishableMessage/getPublishableMessages', { publishable_id: publication.publishable.id })
        }
        this.selectedPublication.message = this.$store.getters['publishableMessage/publishableMessages_by_pid'](publication.publishable.id)
            .find((m: any) => m.code === code_publishable_message.PUBLICATION_BLOQUEE)

        this.showModaleEditionAcces = true
    }

    /**
     * Sauvegarde l'édition des accès d'une publication
     * @returns {void}
     */
    saveEditionAcces(): void {
        if (this.$store.getters['publication/get_loading']) {
            return
        }
        this.errorSates = null
        this.$store.commit('publication/SET_LOADING', true)

        this.selectedPublication.opened = this.inverseSwitch ? 0 : 1
        this.selectedPublication.all_publication_updated = this.applyAllPublications ? 1 : 0

        const payload = JSON.parse(JSON.stringify(this.selectedPublication))
        if (payload.visibility_start_at && payload.visibility_end_at) {
            payload.visibility_start_at = convertDateUTCValeurT(payload.visibility_start_at)
            payload.visibility_end_at = convertDateUTCValeurT(payload.visibility_end_at)
        }
        if (payload.start_at && payload.end_at) {
            payload.start_at = convertDateUTCValeurT(payload.start_at)
            payload.end_at = convertDateUTCValeurT(payload.end_at)
        }

        if ((payload.start_at && payload.end_at) || (!payload.start_at && !payload.end_at) || (payload.visibility_start_at && payload.visibility_end_at) || (!payload.visibility_start_at && !payload.visibility_end_at)) {
            this.$store.dispatch('publication/updatePublication', {
                publication_id: this.selectedPublication.id,
                payload: payload
            })
                .then(async () => await this.initDatas())
                .finally(() => this.closeEditionAcces())
        } else {
            // Formulaire de dates incorrect
            this.errorSates = "Vous devez renseigner la date d'ouverture et la date de fermeture."
        }
    }

    /**
     * Ferme la popup d'édition des accès d'une publication
     * @returns {void}
     */
    closeEditionAcces(): void {
        this.showModaleEditionAcces = false
        this.selectedPublication = null
    }

    /**
     * Lance / Relance la validation des données vers le portail
     * @param {any} publication - Publication
     * @param {boolean} retry - Relance la validation des données
     * @returns {Promise<void>}
     */
    async validateDatas(publication: any, retry = false): Promise<void> {
        await this.$store.dispatch(`publication/${retry ? 're' : ''}tryValidateDatas`, publication.id)
        await this.initDatas(false)
    }

    /**
     * Initialisation des datas
     * @returns {Promise<void>}
     */
    @Watch('type', { immediate: true })
    async initDatas(loading = true): Promise<void> {
        this.loading = loading
        this.$store.dispatch('publication/getPublications')
            .then(() => {
                this.publications = this.$store.getters[`publication/get_publications_${this.type}`]
            })
            .finally(() => {
                this.loading = false
            })
    }
}
