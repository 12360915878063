export enum typeBarre {
    CODE_BARRE_ADMISSIBILITE = 'admissibilite',
    CODE_BARRE_MATH_PHYS = 'maths_physique',
    CODE_BARRE_ADMISSION = 'admission',
}

export interface StateDefinitionDesBarres {
    tableauDesConcours: any[],
    meta: number | null,
    links: string | null,
    loading: boolean | false,
    error: any | null,
    totalRows: number | null,
    currentPage: number | null,
    lastPage: number | null,
    totalPage: number | null,
    selectedConcour: any[],
    selectedTab: string,
    work_in_progress: boolean,
    old_saisie: any[],
    saisie_en_cours: any[]
}
