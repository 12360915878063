
















































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { scrollbarVisible } from '@/utils/helpers'
import PopupGestionSeance from '@/components/Planification/PopupGestionSeance.vue'
import { TypeEnsemble } from '@/types/Ensemble'


@Component({
    computed: {
        ...mapState('serie', ['series']),
        ...mapState('banque', ['banques']),
        ...mapGetters('ensemble', ['ensemblesByType']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('session', ['sessionSelect'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        PopupGestionSeance
    }
})

export default class DashboardOrauxPlanification extends Vue {
    TypeEnsemble                                 = TypeEnsemble
    scrollbarVisible                             = scrollbarVisible
    loading_local                                = false
    tabStatsSession: Array<any>                  = []
    tabStatsEnsemblesParEpreuve: Array<any>      = [] // Met en forme les stats par banque des équipes de type Par Epreuve
    tabStatsSerieParEpreuve: Array<any>          = [] // Met en forme les stats globales par banque des équipes de type Par Epreuve
    tabStatsEnsemblesInterclassement: Array<any> = [] // Met en forme les stats par banque des équipes de type Interclassement
    tabStatsSerieInterclassement: Array<any>     = [] // Met en forme les stats globales par banque des équipes de type Interclassement
    tabStatsEnsemblesTP: Array<any>              = [] // Met en forme les stats par banque des équipes de type TP
    tabStatsSerieTP: Array<any>                  = [] // Met en forme les stats globales par banque des équipes de type TP
    blocEquipeHasScrollBar                       = false

    /**
     * @description Récupération sur le serveur des concours
     * @returns {Promise<void>}
     */
    loadConcoursIfNotExists(): Promise<void> {
        return new Promise((resolve) => {
            if (this.$store.getters['concour/banques'].length === 0) {
                this.$store.dispatch('concour/getConcoursActifs')
                    .then(resolve)
            } else {
                resolve()
            }
        })
    }

    /**
     * @description Récupère les statistiques de la planification sur la session
     * @returns {Promise<void>}
     */
    async initStatSeries(): Promise<void> {
        await this.$store.dispatch('planification/getStatsSession', { session_id: this.$store.getters['auth/user_session_id'] })
        this.tabStatsSession = []
        this.tabStatsSession = this.$store.state.planification.stats_session
        this.initTabTypeEnsemble()
        this.loading_local = false
    }

    /**
     * @description Init les tableaux de stats par type d'ensemble et par banque (pour les interclassements)
     * Ces tableaux servent à alimenter la structure du DOM
     * @returns {void}
     */
    initTabTypeEnsemble(): void {
        for (const typeEnsembleIndex in this.$store.state.planification.stats_session.ensembles) {
            switch (parseInt(typeEnsembleIndex)) {
                case TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE:
                    this.tabStatsEnsemblesParEpreuve = []
                    this.tabStatsSerieParEpreuve = []
                    for (const serie of this.$store.state.serie.series) {
                        this.tabStatsSerieParEpreuve[serie.id] = { resteAPlanifier: 0 }
                    }
                    for (const ensembleIndex in this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex]) {
                        const ensembleInfos = this.getEnsembleInfos(parseInt(ensembleIndex))
                        // alimentation des compteurs de bilan global par série
                        for (const serieIndex in this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][ensembleIndex]) {
                            this.tabStatsSerieParEpreuve[parseInt(serieIndex)].resteAPlanifier = this.tabStatsSerieParEpreuve[parseInt(serieIndex)].resteAPlanifier + (this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][parseInt(ensembleIndex)][parseInt(serieIndex)].nbAPlanifier - this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][parseInt(ensembleIndex)][parseInt(serieIndex)].nbPlanifie)
                        }

                        this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][ensembleIndex].ensemble_id = ensembleInfos.ensemble_id
                        this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][ensembleIndex].ensemble_name = ensembleInfos.ensemble_name
                        this.tabStatsEnsemblesParEpreuve.push(this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][ensembleIndex])
                    }
                    break
                case TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT:
                    this.tabStatsEnsemblesInterclassement = []
                    this.tabStatsSerieInterclassement = []
                    for (const banqueIndex in this.$store.state.banque.banques) {
                        const tabBanqueTampon = []
                        const tabBanqueBilanTampon = []
                        for (const serie of this.$store.state.serie.series) {
                            tabBanqueBilanTampon[serie.id] = { resteAPlanifier: 0 }
                        }
                        for (const ensembleIndex in this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex]) {
                            const checkEnsembleInBanque = this.checkEnsembleInBanque(parseInt(ensembleIndex), this.$store.state.banque.banques[banqueIndex].code)
                            if (checkEnsembleInBanque.isInBanqueOK) {
                                // alimentation des compteurs de bilan global par série
                                for (const serieIndex in this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][ensembleIndex]) {
                                    tabBanqueBilanTampon[parseInt(serieIndex)].resteAPlanifier = tabBanqueBilanTampon[parseInt(serieIndex)].resteAPlanifier + (this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][parseInt(ensembleIndex)][parseInt(serieIndex)].nbAPlanifier - this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][parseInt(ensembleIndex)][parseInt(serieIndex)].nbPlanifie)
                                }

                                this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][ensembleIndex].ensemble_id = checkEnsembleInBanque.ensemble_id
                                this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][ensembleIndex].ensemble_name = checkEnsembleInBanque.ensemble_name
                                tabBanqueTampon.push(this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][ensembleIndex])
                            }
                        }
                        this.tabStatsEnsemblesInterclassement[parseInt(banqueIndex)] = tabBanqueTampon
                        this.tabStatsSerieInterclassement[parseInt(banqueIndex)] = tabBanqueBilanTampon
                    }
                    break
                case TypeEnsemble.TYPE_ENSEMBLE_TP:
                    this.tabStatsEnsemblesTP = []
                    this.tabStatsSerieTP = []
                    for (const serie of this.$store.state.serie.series) {
                        this.tabStatsSerieTP[serie.id] = { resteAPlanifier: 0 }
                    }
                    for (const ensembleIndex in this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex]) {
                        const ensembleInfos = this.getEnsembleInfos(parseInt(ensembleIndex))
                        // alimentation des compteurs de bilan global par série
                        for (const serieIndex in this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][ensembleIndex]) {
                            this.tabStatsSerieTP[parseInt(serieIndex)].resteAPlanifier = this.tabStatsSerieTP[parseInt(serieIndex)].resteAPlanifier + (this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][parseInt(ensembleIndex)][parseInt(serieIndex)].nbAPlanifier - this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][parseInt(ensembleIndex)][parseInt(serieIndex)].nbPlanifie)
                        }

                        this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][ensembleIndex].ensemble_id = ensembleInfos.ensemble_id
                        this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][ensembleIndex].ensemble_name = ensembleInfos.ensemble_name
                        this.tabStatsEnsemblesTP.push(this.$store.state.planification.stats_session.ensembles[typeEnsembleIndex][ensembleIndex])
                    }
                    break
            }
        }
    }

    /**
     * @description Permet de savoir si la scrollbar est active sur l'élément bloc_equipes
     * @returns {void}
     */
    checkblocEquipeHasScrollbar(): void {
        setTimeout(() => {
            this.$nextTick(() => {
                let hasScrollbar = false
                const refBlocEquipe: HTMLElement = this.$refs.refBlocEquipe as HTMLElement
                if (refBlocEquipe !== undefined) {
                    hasScrollbar = scrollbarVisible(refBlocEquipe)
                    this.blocEquipeHasScrollBar = hasScrollbar
                }
            })
        }, 500)
    }

    /**
     * @description Renvoie les infos d'avancement selon les données nbAPlanifier et nbPlanifie fournies
     * @param {number} nbAPlanifier - Nombre de séances à planifier
     * @param {number} nbPlanifie - Nombre de séances planifiées
     * @returns {any}
     */
    getClassEtatAvancement(nbAPlanifier: number, nbPlanifie: number): any {
        let classEtat: {}
        if (nbAPlanifier === nbPlanifie) {
            classEtat = {
                color: 'text-success',
                title: '',
                resteAPlanifier: 0
            }
        } else {
            classEtat = {
                color: 'text-danger',
                title: 'A planifier : ' + nbAPlanifier + '\nPlanifié : ' + nbPlanifie,
                resteAPlanifier: nbAPlanifier - nbPlanifie
            }
        }
        return classEtat
    }

    /**
     * @description Permet de savoir si l'ensemble donné est associé à la banque donnée
     * @param {number} id_ensemble - id de l'ensemble
     * @param {string} banque_name - nom de la banque
     * @returns {any}
     */
    checkEnsembleInBanque(id_ensemble: number, banque_name: string): any {
        const ensemble = this.$store.getters['ensemble/ensembleById'](id_ensemble)
        return ({ isInBanqueOK: ensemble.concour.code === banque_name, ensemble_id: ensemble.id, ensemble_name: ensemble.name })
    }

    /**
     * @description Récupère les infos de filière et nom de l'ensemble pour les types par epreuve et TP
     * @param {number} id_ensemble - id de l'ensemble
     * @returns {any}
     */
    getEnsembleInfos(id_ensemble: number): any {
        const ensemble = this.$store.getters['ensemble/ensembleById'](id_ensemble)
        const filieresEnsemble = this.$store.getters['ensemble/getFiliereFromEnsemble'](ensemble)
        return ({ ensemble_id: ensemble.id, ensemble_name : ensemble.name + ' (' + filieresEnsemble + ')' })
    }

    /**
     * @description Chargement des données
     * @returns {Promise<void>}
     */
    @Watch('user_session_id')
    async load(): Promise<void> {
        this.loading_local = true
        // Si aucun concours n'a été chargé, alors on les récupère
        await this.loadConcoursIfNotExists()
        // Chargement des matières
        await this.$store.dispatch('matiere/getMatieres')
        // Chargement des banques
        await this.$store.dispatch('banque/getBanques', { isPrecedente: false })
        // Chargement des séries
        await this.$store.dispatch('serie/getSeries')
        // Chargement des ensembles
        await this.$store.dispatch('ensemble/getEnsembles', { session_id: this.$store.getters['auth/user_session_id'], params: null })
        await this.initStatSeries()
    }

    /**
     * @description Initialisation des données du composant au montage
     * @returns {Promise<void>}
     */
    async mounted(): Promise<void> {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            await this.load()
        }
    }
}
