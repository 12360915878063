





















































































import { Vue, Component, Watch }  from 'vue-property-decorator'
import { mapGetters, mapState }   from 'vuex'
import { Ability }                from '@/types/Ability'
import  PopupEditCandidat         from '@/components/Candidat/PopupEditCandidat.vue'
import { FontAwesomeIcon }        from '@fortawesome/vue-fontawesome'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('candidat', ['candidats', 'authUser', 'candidats_avec_commentaires_importants']),
        ...mapGetters('dashboardOraux', ['dashboard_oraux_informations', 'loading_dashboard'])
    },
    components: {
        PopupEditCandidat,
        // AffichageCommentCandidat,
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class DashboardOrauxPreparation extends Vue {
    @Watch('dashboard_oraux_informations')
    dataForDashboard () {
        this.info = this.$store.getters['dashboardOraux/dashboard_oraux_informations']
        this.createPreparationDashboard()
    }

    ability                   = Ability

    candidatsWithComments     = []
    // boolean affichage des commentaires candidats
    showModalEditionCandidat  = false
    selected_tab              = 'commentaires'
    Ability                   = Ability
    tuiles: any               = []
    info: any                 = []
    headerInformations: any   = {}
    full_lancement_delib = false

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    /**  creer un objet de deux tableaux (box1, box2) correspondant aux deux colonnes d'affichage. */
    createPreparationDashboard() {
        this.headerInformations = {
            titre: 'Lancement des oraux',
            info: []
        }

        // Check les états de lancement des oraux
        let count_concours = 0
        let total_concours = 0
        for (const c in this.info.candidats_concours) {
            const filiere = {
                filiere: this.info.candidats_concours[c].name,
                etat: this.info.candidats_concours[c].id_phase_en_cours !== 1 ? 'text-success' : 'text-secondary',
                title: this.info.candidats_concours[c].id_phase_en_cours !== 1 ? 'Lancé' : 'Non lancé'
            }

            if (this.info.candidats_concours[c].id_phase_en_cours !== 1) {
                count_concours++
            }
            total_concours++
            this.headerInformations.info.push(filiere)
        }

        this.full_lancement_delib = count_concours === total_concours
        this.tuiles = {
            box1: [
                {
                    name: 'INITIALISATION',
                    icone: 'calendar-day',
                    tooltip : null,
                    informations: [
                        { description: this.info.series_valides ? 'GESTION DES SÉRIES - VALIDÉE' : 'GESTION DES SÉRIES - NON VALIDÉE', etat: this.getColor(this.info.series_valides), link: '/sessions' }]
                },
                {
                    name: 'Gestion des centres d\'oral',
                    icone: 'school',
                    tooltip: null,
                    informations: [
                        {
                            description: 'CENTRES ONT ÉTÉ PUBLIÉS',
                            link: '/centres_oraux',
                            etat: this.getColor(this.info.centres_nb_publies, this.info.centres_nb_total),
                            stat1: this.info.centres_nb_publies + '/' + this.info.centres_nb_total
                        },
                        {
                            description: 'CENTRES AVEC L\'ORGANISATION VALIDÉE',
                            link: '/centres_oraux',
                            etat: this.getColor(this.info.centres_nb_organisations_valides, this.info.centres_nb_total),
                            stat1: this.info.centres_nb_organisations_valides + '/' + this.info.centres_nb_total
                        },
                        {
                            description: 'CENTRES AVEC LES INFORMATIONS VALIDÉES',
                            link: '/centres_oraux',
                            etat: this.getColor(this.info.centres_nb_informations_valides, this.info.centres_nb_publies),
                            stat1: this.info.centres_nb_informations_valides + '/' + this.info.centres_nb_publies
                        }]
                },
                {
                    name: 'GESTIONS DES CANDIDATS',
                    icone: 'users',
                    tooltip: !this.info.is_barres_validees,
                    informations: [
                        {
                            description: this.full_lancement_delib ? 'CANDIDATS INSCRITS AUX ORAUX' : 'CANDIDATS INSCRITS AUX CONCOURS',
                            link: '/candidats_oraux',
                            etat: this.info.candidats_nb_total !== 0 ? this.full_lancement_delib && this.info.is_barres_validees ? 'text-success' : 'text-info' : 'text-secondary',
                            stat1: this.info.candidats_nb_total,
                            sousDescription: this.info.candidats_concours
                        },
                        {
                            description: 'DEMANDES D\'AMÉNAGEMENTS',
                            link: '/candidats_oraux',
                            etat: this.getColor(this.info.amenagement_nb_traites, this.info.amenagement_nb_total),
                            stat1: this.info.amenagement_nb_traites + '/' + this.info.amenagement_nb_total
                        },
                        {
                            description: 'Répartition des candidats dans les équipes',
                            link: '/candidats_oraux',
                            etat: this.info.candidats_ensembles_valides ? 'text-success' : 'text-secondary'
                        }
                    ]
                }
            ],
            box2: [
                {
                    name: 'AFFECTATION DES EXAMINATEURS',
                    icone: 'users-cog',
                    tooltip: null,
                    informations: [
                        {
                            description: this.info.ensembles_valides ? 'GESTION DES ÉQUIPES - VALIDÉE' : 'GESTION DES ÉQUIPES - NON VALIDÉE',
                            link: '/affectation_examinateurs',
                            etat: this.getColor(this.info.ensembles_valides)
                        },
                        {
                            description: this.info.ensembles_centres_valides ? 'AFFECTATION DES ÉQUIPES PAR CENTRE - VALIDÉE' : 'AFFECTATION DES ÉQUIPES PAR CENTRE - NON VALIDÉE',
                            link: '/affectation_examinateurs',
                            etat: this.getColor(this.info.ensembles_centres_valides),
                            stat1: null
                        },
                        {
                            description: this.info.ensembles_affectations_valides ? 'AFFECTATION EXAMINATEURS PAR ÉQUIPE - VALIDÉE' : 'EXAMINATEURS AFFECTÉS DANS LES ÉQUIPES',
                            link: '/affectation_examinateurs',
                            stat1: this.info.ensembles_affectations_valides ?  null : this.info.ensembles_affectations_affectes + ' /' +  this.info.ensembles_affectations_total,
                            etat: this.info.ensembles_affectations_valides ? 'text-success' : this.info.ensembles_affectations_affectes !== 0 ? 'text-info' : 'text-secondary',
                            sousDescription: null
                        }]
                },
                {
                    name: 'GESTION DES EXAMINATEURS',
                    icone: 'user-clock',
                    tooltip: null,
                    informations: [
                        {
                            description: this.info.disponibilites_open ? 'SAISIE DES DISPONIBILITÉS OUVERTE' : 'SAISIE DES DISPONIBILITÉS FERMÉE',
                            link: '/gestion_examinateurs',
                            etat: this.info.disponibilites_open ? 'text-success' : 'text-secondary',
                            specificIcon: this.info.disponibilites_open ? 'lock-open' : 'lock'
                        },
                        {
                            description: 'EXAMINATEURS ONT SOUMIS LEURS DISPONIBILITÉS',
                            link: '/gestion_examinateurs',
                            stat1: this.info.disponibilites_nb_submit + '/' + this.info.ensembles_affectations_total,
                            etat: this.getColor(this.info.disponibilites_nb_submit, this.info.ensembles_affectations_total)
                        },
                        {
                            description: 'Examinateurs ont leurs disponibilités VALIDÉES',
                            stat1: this.info.disponibilites_nb_valides + '/' + this.info.ensembles_affectations_total,
                            link: '/gestion_examinateurs',
                            etat: this.getColor(this.info.disponibilites_nb_valides, this.info.ensembles_affectations_total)
                        },


                        {
                            description: this.info.incompatibilites_open ? 'SAISIE DES INCOMPATIBILITÉS OUVERTE' : 'SAISIE DES INCOMPATIBILITÉS FERMÉE',
                            link: '/gestion_examinateurs',
                            etat: this.info.incompatibilites_open ? 'text-success' : 'text-secondary',
                            specificIcon: this.info.incompatibilites_open ? 'lock-open' : 'lock'
                        },
                        {
                            description: 'EXAMINATEURS ONT SOUMIS LEURS INCOMPATIBILITÉS',
                            link: '/gestion_examinateurs',
                            stat1: this.info.incompatibilites_nb_submit + '/' + this.info.ensembles_affectations_total,
                            etat: this.getColor(this.info.incompatibilites_nb_submit, this.info.ensembles_affectations_total)
                        },
                        {
                            description: 'Examinateurs ont leurs INCOMPATIBILITÉS VALIDÉES',
                            stat1: this.info.incompatibilites_nb_valides + '/' + this.info.ensembles_affectations_total,
                            link: '/gestion_examinateurs',
                            etat: this.getColor(this.info.incompatibilites_nb_valides, this.info.ensembles_affectations_total)
                        }

                    ]
                }
            ]
        }
    }

    /**
     * Récupération sur le serveur des concours
     */
    loadConcoursIfNotExists ()
    {
        return new Promise((resolve) =>
        {
            // Check si le chargement est sur la session active
            let session_active_load = false
            if (this.$store.state.session.sessionSelect && this.$store.getters['concour/banques'].length !== 0 &&
            this.$store.state.session.sessionSelect.id === this.$store.getters['concour/banques'][0].session_id) {
                session_active_load = true
            }

            if (this.$store.getters['concour/banques'].length === 0 || !session_active_load)
            {
                this.$store.dispatch('concour/getConcoursActifs').then(() =>
                {
                    resolve(true)
                })
            }
            else
            {
                resolve(true)
            }
        })
    }


    /**
     * Récupération sur le serveur des mesures d'handicap
     */
    loadMesuresIfNotExists ()
    {
        return new Promise((resolve) =>
        {
            if (this.$store.getters['amenagement/amenagements'].length === 0)
            {
                this.$store.dispatch('amenagement/getAmenagements')
                resolve(true)
            }
            else
            {
                resolve(true)
            }
        })
    }

    /**
     * enregistrement du candidat en tant que candidat sélectionné
     * et affichage du modale commentaire
     */
    editCandidat (item: any)
    {
        if (item && item.id !== undefined)
        {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.dispatch('candidat/getCandidat', item).then(() => {
                const params =
                {
                    candidatId: item.id,
                    nomFiliere: item.filiere
                }
                this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
                this.showModalEditionCandidat = true
            })
        }
    }


    /** gestion de l'affichage du popup edition commentaires d'un candidat  */
    openEditCandidat(selectedCandidat: any) {
        if (selectedCandidat)
        {
            this.editCandidat(selectedCandidat)
        }
    }

    /**
     * Fermeture de la modale d'édition candidat
     */
    reinitShowModalCandidat ()
    {
        this.showModalEditionCandidat = false
    }

    /** gestion de la couleur des lignes d'informations  */
    getColor(number1?: number | boolean, number2?: number) {
        if (!number2) {
            return number1 === true ? 'text-success' : 'text-secondary'
        } else {
            if (number1 === 0)
            {
                return 'text-secondary'
            } else if (number1 !== number2) {
                return 'text-info'
            } else if (number1 === number2) {
                return 'text-success'
            }
        }
    }

    load () {
        this.$store.dispatch('serie/getSeries').then(() => {
            // Si aucun concours n'a été chargé, alors on les récupère également
            this.loadConcoursIfNotExists().then(() => {
                // Chargement des mesures d'handicap
                this.loadMesuresIfNotExists()
            })
        })
        this.$store.dispatch('dashboardOraux/getDashboardInformations').then(() => {
            this.info = this.$store.state.dashboardOraux.dashboard_oraux_informations
            this.createPreparationDashboard()
        })
    }

    beforeMount () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
