





















import { Vue, Component } from 'vue-property-decorator'
import BigCard from '@/components/dashboardComp/DashboardCard.vue'
import { mapGetters } from 'vuex'
import { functionsForDashboard } from '@/types/DashboardConcours'

@Component({
    computed: {
        ...mapGetters('dashboardConcours', ['dashboard_concours_informations']),
        ...mapGetters('user', ['dashboard'])
    },
    components: {
        BigCard
    }
})
export default class Dashboard extends Vue {
    dashboardElements!: string[]
    dashboardReady = false
    reload = false

    /**
     * Montage du composant
     * @return {Promise<void>}
     */
    async mounted(): Promise<void> {
        this.dashboardReady = false
        try {
            await this.$store.dispatch('dashboardConcours/getDashboardInformations')
            await this.$store.dispatch('user/getDashboard', null)
            this.dashboardElements = functionsForDashboard.dashboardFiltered(functionsForDashboard.dashboardElements)
        } finally {
            this.dashboardReady = true
        }
    }
}
