




























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import PopupEditCommentCandidat from '@/components/Candidat/PopupEditCommentCandidat.vue'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import { truncateString, formatNumber } from '@/utils/helpers'
import { TypeEnsemble } from '@/types/Ensemble'
import { CandidatStatut } from '@/types/Candidat'
import { candidatRecu } from '@/types/DefinitionDesBarresListeNotes'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('definitionBarresListeNotesAdmission', ['tableauListeNotesTrie', 'meta', 'links', 'loading', 'currentPage', 'lastPage', 'totalPage', 'totalRows']),
        ...mapState('definitionBarresListeNotesAdmission', ['params_definition_barres'])
    },
    components: {
        ExaGenericTable,
        PopupEditCandidat,
        PopupEditCommentCandidat,
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class DefinitionBarresListeNotesAdmission extends Vue {
    @Prop() concour?: any

    Ability                 = Ability
    genericfields: any      = []
    sortBy                  = '';
    sortDesc                = false;
    sortDirection           = 'asc';
    filterOn                = [];
    stickyHeader            = true;
    barres: any             = []
    filtres:    any         = []
    dataForTab: Array<any>  = []
    selectedConcour         = this.$props.concour
    showModalEditionCandidat = false
    selected_tab             = 'resultats'
    currentConcour: any = null

    @Watch('tableauListeNotesTrie')
    watchTab() {
        if (!this.$store.state.definitionBarresListeNotesAdmission.loading && this.$store.getters['definitionBarresListeNotesAdmission/tableauListeNotesTrie'].length) {
            this.dataForTab = []
            this.setDataForGenericTab(this.$store.getters['definitionBarresListeNotesAdmission/tableauListeNotesTrie'])
        }
    }

    @Watch('user_session_id')
    refreshInterface() {
        this.load()
    }

    // Chargement des concours
    async loadConcoursIfNotExists () {
        if (!this.$store.getters['concour/banques'].length) {
            await this.$store.dispatch('concour/getConcoursActifs')
        }
    }

    // Création des lignes de résultats
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }
        const options_admissiom: any = this.$store.getters['rankingGroup/rankingGroupsConcourPhase']
            .map((rg: any) => ({ index: rg.id, name: rg.name }))

        if (poData) {
            for (const result of poData) {
                // 1ère partie en dur - candidat
                const identite = result.candidat ? (result.candidat.name ? result.candidat.name : '-') + ' ' + (result.candidat.first_name ? result.candidat.first_name : '-') : '-'
                const line: any = [
                    { label: 'Cliquez pour consulter la fiche du candidat', text: result.candidat.code, item: result.candidat, type: 'actionText', typeAction: 'openModaleCandidat', class: 'text-info item_action' },
                    { label: 'Cliquez pour consulter la fiche du candidat', text: identite,             item: result.candidat, type: 'actionText', typeAction: 'openModaleCandidat', class: 'text-info item_action' }
                ]

                /* 2ème partie - Creation des colonnes dynamiques - colonnes des notes finales par épreuve */
                if (result && result.epreuves) {
                    for (const field of this.genericfields) {
                        if (field.epreuve_id !== null && field.placement === 'dynamique1') {
                            const filter = result.epreuves.filter((epreuve: { epreuve_id: null }) => epreuve.epreuve_id === field.epreuve_id)
                            let note = '-'
                            let label = ''
                            if (filter && filter[0]) {
                                if (filter[0].statut === CandidatStatut.ABSENT) {
                                    note = 'Abs.'
                                    label = 'Absent'
                                } else if (filter[0].statut === CandidatStatut.PRESENT) {
                                    note = filter[0].note_finale
                                } else if (filter[0].statut === CandidatStatut.INSCRIT) {
                                    label = 'Inscrit à l\'épreuve, pas encore noté'
                                }
                            }

                            line.push({
                                label: label,
                                item: note,
                                type: 'text',
                                typeAction: null,
                                class: 'text-center colonne_epreuve'
                            })
                        }
                    }
                }

                /* 3ème partie en dur - totaux, rang, points, moyenne, classement */

                // Equipe
                const equipeInterclassementMatch = result.candidat.ensembles.filter((e: any) => e.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT)
                let equipeInterclassement = ''
                if (equipeInterclassementMatch.length > 0) {
                    equipeInterclassement = equipeInterclassementMatch[0].name
                }

                // info "est classé"
                let isClasse = false
                isClasse = result.recu === candidatRecu.OUI

                const rankGroupName =  options_admissiom.find((oa: any) => oa.index === result.ranking_group_id)?.name

                if (this.currentConcour.phases.length > 1) {
                    const phasesFields = []
                    for (let i = 0; i < this.currentConcour.phases.length;  i++) {
                        if(this.currentConcour.phases[i].phase_id === this.$props.concour.phase_id) {
                            for (let j = 0; j < this.currentConcour.phases[i].bar_rules.length; j++) {
                                if(this.currentConcour.phases[i].bar_rules[j].type === 'point') {
                                    phasesFields.push({ label: '', item: result.bar_rule_results && result.bar_rule_results[this.currentConcour.phases[i].bar_rules[j].id] ?   result.bar_rule_results[this.currentConcour.phases[i].bar_rules[j].id]  : '-',      type: 'text',       typeAction: null,       class: 'text-center' })
                                }
                            }
                        }
                    }
                    line.push(...phasesFields)
                }

                const colonnes_resultats = [
                    // { label: '',      item: result.points ?                 result.points : '-',                    type: 'text',       typeAction: null,       class: 'text-center item_medium' },
                    { label: '',      item: result.moyenne ?                formatNumber(result.moyenne) : '-',     type: 'text',       typeAction: null,       class: 'text-center item_medium' },
                    { label: '',      item: equipeInterclassement !== '' ?  equipeInterclassement : '-',            type: 'text',       typeAction: null,       class: 'text-center item_medium' },
                    { label: '',      item: result.rang_equipe ?            result.rang_equipe : '-',               type: 'text',       typeAction: null,       class: 'text-center item_medium' },
                    { label: '',      item: rankGroupName ? rankGroupName : '' ,                          type: 'text',       typeAction: null,       class: 'text-center item_medium' },
                    {
                        label: '',
                        item: result.recu === 1 && result.rang !== null ? result.rang : result.infos ? this.getEliminationInfos(result.infos) : '-',
                        type: 'text',
                        typeAction: null,
                        class: 'bg-gray-light text-center item_medium'
                    },
                    { label: '',      item: result.rang_externe ?           result.rang_externe : '-',              type: 'text',       typeAction: null,       class: 'bg-gray-light text-center colonne_classement_final item_medium' }
                ]
                line.push(...colonnes_resultats)

                this.dataForTab.push(line)
            }
        }
    }

    // Création des filtres
    setFiltersForGenericTab() {
        // sert à simuler le contexte "admissibilité" pour l'appel d'un filtrage sur les champs 'Total admissibilité' et 'Rang admissibilité'
        const dataForTotalAdmission = {
            code: 'admission'
        }
        const dataForTotalAdmissibilite = {
            code: 'admissibilite'
        }

        const options_classe = [
            { index: 'classe', name: 'Oui' },
            { index: 'nonclasse', name: 'Non' }
        ]
        const options_classementInterne = [
            { index: 'classe', name: 'Admis' },
            { index: 'barre1', name: 'Rejet Admission' },
            { index: 'absenceEliminatoire', name: 'Absent' }
        ]

        this.filtres = [
            { libelle: 'code',                          defautOptionlibelle: 'Rechercher un',           model: 'candidat.code',             value: '',  index: 'code',                      datas: '',                          loading: this.$store.state.definitionBarresListeNotesAdmission.loading,     options: { type: 'form',        fieldsKey: 'candidat.code',     strict: true } },
            { libelle: 'Nom',                           defautOptionlibelle: 'Rechercher un',           model: 'candidat.name',             value: '',  index: 'name',                      datas: '',                          loading: this.$store.state.definitionBarresListeNotesAdmission.loading,     options: { type: 'form',        fieldsKey: 'candidat.name' } }
        ]

        const options_classement = []
        options_classement.push({ index: 'classe', name: 'Classé' })
        if (this.currentConcour.phases.length > 1) {
            for (let i = 0; i < this.currentConcour.phases.length;  i++) {
                if(this.currentConcour.phases[i].phase_id === this.$props.concour.phase_id) {
                    for (let j = 0; j < this.currentConcour.phases[i].bar_rules.length; j++) {
                        this.currentConcour.phases[i].bar_rules[j].code = this.currentConcour.phases[i].bar_rules[j].id
                        options_classement.push({ index: 'bar_rule_results', name: this.currentConcour.phases[i].bar_rules[j].name })
                        this.filtres.push({
                            libelle: this.currentConcour.phases[i].bar_rules[j].name,
                            defautOptionlibelle: 'Rechercher un',
                            model: 'bar_rule_results', // 'barre'+(i + 1),
                            value: '',
                            index: 'bar_rule_results', // 'barre'+(i + 1),
                            datas: this.currentConcour.phases[i].bar_rules[j],
                            loading: this.$store.state.definitionBarresListeNotes.loading,
                            options: { type: 'between', fieldsKey: 'json:bar_rule_results:' + this.currentConcour.phases[i].bar_rules[j].id } })
                    }
                }
            }


            if (this.barres.length > 1) {
                options_classement.push({ index: 'toutesBarresEliminatoires', name: 'Rejet deux barres' })
            }
        }

        const options_admissiom: any = this.$store.getters['rankingGroup/rankingGroupsConcourPhase']
            .map((rg: any) => ({ index: rg.id, name: rg.name }))


        this.filtres.push(...[
            {
                libelle: 'Total admission',
                defautOptionlibelle: 'Rechercher un',
                model: 'barre1',
                value: '',
                index: '',
                datas: dataForTotalAdmission,
                loading: this.$store.state.definitionBarresListeNotesAdmission.loading,
                options: { type: 'between', fieldsKey: 'points' }
            },
            {
                libelle: 'Équipe',
                defautOptionlibelle: 'Rechercher une',
                model: 'equipeInterclassement',
                value: '',
                index: 'equipeInterclassement',
                datas: '',
                loading: this.$store.state.definitionBarresListeNotesAdmission.loading,
                options: { type: 'form', fieldsKey: 'equipeInterclassement' }
            },
            {
                libelle: 'Statut',
                defautOptionlibelle: 'Rechercher par',
                model: 'ranking_group_id',
                value: '-',
                index: 'name',
                datas: options_admissiom,
                loading: this.$store.state.definitionBarresListeNotesAdmission.loading,
                options: { type: 'deroulant', fieldsKey: 'ranking_group_id' }
            }
        ])

        // this.filtres.push({ libelle: 'Classement ou élimination', defautOptionlibelle: ' ', model: 'classement', value: '-', index: 'name', datas: options_classement, loading: false, options: { type: 'deroulant', fieldsKey: 'rang' } })
    }

    // Events du tableau
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'openModaleCandidat':
                    this.editCandidat(paParams[1])
                    break
            }
        }
    }

    // Création des fields et footer en dynamique
    createDynamicFields(epreuvesDeliberation: any) {
        this.barres = this.$store.getters['definitionDesBarres/selectedConcour'].deliberation.bar_rules

        // 1ère partie en dur - candidat
        const fields = [
            { key: 'candidat.code', label: 'Code', sortable: true, class: '', type: 'text', epreuve_id: null },
            { key: 'candidat.name', label: 'Identité', sortable: true, class: '', type: 'text', epreuve_id: null }
        ]

        /* 2ème partie - Creation des colonnes dynamiques - colonnes des notes finales par épreuve */
        const colonnes_epreuves_phase_autre = [] // récupère l'ensemble des colonnes d'épreuves de phases autre (normalement phase précédente) qui seront placées après les colonnes d'épreuves de la phase 2 d'admission
        for (const epreuve of epreuvesDeliberation) {
            /* filtre des phases */
            if (this.$props.concour?.phase_id && epreuvesDeliberation && epreuve.phase_id === this.$props.concour.phase_id) {
                if (epreuve?.epreuve?.phase_id === this.$props.concour.phase_id) {
                    const field = { key: 'dyn:epreuves:epreuve_id:' + epreuve.epreuve.id, label: truncateString(epreuve.epreuve.name, 6), sortable: true, class: 'text-center colonne_epreuve', type: 'text', epreuve_id: epreuve.epreuve.id, placement: 'dynamique1', title: epreuve.epreuve.name }
                    fields.push(field)
                } else {
                    // epreuve d'une autre phase (normalement phase précédente)
                    const field = { key: 'dyn:epreuves:epreuve_id:' + epreuve.epreuve.id, label: truncateString(epreuve.epreuve.name, 6), sortable: true, class: 'text-center colonne_epreuve', type: 'text', epreuve_id: epreuve.epreuve.id, placement: 'dynamique1', title: epreuve.epreuve.name }
                    colonnes_epreuves_phase_autre.push(field)
                }
            }
        }

        if (colonnes_epreuves_phase_autre.length > 0) {
            // si au moins une colonne d'épreuve autre, on rajoute le tableau des colonnes d'épreuve de phase autre (normalement phase précédente)
            // fields.push(...colonnes_epreuves_phase_autre)
        }

        if(this.currentConcour.phases.length > 1) {
            const phasesFields: any = []
            for(let i = 0; i < this.currentConcour.phases.length;  i++) {
                if(this.currentConcour.phases[i].phase_id === this.$props.concour.phase_id) {
                    for(let j = 0; j < this.currentConcour.phases[i].bar_rules.length; j++) {
                        if(this.currentConcour.phases[i].bar_rules[j].type === 'point') {
                            phasesFields.push( { key: 'json:bar_rule_results:' + this.currentConcour.phases[i].bar_rules[j].id, label: 'Total ' + this.currentConcour.phases[i].bar_rules[j].name , sortable: true,     class: 'text-center', type: 'text', epreuve_id: null })
                        }
                    }
                }
            }

            fields.push(...phasesFields)
        }
        /* 3ème partie en dur - totaux, rang, points, moyenne, classement */
        const colonnes_resultats: any = [
            // { key: 'points',                            label: 'Total Admission',                   sortable: true,     class: 'text-center',                                           type: 'text', epreuve_id: null }, // pointsAdmission
            { key: 'moyenne',                           label: 'Moyenne Générale',                  sortable: true,     class: 'text-center',                                           type: 'text', epreuve_id: null }, // moyenneGenerale
            { key: 'equipeInterclassement',             label: 'Équipe',                            sortable: false,    class: 'bg-light text-center',                                  type: 'text', epreuve_id: null, doubleHeaderLabel: 'Interclassement', doubleHeaderColSpan: 2, doubleHeaderClass: 'bg-light text-center header_interclassement' }, // interclassementEquipe
            { key: 'rang_equipe',                       label: 'Rang',                              sortable: true,     class: 'bg-light text-center',                                  type: 'text', epreuve_id: null, doubleHeaderClass: 'text-center header_interclassement' }, // interclassementRang
            { key: 'ranking_group_id',                  label: 'Statut',                            sortable: false,    class: 'text-center',                                           type: 'text', epreuve_id: null }, // isClasse
            { key: 'rang',                              label: 'Classement Interne',                sortable: true,     class: 'bg-gray-light text-center',                             type: 'text', epreuve_id: null, doubleHeaderClass: 'text-center' }, // classementInterne
            { key: 'rang_externe',                      label: 'Classement Final (public)',         sortable: false,    class: 'bg-gray-light text-center colonne_classement_final',    type: 'text', epreuve_id: null, doubleHeaderClass: 'text-center' } // classementFinalPublic
        ]

        fields.push(...colonnes_resultats)
        return fields
    }

    // Chargement des résultats
    loadHandler(params: any) {
        // Init des params par défaut si vide
        if (!this.$store.state.definitionBarresListeNotesAdmission.params_definition_barres) {
            const params = { page: 1, sort: 'rang', direction: 'asc' }
            this.$store.commit('definitionBarresListeNotesAdmission/SET_PARAMS', params)
        }
        if (JSON.stringify(this.$store.state.definitionBarresListeNotesAdmission.params_definition_barres) !== JSON.stringify(params)) {
            this.$store.commit('definitionBarresListeNotesAdmission/SET_PARAMS', params)
            const payload = {
                concour_id: this.$props.concour.concour_id,
                phase_id: this.$props.concour.phase_id,
                params: this.$store.state.definitionBarresListeNotesAdmission.params_definition_barres
            }
            this.$store.dispatch('definitionBarresListeNotesAdmission/getMoreTableauListeDesNotes', payload)
                .then(() => {
                    this.setDataForGenericTab(this.$store.getters['definitionBarresListeNotesAdmission/tableauListeNotesTrie'])
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        }
    }

    // Applique les filtres
    filtreSortHandler(params: any) {
        if (JSON.stringify(this.$store.state.definitionBarresListeNotesAdmission.params_definition_barres) !== JSON.stringify(params)) {
            this.$store.commit('definitionBarresListeNotesAdmission/SET_PARAMS', params)
            const payload = {
                concour_id: this.$props.concour.concour_id,
                phase_id: this.$props.concour.phase_id,
                params: this.$store.state.definitionBarresListeNotesAdmission.params_definition_barres
            }
            this.setDataForGenericTab([])

            this.$store.dispatch('definitionBarresListeNotesAdmission/getTableauListeDesNotes', payload)
                .then(() => {
                    this.setDataForGenericTab(this.$store.getters['definitionBarresListeNotesAdmission/tableauListeNotesTrie'])
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        }
    }

    /** Recup l'objet infos de la requête candidatconcourphases. Vérifie les valeurs et retourne une valeur affichable dans le tableau. Un seul des element affiché. Ordre défini par Thierry
     * Dans le cas de l'admission, on n'affiche que l'info spécifique au cas de l'absence.
    */
    getEliminationInfos(infos: any) {
        if (infos.absenceEliminatoire) {
            return 'Absent(e)'
        } else if (infos.barreEliminatoire) {
            return `Rejet ${this.concour.deliberation.barres[0].nom}`
        }
        return ' - '
    }

    // Ouvre la popup du candidat
    editCandidat(item: any) {
        if (item?.id !== undefined) {
            this.$store.commit('candidat/RESET_EDITED_CANDIDAT')
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.commit('definitionBarresListeNotesAdmission/SET_LOADING', true) // on lance le loading
            this.$store.dispatch('candidat/getCandidat', item)
                .then(() => {
                    this.$store.commit('candidat/SET_CANDIDAT_FILIERE', {
                        candidatId: item.id,
                        nomFiliere: item.filiere
                    })
                    this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
                    this.showModalEditionCandidat = true
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$store.commit('definitionBarresListeNotesAdmission/SET_LOADING', false) // on ferme le loading
                })
        }
    }

    // Ferme la popup du candidat
    reinitShowModalCandidat() {
        this.showModalEditionCandidat = false
    }

    async load() {
        // Si aucun concours n'a été chargé, alors on les récupère (nécessaire pour l'affichage des résultats sur le popup d'édition d'un candidat)

        await this.loadConcoursIfNotExists()

        const payload_1 = { concour_id: this.selectedConcour.concour_id, phase_id: this.selectedConcour.phase_id }
        this.$store.dispatch('concour/getConcour', payload_1.concour_id)
            .then(async (response) => {
                await this.$store.dispatch('rankingGroup/getRankingGroupsConcourPhase', payload_1)

                this.currentConcour = response.data.data
                this.genericfields = this.createDynamicFields(response.data.data.epreuves_deliberation)
                if (!this.$store.state.definitionBarresListeNotesAdmission.params_definition_barres) {
                    const params = { page: 1, sort: 'rang', dzirection: 'asc' }
                    this.$store.commit('definitionBarresListeNotesAdmission/SET_PARAMS', params)
                }
                const payload = {
                    concour_id: this.selectedConcour.concour_id,
                    phase_id: this.selectedConcour.phase_id,
                    params:  { page: 1, sort: 'rang', direction: 'asc' }
                }
                this.$store.commit('definitionBarresListeNotesAdmission/SET_PARAMS', payload.params)
                this.$store.dispatch('definitionBarresListeNotesAdmission/getTableauListeDesNotes', payload)
                    .then(() => {
                        this.setFiltersForGenericTab()
                    })
            })

    }


    mounted() {
        this.load()
    }
}
