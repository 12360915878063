

































































import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { getFileNameFromHeader, base64ToArrayBuffer, formatDateDayHourDateForFileSaving } from '@/utils/helpers'
import { BarTypePass } from '@/types/Barre'
import { GenerateFlux } from '@/types/DefinitionBarresListeNotesAdmission'

/**
 * Composant principal de la pop up d'édition de candidats
 */
@Component({
    computed: {
        GenerateFlux() {
            return GenerateFlux
        },
        ...mapGetters('auth', ['authUser']),
        ...mapGetters('definitionBarresListeNotesAdmission', ['errorExport', 'loadingGenerate']),
        ...mapGetters('definitionDesBarres', ['selectedConcour'])
    },
    components: {
        ErrorDisplay
    }
})
export default class PopupExportationListeDesNotesAdmission extends Vue {
    @Prop() showModal?: boolean
    @Prop() concours?: any

    exportingIsWorking = false
    returnParams: any = {}
    concour: any = null
    valBarre: any = null
    loadingFlux: GenerateFlux | false = false
    codeClient = ''

    optionsExport = {
        type_export: 'liste_resultats',
        resultats_info_cand: false,
        resultats_details_notes: true,
        resultat_concours: true
    }

    listeResulatsChoixColumnExport = [
        { id: 'infos_candidat', checked: false, title: 'Informations des candidats' },
        { id: 'epreuves', checked: true, title: 'Détails des notes aux épreuves' },
        { id: 'resultats', checked: true, title: 'Resultats au concours' }
    ]

    @Watch('loadingGenerate', { immediate: true })
    setLoadingFlux(value: GenerateFlux | false): void {
        this.loadingFlux = value || false
    }

    /**
     * Récupère le code client
     * @returns {void}
     */
    @Watch('authUser', { immediate: true })
    setCodeClient(): void {
        this.codeClient = this.$store.getters['auth/findParameter']('codeClient')?.value || ''
    }

    /**
     * getConcourSelected
     * @description Récupère le concours sélectionné
     * @returns {void}
     */
    @Watch('showModal', { immediate: true })
    @Watch('selectedConcour')
    getConcourSelected(): void {
        this.concour = this.$store.getters['definitionDesBarres/selectedConcour']
        this.valBarre = this.concours.deliberation.barres
            .filter((barre: any) => barre.type === BarTypePass.BAR_TYPE_PASS)
            .map((barre: any) => barre.thresholds)
            .flat()
            .every((threshold: any) => threshold.value !== null)
    }

    /**
     * choixExportationColumns
     * @description Transforme les choix utilisateur des columns, push les infos dans l'objet returnParams
     * @returns {void}
     */
    choixExportationColumns(): void {
        this.returnParams = {}
        this.listeResulatsChoixColumnExport.forEach((each) => {
            if (each.checked) {
                this.returnParams[each.id] = each.checked ? 1 : 0
            }
        })
    }

    /**
     * lancerExport
     * @description Lance l'exportation des données
     * @returns {void}
     */
    lancerExport(): void {
        this.$store.commit('definitionBarresListeNotesAdmission/SET_ERROR_EXPORT', null) // réinit de l'erreur potentiellement affichée

        const currentDateFormated = formatDateDayHourDateForFileSaving(new Date(Date.now()).toISOString())
        let filenameExport = `Exportation_Des_Admissions_${currentDateFormated}.pdf`

        let payload = {}
        switch (this.optionsExport.type_export) {
            case 'liste_resultats': {
                filenameExport = `Exportation_Des_Admissions_${currentDateFormated}.xlsx`
                this.choixExportationColumns()
                payload = {
                    headers: {
                        'Content-Disposition': 'attachment; filename=template.xlsx',
                        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    },
                    responseType: 'blob',
                    concour_id : this.concours.concour_id,
                    phase_id: this.concours.phase_id,
                    type_export: 'liste_resultats',
                    params: this.returnParams
                }
                break
            }
            case 'tableau_rangs_par_equipe': {
                filenameExport = `Admissions_Rang_Par_Equipe_${currentDateFormated}.pdf`
                payload = {
                    concour_id : this.concours.concour_id,
                    phase_id: this.concours.phase_id,
                    type_export: 'tableau_rangs_par_equipe'
                }
                break
            }
            case 'liste_classes_tri_par_rang': {
                filenameExport = `Admissions_Classés_Tri_Par_Rang_${currentDateFormated}.pdf`
                payload = {
                    concour_id : this.concours.concour_id,
                    phase_id: this.concours.phase_id,
                    type_export: 'liste_classes',
                    alpha: 0
                }
                break
            }
            case 'liste_classes_tri_par_nom': {
                filenameExport = `Admissions_Classés_Tri_Par_Nom_${currentDateFormated}.pdf`
                payload = {
                    concour_id : this.concours.concour_id,
                    phase_id: this.concours.phase_id,
                    type_export: 'liste_classes',
                    alpha: 1
                }
                break
            }
        }

        this.exportingIsWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        this.$store.dispatch('definitionBarresListeNotesAdmission/exportationAdmission', payload)
            .then((response) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([
                    this.optionsExport.type_export === 'liste_resultats' ? response.data : base64ToArrayBuffer(response.data)
                ]))
                link.setAttribute(
                    'Download',
                    getFileNameFromHeader(response.headers) || filenameExport
                )
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                this.closeModal()
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
                this.returnParams = {}
            })
    }

    /**
     * Lance la génération des résultats
     * @param {GenerateFlux} flux - Flux à générer
     * @returns {void}
     */
    generateExport(flux: GenerateFlux): void {
        if (this.loadingFlux || this.codeClient !== 'CCMP') {
            return
        }
        this.loadingFlux = flux;

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast(`Génération des résultats ${flux.toUpperCase()} en cours de création ...`, infosToaster)

        this.$store.dispatch('definitionBarresListeNotesAdmission/generateExport', {
            flux: flux,
            phaseId: this.concours.phase_id
        })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(`Resultats ${flux.toUpperCase()} générés`, succesToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                // @Watch sur le store pour mettre à jour loadingFlux (Garde l'état en cas de réouverture de la modale)
            })
    }

    /**
     * closeModal
     * @description Ferme la modale
     * @returns {void}
     */
    closeModal(): void {
        this.$store.commit('definitionBarresListeNotesAdmission/SET_ERROR_EXPORT', null) // réinit de l'erreur potentiellement affichée
        this.$emit('reinitShow', null)
    }
}
